import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import mailic from ".././images/mailic.png";
import strings from "../utils/locals/languages";
import axios from "axios";
import { baseURL1 } from "../utils/baseUrl";
import { toast } from "react-toastify";

const EmailForgotOtp = ({setSelectedForm, email, setCode}) => {
    const [timer, setTimer] = useState(0);
    const [resendEmailLoader, setResendEmailLoader] = useState(false);
    const [emailResetLoader, setEmailResetLoader] = useState(false);

    const resendEmail = async() => {
        setResendEmailLoader(true);
        try {
            const response = await axios.get(`${baseURL1}/v1/user/forget-password?email=${email}`)

            if (response) {
                toast.success(response.data.msg)
            } else {
                toast.error(response.data.msg)
            }
        } catch {
            toast.error(strings.somethingWentWrong)
        }
        setTimeout(() => {
            setResendEmailLoader(false);
            setTimer(60);
        }, 2000);
    };

    const handleEmailReset = async (values, { setSubmitting }) => {

        setEmailResetLoader(true);

        try {
            setSubmitting(true)
            const response = await axios.get(`${baseURL1}/v1/user/verify?code=${formik.values.emailResetCode}`)

            if (response) {
                toast.success(response.data.msg)
                setCode(formik.values.emailResetCode)
                setEmailResetLoader(false);
                setSubmitting(false);
                setSelectedForm("UpdatePassword")
            } else {
                toast.error(response.data.msg)
            }
        } catch(err) {
            console.log('first', err)
            toast.error(strings.invalidCode)
            setEmailResetLoader(false);
            setSubmitting(false);
        }

        setTimeout(() => {
            setEmailResetLoader(false);
            setSubmitting(false);
        }, 2000);
    };

    const formik = useFormik({
        initialValues: {
            emailResetCode: "",
        },
        validationSchema: Yup.object({
            emailResetCode: Yup.string()
                .required(strings.requiredField)
                .length(4, strings.invalidCode),
        }),
        onSubmit: handleEmailReset,
    });

    return (
        <div className="container rounded-lg" style={{ background: "#F4F5F9" }}>
            <div className="p-4">
                <h5 className="text-center font-bold">{strings.forgotPassword}</h5>
                <p className="text-center pt-2">{strings.checkEmail}</p>
            </div>

            <div className="flex justify-center">
                <img className="w-40" src={mailic} alt="Mail Icon" />
            </div>

            <form onSubmit={formik.handleSubmit}>
                <div className="flex justify-center mt-3">
                    <input
                        className="bg-white shadow-md rounded-full w-80 p-3 outline-orange-600"
                        type="text"
                        name="emailResetCode"
                        placeholder={strings.enterTheCode}
                        value={formik.values.emailResetCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
                {formik.touched.emailResetCode && formik.errors.emailResetCode && (
                    <p className="text-red-500 text-center">{formik.errors.emailResetCode}</p>
                )}

                <div className="w-full mt-2 flex justify-center">
                    <div
                        className="text-orange-600 cursor-pointer"
                        onClick={() => {
                            if (timer <= 0) resendEmail();
                        }}
                    >
                        {resendEmailLoader ? (
                            <ClipLoader size={8} />
                        ) : (
                            `${strings.resendCode} ${timer > 0 ? `(${timer}s)` : ""}`
                        )}
                    </div>
                </div>

                <div className="flex justify-center mt-4">
                    <button
                        type="submit"
                        className="bg-orange-500 w-80 rounded-full h-15 p-3 mb-4 text-white"
                        disabled={formik.isSubmitting}
                    >
                        {emailResetLoader ? (
                            <>
                                <ClipLoader size={15} color="#fff" /> {strings.loading}
                            </>
                        ) : (
                            strings.sendRequest
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EmailForgotOtp;