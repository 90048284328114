export const handleCarClick = (navigate, id, title, category, brand, model, city, distt, km) => {
  const slugify = (text) => 
    text?.toString().trim().toLowerCase().replace(/[^a-z0-9\s-]/g, "").replace(/\s+/g, '-');

  const cleanedDescription = slugify(title);
  const cleanedCategory = slugify(category);
  const cleanedBrand = slugify(brand);
  const cleanedModel = slugify(model);
  const cleanedCity = slugify(city);
  const cleanedDistt = slugify(distt);

  navigate(`/vehicle-details/${cleanedCategory}-${cleanedBrand}-${cleanedModel}-${cleanedDescription}-${km || 0}-${cleanedCity}-${cleanedDistt}?id=${id}`);
};

  

export const handleRealEstateClick = (navigate, id, title, category, brand, city, district) => {
  const slugify = (text) =>
    text?.toString().trim().toLowerCase().replace(/[^a-z0-9\s-]/g, "").replace(/\s+/g, '-');

  const cleanedDescription = slugify(title);
  const cleanedCategory = slugify(category);
  const cleanedBrand = slugify(brand);
  const cleanedCity = slugify(city);
  const cleanedDistrict = slugify(district);

  navigate(`/real-estate-details/${cleanedCategory}-${cleanedBrand}-${cleanedDescription}-${cleanedCity}-${cleanedDistrict}?id=${id}`);
};
