import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";
import SelectRecoveryMethod from '../auth-components/SelectRecoveryMethod';
import EmailPassReset from '../auth-components/EmailPassReset';
import EmailForgotOtp from '../auth-components/EmailForgotOtp';
import UpdatePassword from '../auth-components/UpdatePassword';
import EmailSignUp from '../auth-components/EmailSignUp';
import PhoneLoginOtp from '../auth-components/PhoneLoginOtp';
import PhoneSignIn from '../auth-components/PhoneSignIn';
import PhoneSignUpVerfCode from '../auth-components/PhoneSignUpVerfCode';
import EmailSignUpVerfCode from '../auth-components/EmailSignUpVerfCode';
import EmailLogin from '../auth-components/EmailLogin';
import ResetWithPhoneNumber from '../auth-components/ResetWithPhoneNumber';
import SelectSignUpMethod from '../auth-components/SelectSignUpMethod';
import PhoneSignUp from '../auth-components/PhoneSignUp';

const AuthModal = ({ show, setShow }) => {

    const handleClose = () => setShow(false)
    const [email, setEmail] = useState("")
    const [code, setCode] = useState("")
    const [phoneNbr, setPhoneNbr] = useState("")

    const [selectedForm, setSelectedForm] = useState("EmailLogin")
    return (
        <div>
            <Modal size="md" style={{ marginTop: "50px" }} show={show} onHide={handleClose}>
                {selectedForm === "EmailLogin" ? (
                    <EmailLogin
                        setSelectedForm={setSelectedForm}
                    />
                ) : selectedForm === "SelectRecoveryMethod" ? (
                    <SelectRecoveryMethod
                        setSelectedForm={setSelectedForm}
                    />
                ) : selectedForm === "SignInWithPhone" ? (
                    <PhoneSignIn
                        setSelectedForm={setSelectedForm}
                        setPhoneNbr={setPhoneNbr}
                    />
                ) : selectedForm === "RecoverWithEmail" ? (
                    <EmailPassReset
                        setSelectedForm={setSelectedForm}
                        setEmail={setEmail}
                    />
                ) : selectedForm === "ForgotOtpEmailVerification" ? (
                    <EmailForgotOtp
                        setSelectedForm={setSelectedForm}
                        email={email}
                        setCode={setCode}
                    />
                ) : selectedForm === "UpdatePassword" ? (
                    <UpdatePassword
                        setSelectedForm={setSelectedForm}
                        code={code}
                    />
                ) : selectedForm === "ResetWithPhoneNumber" ? (
                    <ResetWithPhoneNumber />
                ) : selectedForm === "SelectSignUpMethod" ? (
                    <SelectSignUpMethod
                        setSelectedForm={setSelectedForm}
                    />
                ) : selectedForm === "SignUpWithEmail" ? (
                    <EmailSignUp
                        setSelectedForm={setSelectedForm}
                    />
                ) : selectedForm === "PhoneLoginOtp" ? (
                    <PhoneLoginOtp
                        setSelectedForm={setSelectedForm}
                        phoneNbr={phoneNbr}
                    />
                ) : selectedForm === "PhoneSignUp" ? (
                    <PhoneSignUp
                        setSelectedForm={setSelectedForm}
                        setPhoneNbr={setPhoneNbr}
                    />
                ) : selectedForm === "PhoneSignUpVerfCode" ? (
                    <PhoneSignUpVerfCode
                        setSelectedForm={setSelectedForm}
                        phoneNbr={phoneNbr}
                    />
                ) : selectedForm === "EmailSignUpVerfCode" ? (
                    <EmailSignUpVerfCode
                        setSelectedForm={setSelectedForm}
                    />
                ) : null}
            </Modal>

        </div>
    )
}

export default AuthModal