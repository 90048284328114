import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import Cookies from "js-cookie";

import "bootstrap/dist/css/bootstrap.min.css";

import { useGeolocated } from "react-geolocated";
import { FaTimes } from 'react-icons/fa';
import * as Yup from "yup";
import { baseURL1, realEstateId, uploadDataUrl } from "../../utils/baseUrl";
import apiClient from "../../utils/apiClient";
import { setLanguage } from "../../app/languageSlice";
import { useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import strings from "../../utils/locals/languages";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import OpenStreetMap from "../../components/OpenStreetMap";
import { capitalizeFirstLetter } from "../../utils/capitalizeLetter";
import CurrencyInput from "react-currency-input-field";
import { regions } from "../../utils/regions";

const EditState = () => {

  const [isLocationAvailable, setIsLocationAvailable] = useState(true)

  const { coords } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  const [notificationData, setNotificationData] = useState([])
  const [notificationCount, setNotificationCount] = useState(0)
  const [city, setCity] = React.useState(null);
  const [country, setCountry] = React.useState(null);
  const [district, setDistrict] = React.useState(null);
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [detailedDataLength, setDetailedDataLength] = useState(0)
  const [required, setRequired] = useState(false)
  const [categoryDropDownLoading, setCategoryDropDownLoading] = useState(false)
  const [subCategoryDropDownLoading, setSubCategoryDropDownLoading] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState("")
  const [selectedBrand, setSelectedBrand] = useState("")
  const [filteredData, setFilteredData] = useState([]);
  const [videoFile, setVideoFile] = useState("")
  const button1Ref = React.useRef(null);
  const button2Ref = React.useRef(null);
  const [menu1Open, setMenu1Open] = useState(false);
  const handleMenu1Open = (event) => {
    setMenu1Open(true);
  };
  const handleMenu1Close = () => {
    setMenu1Open(false);
  };
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageChange = (language) => {
    strings.setLanguage(language); // set the language for the LocalizedStrings instance

    Cookies.set("language", language);
    setTimeout(() => {
      window.location.reload();
    }, 500);
    dispatch(setLanguage(language)); // dispatch an action to update the language in the store
  };

  useEffect(() => {
    const selectedLanguage = Cookies.get("language")

    if (selectedLanguage !== undefined) {
      strings.setLanguage(selectedLanguage)
    }

  }, [])

  const logOutUser = () => {
    Cookies.remove("token");
    Cookies.remove("userDetails");
    Cookies.remove("manageAd")
    window.location.reload();
  };
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);

  const [internalFeatures, setInternalFeatures] = useState([]);
  const [externalFeatures, setExternalFeatures] = useState([]);
  const [accessToTransportation, setAccessToTransportation] = useState([]);
  const [environment, setEnvironment] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [getData, setGetData] = useState([]);
  const [detailedData, setDetailedData] = useState([]);
  const [getInternalFeatures, setGetInternalFeatures] = useState([]);
  const [getExternalFeatures, setGetExternalFeatures] = useState([]);
  const [getAccessToTransportation, setGetAccessToTransportation] = useState(
    []
  );
  const [getEnvironment, setGetEnvironment] = useState([]);
  const [getFacilities, setGetFacilities] = useState([]);
  const [whatsapp, setWhatsapp] = useState(false);
  const [telegram, setTelegram] = useState(false);
  const [sms, setSms] = useState(false);
  const [call, setCall] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const hiddenFileInput = useRef(null);
  const hiddenVideoFileInput = useRef(null);
  const hiddenCoverFileInput = useRef(null);

  const [files, setFiles] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false)
  const [coverPhotoLoading, setCoverPhotoLoading] = useState(false)


  const handleCoverPhotoClick = (event) => {
    hiddenCoverFileInput.current.click();
  };

  const handleVideoUpload = (File) => {
    setVideoLoading(true)
    const formData = new FormData();
    formData.append("files", File);

    axios
      .post(
        `${uploadDataUrl}/v1/adds/upload-multiple`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setVideoFile(res.data[0]);
        setVideoLoading(false)
        formik.setFieldValue("videoUrl", res.data[0])
        formik.setFieldValue("videoThumbnail", res.data[0])
        toast.success(`${strings.videoUpload}`)
      })
      .catch((err) => {
        setVideoLoading(false)
        toast.error(`${strings.videoUploadFailed}`)
      });
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const uploadCoverPhoto = (File) => {
    setCoverPhotoLoading(true);
    const formData = new FormData();
    formData.append("files", File);

    axios
      .post(`${uploadDataUrl}/v1/adds/upload-multiple`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setCoverPhotoLoading(false);
        formik.setFieldValue('coverPhoto', res.data[0])

        toast.success(`${strings.imageUpload}`);
      })
      .catch((err) => {
        toast.error(`${strings.imageUploadFailed}`);
      });
  };

  const handleVideoClick = (event) => {
    hiddenVideoFileInput.current.click();
  };

  const handleImageUpload = (files) => {
    Array.from(files).forEach((file) => {
      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
          const { width, height } = img;

          if (width < 500 || height < 500) {
            toast.error("Please select an image with height and width greater than 500px");
            return;
          }

          // Proceed with the upload if dimensions are as expected
          const formData = new FormData();
          formData.append("files", file);
          setImageLoading(true);

          axios
            .post(`${uploadDataUrl}/v1/adds/upload-multiple`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              setImageLoading(false);

              setFiles((prevFiles) => [
                ...prevFiles,
                {
                  image: res.data[0],
                },
              ]);


              toast.success(`${strings.imageUpload}`);
            })
            .catch((err) => {
              setImageLoading(false);
              toast.error(`${strings.imageUploadFailed}`);
            });
        };

        img.onerror = () => {
          setImageLoading(false);
          toast.error("Invalid image file.");
        };

        img.src = e.target.result;
      };

      fileReader.onerror = () => {
        setImageLoading(false);
        toast.error("Failed to read the file.");
      };

      fileReader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    setCategoryDropDownLoading(true)
    axios
      .get(`${baseURL1}/v1/adds/sections?language=${strings._language}`)
      .then((response) => {
        setBrands(response.data[1].categories);
        setCategoryDropDownLoading(false)
      })
      .catch((err) => { });;
  }, [strings._language]);

  const location = useLocation();
  const id = location.state.id;
  const [selectedFiles, setSelectedFiles] = useState([]);

  const editData = location.state.realEstateData;
  const validationSchema = Yup.object().shape({
    purpose: Yup.string().required(`${strings.purpose}`),
    basicInformation: Yup.object().shape({

      title: Yup.string()
        .min(5, `${strings.titleErrorMsgOne}`)
        .required(`${strings.titleErrorMsgTwo}`),
      description: Yup.string()
        .min(10, `${strings.descErrorMsgOne}`)
        .required(`${strings.descErrorMsgTwo}`),
      area: Yup.object().shape({
        gross: Yup.number()
          .min(0, `${strings.grossAreaMsgOne}`)
          .required(`${strings.grossAreaMsgTwo}`),
        net: Yup.number()
          .min(0, `${strings.netAreaMsgOne}`)
          .required(`${strings.netAreaMsgTwo}`),
      }),
      price: Yup.object().shape({
        currency: Yup.string().required(`${strings.currencyError}`),
        price: Yup.number()
          .min(0, `${strings.priceErrorOne}`)
          .required(`${strings.priceErrorTwo}`),
      }),
    }),

  });

  const formik = useFormik({
    initialValues: {
      section: realEstateId,
      purpose: "",
      region: "",
      basicInformation: {
        title: "",
        description: "",
        price: {
          currency: "",
          price: 0,
        },
        category: "",
        brand: "",
        area: {
          gross: 0,
          net: 0,
        },
        contactInformation: {
          nameSurname: "",
          contactList: [],
        },
        details: [],
      },
      internalFeatures: [],
      externalFeatures: [],
      accessToTransportation: [],
      disabledFacilities: [],
      neighborhood: [],
      location: {
        country: "",
        city: "",
        district: "",
        neighborhood: "",
        complex: "",
        openAddress: "",
      },
      imageUrl: [],
      videoUrl: "",
      coverPhoto: ""
    },

    validationSchema,

    onSubmit: (values) => {
      setTimeout(() => {
        handleSave(values);
      }, 2000);
    },
  });

  const handleFileUpload = (event) => {
    const files = event.target.files;
    setSelectedFiles(files);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }
    fetch(`${uploadDataUrl}/v1/adds/upload-multiple`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .catch((err) => { });;
  };

  const handleSave = (values) => {
    submitData(values);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get(
          `/v1/adds/section/feature?section=realEstate&language=${strings._language}`,
        );
        setGetData(response.data.detail);
        setGetInternalFeatures(response.data.internalFeatures);
        setGetExternalFeatures(response.data.externalFeatures);
        setGetAccessToTransportation(response.data.accessToTransportation);
        setGetEnvironment(response.data.environment);
        setGetFacilities(response.data.disabledFacilities);
      } catch (error) {
      } finally {
      }
    };
    fetchData();
  }, [selectedBrand, selectedCategory, strings._language]);

  const submitData = (data) => {
    if (formik.values.region === "") {
      toast.error(`${strings.required}: ${strings.region}`)
    } else if (formik.values.coverPhoto === "") {
      toast.error(`${strings.required}: ${strings.coverPhoto}`)
    } else if (formik.values.location.city === "" || formik.values.location.country === "") {
      toast.error(strings.locationAcces)
    } else if (formik.values.imageUrl.length === 0) {
      toast.error(`${strings.imgErr}`);
    } else if (
      formik.values.basicInformation.details.length < detailedDataLength
    ) {
      toast.error(`${strings.requiredFields}`);
      setRequired(true);
    } else if (!(whatsapp || telegram || call || sms)) {
      // Check if none of the contact options are selected
      toast.error(`${strings.contactError}`);
    } else {
      navigate("/realestate-preview", {
        state: {
          formik: formik.values,
          basicInfo: {
            category: selectedCategory,
            brand: selectedBrand
          },
          isEdit: true
        }
      });
    };
  }


  useEffect(() => {
    setSubCategoryDropDownLoading(true)
    axios
      .get(
        `${baseURL1}/v1/adds/realestate-cat-dropdown?catId=${typeof formik.values.basicInformation.category === "object" ? formik.values.basicInformation.category._id : formik.values.basicInformation.category}&language=${strings._language}`
      )
      .then((response) => {
        setModels(response.data);
        setSubCategoryDropDownLoading(false)
      })
      .catch((err) => { });;
  }, [formik.values.basicInformation.category, strings._language]);

  const handleInternalFeatures = (fieldName, imageUrl) => {
    if (fieldName) {
      const existingIndex = internalFeatures.findIndex(
        (item) => item.fieldName === fieldName
      );
      if (existingIndex !== -1) {
        const newFeatures = [...internalFeatures];
        newFeatures.splice(existingIndex, 1);
        setInternalFeatures(newFeatures);
      } else {
        const newItem = {
          fieldName: fieldName,
          imageUrl: imageUrl,
        };
        setInternalFeatures([...internalFeatures, newItem]);
      }
    } else {
    }
  };

  useEffect(() => {
    formik.setFieldValue("internalFeatures", internalFeatures);
  }, [internalFeatures]);


  const handleExternalFeatures = (fieldName, imageUrl) => {
    if (fieldName) {
      const existingIndex = externalFeatures.findIndex(
        (item) => item.fieldName === fieldName
      );
      if (existingIndex !== -1) {
        const newFeatures = [...externalFeatures];
        newFeatures.splice(existingIndex, 1);
        setExternalFeatures(newFeatures);
      } else {
        const newItem = {
          fieldName: fieldName,
          imageUrl: imageUrl,
        };
        setExternalFeatures([...externalFeatures, newItem]);
      }
    } else {
    }
  };

  const getDetailedDropDowns = async (id) => {
    try {
      const response = await apiClient.get(
        `v1/adds/get-section-details?language=${strings._language}&section=realEstate&categoryId=${formik.values.basicInformation.category}&subCategoryId=${id}
        `
      );

      if (formik.values.purpose === 'rent' || formik.values.purpose === 'daily rent') {
        const filteredResult = response.data.realEstate.detail.filter((item) => item._id !== '641c0c2e690ed315f0e1f835' && item._id !== '641c0c2e690ed315f0e1f837' &&
          item._id !== '641c0c2e690ed315f0e1f775' && item._id !== '641c0c2e690ed315f0e1f8f5' && item._id !== '641c0c2e690ed315f0e1f777' && item._id !== '641c0c2e690ed315f0e1f8f7'
        )
        setDetailedData(filteredResult)
        setDetailedDataLength(filteredResult.length)

      } else {
        setDetailedData(response.data.realEstate.detail)
        setDetailedDataLength(response.data.realEstate.detail.length)


      }

    } catch (error) {
      setDetailedData([])
    }
  }

  useEffect(() => {
    formik.setFieldValue("externalFeatures", externalFeatures);
  }, [externalFeatures]);


  const handleAccessToTransportation = (fieldName, imageUrl) => {
    if (fieldName) {
      const existingIndex = accessToTransportation.findIndex(
        (item) => item.fieldName === fieldName
      );
      if (existingIndex !== -1) {
        const newFeatures = [...accessToTransportation];
        newFeatures.splice(existingIndex, 1);
        setAccessToTransportation(newFeatures);
      } else {
        const newItem = {
          fieldName: fieldName,
          imageUrl: imageUrl,
        };
        setAccessToTransportation([...accessToTransportation, newItem]);
      }
    } else {
    }
  };

  const removeImage = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };


  const handleRemoveVideo = () => {
    setVideoFile(null);
  };


  useEffect(() => {
    formik.setFieldValue("accessToTransportation", accessToTransportation);
  }, [accessToTransportation]);

  const handleEnvironment = (fieldName, imageUrl) => {
    if (fieldName) {
      const existingIndex = environment.findIndex(
        (item) => item.fieldName === fieldName
      );
      if (existingIndex !== -1) {
        const newFeatures = [...environment];
        newFeatures.splice(existingIndex, 1);
        setEnvironment(newFeatures);
      } else {
        const newItem = {
          fieldName: fieldName,
          imageUrl: imageUrl,
        };
        setEnvironment([...environment, newItem]);
      }
    } else {
    }
  };

  useEffect(() => {
    formik.setFieldValue("neighborhood", environment);
  }, [environment]);
  const handleFacilities = (fieldName, imageUrl) => {
    if (fieldName) {
      const existingIndex = facilities.findIndex(
        (item) => item.fieldName === fieldName
      );
      if (existingIndex !== -1) {
        const newFeatures = [...facilities];
        newFeatures.splice(existingIndex, 1);
        setFacilities(newFeatures);
      } else {
        const newItem = {
          fieldName: fieldName,
          imageUrl: imageUrl,
        };
        setFacilities([...facilities, newItem]);
      }
    } else {
    }
  };

  useEffect(() => {
    formik.setFieldValue("disabledFacilities", facilities);
  }, [facilities]);

  useEffect(() => {
    formik.setValues(editData)
    if (editData?.basicInformation?.contactInformation.contactList.includes("Whatsapp")) {
      setWhatsapp(true)
    }
    if (editData.basicInformation.contactInformation.contactList.includes("Call")) {
      setCall(true)
    }
    if (editData.basicInformation.contactInformation.contactList.includes("Sms")) {
      setSms(true)
    }
    if (editData.basicInformation.contactInformation.contactList.includes("Telegram")) {
      setTelegram(true)
    }
    editData.imageUrl.forEach((item, index) => {
      files.push({
        id: index,
        image: item,
      });
    });
    setVideoFile(editData.videoUrl)
    setInternalFeatures(editData.internalFeatures)
    setExternalFeatures(editData.externalFeatures)
    setAccessToTransportation(editData.accessToTransportation)
    setFacilities(editData.disabledFacilities)
    setEnvironment(editData.neighborhood)
  }, [])

  const imageUrl = []

  useEffect(() => {
    formik.setFieldValue(
      "basicInformation.contactInformation.contactList",
      selectedOptions
    );
  }, [selectedOptions]);


  const updateImages = () => {
    const imageUrlSet = new Set();

    files.forEach((file) => {
      imageUrlSet.add(file.image);
    });

    const imageUrl = Array.from(imageUrlSet);

    formik.setFieldValue("imageUrl", imageUrl);
  };



  useEffect(() => {
    setSelectedCategory(editData.basicInformation.category.title)
    setSelectedBrand(editData.basicInformation.brand.title)

  }, [])

  useEffect(() => {
    setDetailedDataLength(filteredData.length)
  }, [filteredData])

  useEffect(() => {
    if (formik.values.basicInformation.details.length < detailedDataLength) {
      setRequired(true)
    } else {
      setRequired(false)
    }
  }, [formik.values.basicInformation.details.length])

  useEffect(() => {
    apiClient
      .get(
        `/v1/user/notification`
      )
      .then((response) => {
        setNotificationCount(response.data.length)
        setNotificationData(response.data)
      })
      .catch((err) => { });;
  }, [])
  return (
    <>

      <div className="container">
        <div className="flex justify-center">
          <h4>{strings.editAd}</h4>
        </div>

      </div>

      {/* Navbar End
       */}

      {/*  */}

      <div className="container bg-white shadow-md rounded-md mt-5">
        <form onSubmit={formik.handleSubmit}>
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:gap-8  lg:grid-cols-3  p-5">
            <div className="">
              <h6 className="font-bold">{strings.selectPurpose}</h6>
              <select
                className="custom-select mt-3 w-72 h-14 rounded-full  border-2 shadow-md  bg-white  p-1"
                onChange={(e) => {
                  formik.setFieldValue("purpose", e.target.value);
                }}
                value={formik.values.purpose}
              >
                <option>{strings.selectPurpose}</option>
                <option value="sale">{strings.sale}</option>
                <option value="rent">{strings.rent}</option>
                <option value="daily rent">{strings.dailyRent}</option>
              </select>
              {formik.touched.purpose && formik.errors.purpose ? (
                <div className="text-red-500">{formik.errors.purpose}</div>
              ) : null}
            </div>
            <div>
              <div className="flex px-2 ">
                <p className="mt-2 px-2 font-bold">{strings.selectCat}</p>
              </div>
              <div>
                <select
                  className="custom-select w-72 h-14 rounded-full border-2 shadow-md bg-white p-1"
                  id="category"
                  onChange={(e) => {
                    const { value, selectedIndex } = e.target;
                    const option = e.target.options[selectedIndex];
                    const brandData = option.getAttribute("data-brand");

                    if (brandData) {
                      const brand = JSON.parse(brandData);
                      formik.setFieldValue("basicInformation.category", brand._id);
                      setSelectedCategory(brand.title);
                    } else {
                      formik.setFieldValue("basicInformation.category", "");
                      setSelectedCategory("");
                    }
                  }}
                  value={typeof formik.values.basicInformation.category === "object" ? formik.values.basicInformation.category._id : formik.values.basicInformation.category}
                >
                  {
                    categoryDropDownLoading ? <option>{strings.loading}</option> : (
                      <>
                        <option
                          key=""
                          value=""
                          data-brand=""
                        >{strings.select}</option>

                        {brands.sort((a, b) => a.title.localeCompare(b.title)).map((brand) => (
                          <option
                            key={brand._id}
                            value={brand._id}
                            data-brand={JSON.stringify(brand)}
                          >
                            {brand.title}
                          </option>
                        ))}
                      </>
                    )
                  }


                </select>

                {formik.touched.basicInformation?.category &&
                  formik.errors.basicInformation?.category ? (
                  <div className="text-red-500">
                    {formik.errors.basicInformation.category}
                  </div>
                ) : null}
              </div>
            </div>

            <div>
              <div className="flex px-2 ">
                <p className="mt-2 px-2 font-bold">{strings.selectBrand}</p>
              </div>
              <div>
                <select
                  id="brand"
                  className="custom-select w-72 h-14 rounded-full border-2 shadow-md bg-white p-1"
                  onChange={(e) => {
                    const { value, selectedIndex } = e.target;
                    const option = e.target.options[selectedIndex];
                    const modelData = option.getAttribute("data-model");

                    if (modelData) {
                      const model = JSON.parse(modelData);
                      formik.setFieldValue("basicInformation.brand", model._id);
                      setSelectedBrand(model.title);
                      getDetailedDropDowns(model._id)
                    } else {
                      formik.setFieldValue("basicInformation.brand", "");
                      setSelectedBrand("");
                    }
                  }}
                  value={typeof formik.values.basicInformation.brand === "object" ? formik.values.basicInformation.brand._id : formik.values.basicInformation.brand}
                >
                  {
                    subCategoryDropDownLoading ? <option>{strings.loading}</option> : (
                      <>
                        <option
                          key=""
                          value=""
                          data-brand=""
                        >{strings.select}</option>

                        {models.sort((a, b) => a.title.localeCompare(b.title)).map((model) => (
                          <option
                            key={model._id}
                            value={model._id}
                            data-model={JSON.stringify(model)}
                          >
                            {model.title}
                          </option>
                        ))}
                      </>
                    )
                  }
                </select>

                {formik.touched.basicInformation?.brand &&
                  formik.errors.basicInformation?.brand ? (
                  <div className="text-red-500">
                    {formik.errors.basicInformation.brand}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* ................................................................... */}
      <div className="container mx-auto rounded-lg shadow-md bg-white shadow-gray-300 mt-5">
        <div>
          <h6 className="pt-2 font-bold">{strings.basicInfo}</h6>

          <input
            className="w-full bg-white  border-2 p-3 rounded-full "
            type="text"
            placeholder={strings.title}
            onChange={(e) => {
              formik.setFieldValue("basicInformation.title", e.target.value);
            }}
            value={formik.values.basicInformation.title}
          />
          {formik.touched.basicInformation?.title &&
            formik.errors.basicInformation?.title ? (
            <div className="text-red-500">
              {formik.errors.basicInformation.title}
            </div>
          ) : null}
        </div>

        <div>
          <textarea
            className="w-full bg-white  border-2 p-3 rounded-md mt-3 "
            type="text"
            placeholder={strings.desc}
            onChange={(e) => {
              formik.setFieldValue(
                "basicInformation.description",
                e.target.value
              );
            }}
            value={formik.values.basicInformation.description}
          />
          {formik.touched.basicInformation?.description &&
            formik.errors.basicInformation?.description ? (
            <div className="text-red-500">
              {formik.errors.basicInformation.description}
            </div>
          ) : null}
        </div>

        <h6 className="pt-2">{strings.area}</h6>

        <div className="flex justify-between">
          <div className="col-md-5">
            <input
              className="w-full bg-white  border-2 p-3 rounded-full "
              type="number"
              placeholder={strings.gross}
              onChange={(e) => {
                if (e.target.value < 0) {
                  formik.setFieldValue(
                    "basicInformation.area.gross",
                    ''
                  );
                } else {
                  formik.setFieldValue(
                    "basicInformation.area.gross",
                    e.target.value
                  );
                }
              }}
              value={formik.values.basicInformation.area.gross}
            />
            {formik.touched.basicInformation?.area?.gross &&
              formik.errors.basicInformation?.area?.gross ? (
              <div className="text-red-500">
                {formik.errors.basicInformation.area?.gross}
              </div>
            ) : null}
          </div>

          <div className="col-md-5">
            <input
              className="w-full bg-white  border-2 p-3 rounded-full "
              type="number"
              placeholder={strings.net}
              onChange={(e) => {
                if (e.target.value < 0) {
                  formik.setFieldValue(
                    "basicInformation.area.net",
                    ""
                  );
                } else {
                  formik.setFieldValue(
                    "basicInformation.area.net",
                    e.target.value
                  );
                }
              }}
              value={formik.values.basicInformation.area.net}
            />
            {formik.touched.basicInformation?.area?.net &&
              formik.errors.basicInformation?.area?.net ? (
              <div className="text-red-500">
                {formik.errors.basicInformation?.area?.net}
              </div>
            ) : null}
          </div>
        </div>
        <h6 className="pt-2 font-bold">{strings.price}</h6>
        <div className="flex justify-between">
          <div className="col-md-5">
            <select
              placeholder="dropDown"
              name="dropDown"
              className="custom-select w-72 h-14 rounded-full  border-2 shadow-md  bg-white  p-1 col-md-12"
              onChange={(e) => {
                formik.setFieldValue(
                  "basicInformation.price.currency",
                  e.target.value
                );

              }}
              value={formik.values.basicInformation.price.currency}
            >

              <option>{strings.select}</option>
              {getData?.find(obj => obj.fieldName === 'Currency' || obj.fieldName === "Валюта" || obj.fieldName === "Valyuta")?.dropDown.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {formik.touched.basicInformation?.price?.currency &&
              formik.errors.basicInformation?.price?.currency ? (
              <div className="text-red-500">
                {formik.errors.basicInformation?.price?.currency}
              </div>
            ) : null}
          </div>
          <div className="col-md-5">
            <CurrencyInput
              min={0}
              decimalsLimit={2}
              name='price'
              id='price'
              className="w-full bg-white  border-1 shadow-md p-3 rounded-full "
              placeholder={strings.price}
              onValueChange={(value, name, values) => formik.setFieldValue(
                "basicInformation.price.price",
                value
              )}

              value={formik.values.basicInformation.price.price}
            />
            {formik.touched.basicInformation?.price?.price &&
              formik.errors.basicInformation?.price?.price ? (
              <div className="text-red-500">
                {formik.errors.basicInformation?.price?.price}
              </div>
            ) : null}
          </div>
        </div>

        <h6 className="pt-2 font-bold">{strings.region}</h6>
        <div>
          <select
            value={formik.values.region}
            onChange={(e) => formik.setFieldValue("region", e.target.value)}
            className="custom-select w-72 h-14 rounded-full  border-1 shadow-md  bg-white  p-1 col-md-12"
          >
            <option value="">{strings.select} {strings.region}</option>
            {regions[strings._language]?.map((region, index) => (
              <option key={index} value={region.value}>
                {region.title}
              </option>
            ))}
          </select>
        </div>


        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 py-5 justify-center">

          {detailedData.length === 0 ? (
            // Render a loading state or placeholder while filteredData is empty

            <div>{strings.loading}...
            </div>
          ) : (
            detailedData.map((data, index) => {
              if (data.fieldName === "Video Call") {
                return null;
              }
              return (
                <div key={index}>
                  <h6 className="mt-2 font-bold">{data.fieldName}</h6>

                  <select
                    placeholder="dropDown"
                    name="dropDown"
                    className="custom-select mt-3 w-72 h-14 rounded-full  border-2 shadow-md  bg-white  p-1"
                    onChange={(e) =>
                      formik.setFieldValue(`basicInformation.details[${index}]`, {
                        fieldName: data.fieldName,
                        value: e.target.value,
                      })
                    }
                    value={formik.values?.basicInformation.details[index]?.value}
                  >
                    <option>{strings.select}</option>
                    <option>{formik.values?.basicInformation.details[index]?.value}</option>

                    {data.dropDown.map((option) => {
                      if (
                        formik.values?.basicInformation.details[index]?.value &&
                        option === formik.values.basicInformation.details[index].value
                      ) {
                        return null; // Skip this option
                      }

                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                  {required && (
                    <div className="text-red-500">{strings.required}</div>
                  )}
                </div>
              );
            })
          )}

        </div>

        <div>
          <h5 className="pt-3 font-bold">{strings.contactInfo}</h5>

          <div className="w-24 flex mt-4">
            <label className="flex items-center">
              <div>
                <input
                  type="radio"
                  className="h-8 w-8 text-purple-500"
                  name="whatsapp"
                  value="whatsapp"
                  onClick={() => {
                    if (whatsapp) {
                      setWhatsapp(false);
                      setSelectedOptions((prevSelectedOptions) =>
                        prevSelectedOptions.filter(
                          (option) => option !== "Whatsapp"
                        )
                      );
                    } else {
                      setWhatsapp(true);
                      setSelectedOptions((prevSelectedOptions) => [
                        ...prevSelectedOptions,
                        "Whatsapp",
                      ]);
                    }
                  }}
                  checked={whatsapp}
                />
              </div>
              <div className="ml-2">{strings.wtp}</div>
            </label>
          </div>
          <div className="w-24 flex mt-4">
            <label className="flex items-center">
              <div>
                <input
                  type="radio"
                  className="h-8 w-8 text-purple-500"
                  name="telegram"
                  value="telegram"
                  onClick={() => {
                    if (telegram) {
                      setTelegram(false);
                      setSelectedOptions((prevSelectedOptions) =>
                        prevSelectedOptions.filter(
                          (option) => option !== "Telegram"
                        )
                      );
                    } else {
                      setTelegram(true);
                      setSelectedOptions((prevSelectedOptions) => [
                        ...prevSelectedOptions,
                        "Telegram",
                      ]);
                    }
                  }}
                  checked={telegram}
                />
              </div>
              <div className="ml-2">{strings.teleg}</div>
            </label>
          </div>
          <div className="w-24 flex mt-4">
            <label className="flex items-center">
              <div>
                <input
                  type="radio"
                  className="h-8 w-8 text-purple-500"
                  name="call"
                  value="call"
                  onClick={() => {
                    if (call) {
                      setCall(false);
                      setSelectedOptions((prevSelectedOptions) =>
                        prevSelectedOptions.filter(
                          (option) => option !== "Call"
                        )
                      );
                    } else {
                      setCall(true);
                      setSelectedOptions((prevSelectedOptions) => [
                        ...prevSelectedOptions,
                        "Call",
                      ]);
                    }
                  }}
                  checked={call}
                />
              </div>
              <div className="ml-2">{strings.call}</div>
            </label>
          </div>
          <div className="w-24 flex mt-4">
            <label className="flex items-center">
              <div>
                <input
                  type="radio"
                  className="h-8 w-8 text-purple-500"
                  name="sms"
                  value="sms"
                  onClick={() => {
                    if (sms) {
                      setSms(false);
                      setSelectedOptions((prevSelectedOptions) =>
                        prevSelectedOptions.filter((option) => option !== "Sms")
                      );
                    } else {
                      setSms(true);
                      setSelectedOptions((prevSelectedOptions) => [
                        ...prevSelectedOptions,
                        "Sms",
                      ]);
                    }
                  }}
                  checked={sms}
                />
              </div>
              <div className="ml-2">{strings.sms}</div>
            </label>
          </div>

          <h5 className="pt-2 font-bold">{strings.internalFeatures}</h5>

          <div className="grid grid-cols-4 lg:grid-cols-7 gap-4 mt-2">
            {getInternalFeatures.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item, index) => {
              const isActive =
                internalFeatures.findIndex(
                  (feature) => feature.fieldName === item.fieldName
                ) !== -1;
              return (
                <div
                  className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                    }`}
                  key={item._id}
                  onClick={() =>
                    handleInternalFeatures(item.fieldName, item.imageUrl)
                  }
                >
                  <img className="w-12" src={item.imageUrl} alt="" />
                  <h6 className="mt-2">{item.fieldName}</h6>
                </div>
              );
            })}
          </div>
          <h5 className="pt-2 font-bold">{strings.extFeatures}</h5>
          <div className="grid grid-cols-4 lg:grid-cols-7 gap-4 mt-2">
            {getExternalFeatures.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item, index) => {
              const isActive =
                externalFeatures.findIndex(
                  (feature) => feature.fieldName === item.fieldName
                ) !== -1;
              return (
                <div
                  className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                    }`}
                  key={item._id}
                  onClick={() =>
                    handleExternalFeatures(item.fieldName, item.imageUrl)
                  }
                >
                  <img className="w-12" src={item.imageUrl} alt="" />
                  <h6 className="mt-2">{item.fieldName}</h6>
                </div>
              );
            })}
          </div>
          <h5 className="pt-2 font-bold">{strings.accessToTr}n</h5>
          <div className="grid grid-cols-4 lg:grid-cols-7 gap-4 mt-2">
            {getAccessToTransportation.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item, index) => {
              const isActive =
                accessToTransportation.findIndex(
                  (feature) => feature.fieldName === item.fieldName
                ) !== -1;
              return (
                <div
                  className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                    }`}
                  key={item._id}
                  onClick={() =>
                    handleAccessToTransportation(item.fieldName, item.imageUrl)
                  }
                >
                  <img className="w-12" src={item.imageUrl} alt="" />
                  <h6 className="mt-2">{item.fieldName}</h6>
                </div>
              );
            })}
          </div>

          <h5 className="pt-2 font-bold">{strings.neigh}</h5>
          <div className="grid grid-cols-4 lg:grid-cols-7 gap-4 mt-2">
            {getEnvironment.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item, index) => {
              const isActive =
                environment.findIndex(
                  (feature) => feature.fieldName === item.fieldName
                ) !== -1;
              return (
                <div
                  className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                    }`}
                  key={item._id}
                  onClick={() =>
                    handleEnvironment(item.fieldName, item.imageUrl)
                  }
                >
                  <img className="w-12" src={item.imageUrl} alt="" />
                  <h6 className="mt-2">{item.fieldName}</h6>
                </div>
              );
            })}
          </div>
          <h5 className="pt-2 font-bold">{strings.fac}</h5>
          <div className="grid grid-cols-4 lg:grid-cols-7 gap-4 mt-2">
            {getFacilities.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item, index) => {
              const isActive =
                facilities.findIndex(
                  (feature) => feature.fieldName === item.fieldName
                ) !== -1;
              return (
                <div
                  className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                    }`}
                  key={item._id}
                  onClick={() =>
                    handleFacilities(item.fieldName, item.imageUrl)
                  }
                >
                  <img className="w-12" src={item.imageUrl} alt="" />
                  <h6 className="mt-2">{item.fieldName}</h6>
                </div>
              );
            })}
          </div>

          <h5 className="pt-2 font-bold">{strings.locationSettings}</h5>
          <div className="mt-2">
            <input
              className="w-full bg-white  border-2 p-3 rounded-full "
              type="text"
              placeholder={strings.country}
              onChange={(e) => {
                formik.setFieldValue("location.country", e.target.value);
              }}
              value={formik.values.location.country}
            />
          </div>
          <div className="mt-2">
            <input
              className="w-full bg-white  border-2 p-3 rounded-full "
              type="text"
              placeholder={strings.city}
              onChange={(e) => {
                formik.setFieldValue("location.city", e.target.value);
              }}
              value={formik.values.location.city}
            />
          </div>
          <div className="mt-2">
            <input
              className="w-full bg-white  border-2 p-3 rounded-full "
              type="text"
              placeholder={strings.distt}
              onChange={(e) => {
                formik.setFieldValue("location.district", e.target.value);
              }}
              value={formik.values.location.district}
            />
          </div>
          <div className="mt-2">
            <input
              className="w-full bg-white  border-2 p-3 rounded-full "
              type="text"
              placeholder={strings.neigh}
              onChange={(e) => {
                formik.setFieldValue("location.neighborhood", e.target.value);
              }}
              value={formik.values.location.neighborhood}
            />
          </div>
          <div className="mt-2">
            <input
              className="w-full bg-white  border-2 p-3 rounded-full "
              type="text"
              placeholder={strings.complex}
              onChange={(e) => {
                formik.setFieldValue("location.complex", e.target.value);
              }}
              value={formik.values.location.complex}
            />
          </div>
          <div className="mt-2">
            <input
              className="w-full bg-white  border-2 p-3 rounded-full "
              type="text"
              placeholder={strings.openadd}
              onChange={(e) => {
                formik.setFieldValue("location.openAddress", e.target.value);
              }}
              value={formik.values.location.openAddress}
            />
          </div>

          <div className="container mt-3">
            <div>Location</div>
            <div className="mt-2">
              <input type="text" placeholder={strings.city} className="w-full border-1  bg-white shadow-md p-3 rounded-full"
                onChange={(e) => formik.setFieldValue('location.city', e.target.value)}
                value={formik.values.location.city}
                readOnly={isLocationAvailable}
              />
            </div>
            <div className="mt-4">
              <input type="text" placeholder={strings.distt} className="w-full border-1  bg-white shadow-md p-3 rounded-full"
                onChange={(e) => formik.setFieldValue('location.district', e.target.value)}
                value={formik.values.location.district}
                readOnly={isLocationAvailable}
              />
            </div>
            <div className="mt-4">
              <input type="text" placeholder={strings.country} className="w-full border-1  bg-white shadow-md p-3 rounded-full"
                onChange={(e) => formik.setFieldValue('location.country', e.target.value)}
                value={formik.values.location.country}
                readOnly={isLocationAvailable}
              />
            </div>
          </div>

          <div
            className="container mt-3 h-[450px]"
          >

            <OpenStreetMap
              style={{
                height: '400px'
              }}
              formik={formik}
              setIsLocationAvailable={setIsLocationAvailable}
            />
          </div>

          <h3 className="text-sm mt-5">{strings.upload} {strings.image}</h3>
          <div className="col-12 bg-white shadow-lg rounded-lg mt-4">
            <div className="ml-auto mr-auto w-full justify-center p-3">
              <h6 className="text-center"> {/* Add the 'text-center' class */}
                {strings.upload} {strings.images}
              </h6>
              <form onSubmit={handleSubmit}>
                <div className="flex w-100 justify-content-center"> {/* Add the 'justify-content-center' class */}
                  {Array.from(new Set(files.map(file => file.image))).map((image, index) => (
                    <div className="m-2" key={index}>
                      <div className="image-container">
                        <button className="remove-image" onClick={() => removeImage(index)}>
                          <FaTimes />
                        </button>
                        <img src={`${image}`} alt="" className="h-36 w-40" />

                      </div>
                    </div>
                  ))}
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    multiple
                    onChange={(e) => {
                      handleImageUpload(e.target.files);
                    }}
                  />
                </div>
                <div className="col-md-12 flex justify-center mt-2">
                  <button
                    onClick={handleClick}
                    className="bg-orange-500 rounded-md py-2 px-4 text-white cursor-pointer w-40 text-center"
                    disabled={imageLoading}
                  >
                    {imageLoading ? strings.uploading : `${strings.add} ${strings.image}`}
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* Cover Photo */}
          <h3 className="text-sm mt-5">
            {strings.upload} {strings.coverPhoto}
          </h3>
          <div className="col-12 bg-white shadow-lg rounded-lg mt-4">
            <div className="ml-auto mr-auto w-full justify-center p-3">
              <h6 className="text-center">
                {" "}
                {/* Add the 'text-center' class */}
                {strings.upload} {strings.coverPhoto}
              </h6>
              <form onSubmit={handleSubmit}>
                <div className="flex w-100 justify-content-center">
                  {" "}
                  {
                    formik.values.coverPhoto !== '' && (
                      <div className="m-2" >
                        <div className="image-container">

                          <img src={`${formik?.values?.coverPhoto}`} alt="" className="h-36 w-40" />
                        </div>
                      </div>
                    )
                  }
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={hiddenCoverFileInput}
                    onChange={(e) => {
                      uploadCoverPhoto(e.target.files[0]);
                    }}
                  />
                </div>
                <div className="col-md-12 flex justify-center mt-2">
                  <button
                    onClick={handleCoverPhotoClick}
                    className="bg-orange-500 rounded-md py-2 px-4 text-white cursor-pointer w-40 text-center"
                    disabled={coverPhotoLoading}
                  >
                    {coverPhotoLoading
                      ? strings.uploading
                      : `${strings.add} ${strings.image}`}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="container mt-4 h-max p-2">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <h3 className="text-sm">{strings.upload} {strings.video}</h3>
                <div className="uploadimage">
                  <h4>{strings.upload} {strings.video}</h4>
                  <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => {
                      handleVideoUpload(e.target.files[0]);
                    }}
                    style={{ display: "none" }}
                    ref={hiddenVideoFileInput}
                  />

                  {videoFile && (
                    <div className="video-container">
                      <button className="remove-video" onClick={handleRemoveVideo}>
                        <FaTimes />
                      </button>
                      <video controls className="h-32 w-48">
                        <source src={videoFile} type={videoFile.type} />
                      </video>
                    </div>
                  )}

                  <div className="d-flex justify-content-center">
                    {
                      !videoFile && (
                        <button
                          className="bg-orange-500 rounded-md py-2 px-4 text-white cursor-pointer w-40 text-center"
                          onClick={handleVideoClick}
                          disabled={videoLoading}
                        >
                          {videoLoading ? strings.uploading : `${strings.add} ${strings.video}`}
                        </button>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-4 ">
            <button
              type="submit"
              className="w-80 p-3 rounded-full text-white mb-5 bg-orange-500"
              onClick={formik.handleSubmit}
            >
              {strings.next}
            </button>
          </div>
        </div>
      </div>

      {/*.........................Footer Section.....................*/}
      <Footer strings={strings} />
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">{strings.notif}</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          {
            notificationData.map((data, index) => (
              <div key={index}
                className={`p-2 ${index % 2 === 0 ? "bg-gray-300" : ""
                  }`}
              >
                <p style={{ fontWeight: 'bold' }}>{index + 1}. {data.title}</p>
                <p>{data.message}</p>
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
};

export default EditState;
