import React from 'react';
import { useFormik } from 'formik';
import Lock2 from ".././images/Lock2.svg";
import strings from '../utils/locals/languages';

const SelectRecoveryMethod = ({setSelectedForm}) => {
    return (
        <div className="container rounded-lg" style={{ background: "#F4F5F9" }}>
            <div className="p-4">
                <h5 className="text-center font-bold">
                    {strings.forgotPassword}
                </h5>
                <p className="text-center pt-2">
                    {strings.pleaseSelectRecoveryMethod}
                </p>
            </div>

            <div className="flex justify-center">
                <img className="w-16" src={Lock2} alt="" />
            </div>

            <div className="flex justify-center">
                <button
                    type="button"
                    className="h-16 w-72 mt-5 bg-orange-600 text-white rounded-full"
                    onClick={() => setSelectedForm("RecoverWithEmail")}
                >
                    {strings.emailAddress}
                </button>
            </div>

            <div className="flex justify-center">
                <button
                    type="button"
                    className="h-16 w-72 mt-4 bg-orange-600 text-white rounded-full mb-5"
                    onClick={() => setSelectedForm("ResetWithPhoneNumber")}
                >
                    {strings.mobileNumber}
                </button>
            </div>

        </div>
    );
}

export default SelectRecoveryMethod;
