import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import strings from "../utils/locals/languages";
import { setLanguage } from "../app/languageSlice";
import { useDispatch } from "react-redux";
import { baseURL1 } from "../utils/baseUrl";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EmailIcon from '@mui/icons-material/Email';
import { ClipLoader } from "react-spinners";
import { getFcmToken } from "../firebase";


const validate = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = `${strings.required}`
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = `${strings.invalidEmail}`
    }
    if (!values.password) {
        errors.password = `${strings.required}`
    }
    return errors;
};

const EmailLogin = ({ setSelectedForm }) => {

    const [isLocked, setIsLocked] = useState(true)
    const [showPassword, setShowPassword] = useState(false)
    const dispatch = useDispatch();
    const handleLanguageChange = (language) => {
        strings.setLanguage(language); // set the language for the LocalizedStrings instance
        dispatch(setLanguage(language)); // dispatch an action to update the language in the store
    };
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            webToken: ""
        },
        validate,
        onSubmit: () => {
            setIsLoading(true);
            setTimeout(handleSave, 2000);
        },
    });

    const handleSave = () => {
        submitData(formik.values);
    };

    const submitData = (data) => {
        axios
            .post(`${baseURL1}/v1/user/email-login`, data)
            .then((res) => {
                setIsLoading(false);
                toast.success(`${strings.loginSuccess}`)
                Cookies.set("token", res.data.token, { expires: 7 });
                Cookies.set("userDetails", JSON.stringify(res.data.user), { expires: 7 });
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error(err.response.data.error)
            });
    };


    const getDeviceToken = async () => {
        try {
            const token = await getFcmToken()
            formik.setFieldValue('webToken', token)
        } catch (error) {
            formik.setFieldValue('webToken', '')
        }
    }


    useEffect(() => {
        getDeviceToken()
    }, [])

    return (
        <div className="container rounded-lg" style={{ background: "#F4F5F9" }}>
            <h4 className="fw-bold mt-4 text-center">{strings.login}</h4>
            <p className="text-center">{strings.pleaseSignIn}</p>
            <div className="flex flex-col items-center">
                <div className="mt-3">
                    <div className="relative">

                        <EmailIcon
                            className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6"
                            style={{
                                color: "grey"
                            }}
                        />
                        <input
                            className="bg-white shadow-md rounded-full w-80  outline-orange-600 p-3"
                            type="text"
                            placeholder={strings.email}
                            id="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-red-500">{formik.errors.email}</div>
                        ) : null}
                    </div>
                </div>

                <div className="mt-3">
                    <div className="relative">

                        {
                            isLocked ? (
                                <LockIcon
                                    className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                                    style={{
                                        color: "grey"
                                    }}
                                    onClick={() => {
                                        setIsLocked(false)
                                        setShowPassword(true)
                                    }}
                                />
                            ) : (
                                <LockOpenIcon
                                    className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                                    style={{
                                        color: "grey"
                                    }}
                                    onClick={() => {
                                        setIsLocked(true)
                                        setShowPassword(false)
                                    }}
                                />
                            )
                        }

                        <input
                            className="bg-white shadow-md rounded-full w-80 outline-orange-600  p-3"
                            type={showPassword ? 'text' : 'password'}
                            placeholder={strings.pass}
                            id="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="text-red-500">{formik.errors.password}</div>
                        ) : null}
                    </div>
                </div>

                <button
                    onClick={formik.handleSubmit}
                    className="mt-3 bg-orange-600 text-white shadow-md rounded-full w-80  p-3"
                >
                    {isLoading ? (
                        <>
                            <ClipLoader size={15} color="#fff" /> {strings.loading}
                        </>
                    ) : strings.login}
                </button>
            </div>
            <div style={{ background: "#F4F5F9" }}>
                <div className="d-flex text-orange-600 justify-content-center mt-4 cursor-pointer">
                    <div
                        onClick={() => setSelectedForm("RecoverWithEmail")}
                    >
                        {strings.forgotPassword}
                    </div>
                </div>

                <div className="flex justify-center">
                    <button
                        className=" p-3 w-72 mt-3 bg-orange-600 text-white rounded-full"
                        onClick={() => setSelectedForm("SignInWithPhone")}
                    >
                        {strings.withNumber}
                    </button>
                </div>

                <div className="flex justify-center mb-3">
                    <span>
                        {strings.dontHave}{" "}
                        <button
                            className="ms-2 text-orange-600"
                            onClick={() => setSelectedForm("SelectSignUpMethod")}
                        >
                            {strings.signUp}
                        </button>{" "}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default EmailLogin;
