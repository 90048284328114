import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // For validation
import Verfiy from ".././images/Verfiy.png";
import ClipLoader from "react-spinners/ClipLoader";
import strings from '../utils/locals/languages';
import { baseURL1 } from '../utils/baseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const PhoneSignUpVerfCode = ({ setSelectedForm, phoneNbr }) => {

    const [isLoading, setIsLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: Yup.object({
            otp: Yup.string()
                .required(strings.otpRequired)
                .length(4, strings.otpLength),
        }),
        onSubmit: (values) => {
            verifySignUp(values.otp);
        },
    });

    const verifySignUp = async (otp) => {
        console.log("OWTPW", otp)
        try {
            setIsLoading(true)
            const response = await axios.post(`${baseURL1}/v1/user/verify-phone-user`, {
                code: otp,
                phone: phoneNbr
            })

            if (response) {
                toast.success(response.data.message)
                Cookies.set("token", response.data.token, { expires: 7 });
                Cookies.set("userDetails", JSON.stringify(response.data.user), { expires: 7 });
                setTimeout(() => {
                    window.location.reload();
                }, 500);
                setIsLoading(false)
            } else {
                setIsLoading(false)
                toast.error(response.data.message)
            }
        } catch (err) {
            console.log(err)
            setIsLoading(false)
            toast.error(strings.invalidCode)
        }
    };

    return (
        <div className="container rounded-lg" style={{ background: "#F4F5F9" }}>
            <h4 className="mt-5 text-center">{strings.enterTheCode}</h4>
            <p>{strings.codeThatReceived}</p>
            <div className="flex justify-center">
                <img src={Verfiy} alt="" style={{ width: "180px", height: "130px" }} />
            </div>

            <div className="flex justify-center mt-5">
                <input
                    className="bg-white shadow-md rounded-full w-80 p-3"
                    type="text"
                    placeholder={strings.enterTheCode}
                    id="otp"
                    name="otp"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.otp}
                />
            </div>

            {formik.touched.otp && formik.errors.otp ? (
                <div className="text-red-500 text-center mt-2">
                    {formik.errors.otp}
                </div>
            ) : null}

            <div className="flex justify-center mt-4">
                <button
                    type="submit"
                    className="h-16 w-80 mt-2 bg-orange-600 text-white rounded-full mb-5"
                    disabled={formik.isSubmitting}
                    onClick={formik.handleSubmit}
                >
                    {isLoading ? (
                        <>
                            <ClipLoader size={12} color="#fff" /> {strings.loading}
                        </>
                    ) : (
                        strings.verify
                    )}
                </button>
            </div>
        </div>
    );
};

export default PhoneSignUpVerfCode;
