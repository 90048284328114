import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // For validation
import Lock2 from ".././images/Lock2.svg";
import phone from ".././images/phone.svg";
import ClipLoader from "react-spinners/ClipLoader";
import strings from '../utils/locals/languages';

const ResetWithPhoneNumber = () => {
    const formik = useFormik({
        initialValues: {
            phoneNumber: '',
        },
        validationSchema: Yup.object({
            phoneNumber: Yup.string()
                .required(strings.mobileNumberRequired)
                .matches(
                    /^[0-9]{10}$/,
                    strings.invalidPhoneNumber
                ),
        }),
        onSubmit: (values) => {
            sendPhoneCodeRequest(values.phoneNumber);
        },
    });

    // Function to handle phone code request
    const sendPhoneCodeRequest = (phoneNumber) => {
        console.log("Requesting code for phone number:", phoneNumber);
        // Add logic to send the request for OTP or verification here
    };

    return (
        <>
            <div className="container rounded-lg" style={{ background: "#F4F5F9" }}>
                <div className="p-4">
                    <h5 className="text-center font-bold">{strings.forgotPassword}</h5>
                    <p className="text-center pt-2">{strings.pleaseEnterNumber}</p>
                </div>

                <div className="flex justify-center">
                    <img className="w-16" src={Lock2} alt="" />
                </div>

                <form onSubmit={formik.handleSubmit}>
                    <div className="flex justify-center mt-3">
                        <div className="relative">
                            <img
                                className="absolute py-3 w-6 right-6 lg:right-6 md:right-6"
                                src={phone}
                                alt=""
                            />
                            <input
                                className="bg-white shadow-md rounded-full w-80 p-3 border-orange-400 border-2 outline-orange-600"
                                type="text"
                                placeholder={strings.mobileNumber}
                                id="phoneNumber"
                                name="phoneNumber"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phoneNumber}
                            />
                        </div>
                    </div>

                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                        <div className="text-red-500 text-center mt-2">
                            {formik.errors.phoneNumber}
                        </div>
                    ) : null}

                    <div className="flex justify-center mt-4">
                        <button
                            type="submit"
                            className="bg-orange-500 w-80 shadow-md rounded-full h-15 p-3 mb-4 text-white"
                            disabled={formik.isSubmitting}
                        >
                            {formik.isSubmitting ? (
                                <>
                                    <ClipLoader size={12} color="#fff" /> {strings.loading}
                                </>
                            ) : (
                                strings.sendRequest
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default ResetWithPhoneNumber;
