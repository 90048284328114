import React, { useState, useEffect } from "react";

import mobile from "../../images/Mobil.png";
import appstore from "../../images/appstore.png";
import googleplay from "../../images/googleplay.png";
import calendar from "../../images/calendar.svg";

import Group11921 from "../../images/Group 11921.svg";
import Group11922 from "../../images/Group 11922.svg";
import Group11923 from "../../images/Group 11923.svg";
import Group11924 from "../../images/Group 11924.svg";
import area from "../../images/ar.svg";
import beds from "../../images/bedss.svg";
import wash from "../../images/wshs.svg";
import year from "../../images/yeear.svg";
import engineSize from "../../images/engine.svg";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ClipLoader } from 'react-spinners'

import "./index.css";
import strings from "../../utils/locals/languages";
import { useSelector } from "react-redux";
import { baseURL1, realEstateId, vehicleId } from "../../utils/baseUrl";
import apiClient from "../../utils/apiClient";
import Footer from "../../components/Footer";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { handleCarClick, handleRealEstateClick } from "../../services/handlers";
// import { io } from "socket.io-client";
import { regions } from "../../utils/regions";

const Body = ({ search }) => {

  // useEffect(() => {
  //   const socket = io("http://localhost:1335");

  //   socket.emit("joinRoom", "6721cdebeadd525f1ea7c720");


  //   socket.on("jobStatusChanged", (data) => {
  //     console.log("JOB", data)
  //   });


  //   // Cleanup on component unmount
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, [])
  const user = Cookies.get("userDetails");

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [realEstatesLoading, setRealEstatesLoading] = useState(true);
  const [reLoad, setReLoad] = useState(0);
  const language = useSelector((state) => state.language);
  const token = Cookies.get("token");
  const [userId, setUserId] = useState("");
  const [selectedCityData, setSelectedCityData] = useState([])
  const [selectedCity, setSelectedCity] = useState("")
  const [partners, setPartners] = useState([])
  const [internalAds, setInternalAds] = useState([])
  const [citiesLoading, setCitiesLoading] = useState(false)

  const openInAppStore = () => {
    window.open(
      "https://apps.apple.com/us/app/egasi-net/id6639613472?platform=iphone",
      "_blank",
      "noopener,noreferrer"
    );
  };


  const openOnGooglePlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.esols.egasi&pcampaignid=web",
      "_blank",
      "noopener,noreferrer"
    );
  };



  const addToFavorites = (event, adId) => {
    event.stopPropagation();

    apiClient
      .get(`/v1/user/fav-unfav/${adId}`)
      .then((res) => {
        // Delay the execution by 2 seconds
        setTimeout(() => {
          apiClient
            .get(
              `${baseURL1}/v1/adds/search/?section=${vehicleId}&language=${strings._language}`
            )
            .then((response) => {
              setVehicleData(response.data.data);
              setIsLoading(false);
            })
            .catch((err) => { });

          apiClient
            .get(
              `/v1/adds/search/?section=${realEstateId}&language=${strings._language}`
            )
            .then((response) => {
              setData(response.data.data);
              setRealEstatesLoading(false);
            })
            .catch((err) => { });
        }, 2000); // 2000 milliseconds = 2 seconds
      })
      .catch((err) => { });
  };

  useEffect(() => {
    setIsLoading(true);
    setRealEstatesLoading(true);
    apiClient
      .get(
        `${baseURL1}/v1/adds/search/?section=${vehicleId}&language=${strings._language}`
      )
      .then((response) => {
        setVehicleData(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => { });


    apiClient
      .get(
        `/v1/adds/search/?section=${realEstateId}&language=${strings._language}`
      )
      .then((response) => {
        setData(response.data.data);
        setRealEstatesLoading(false);
      })
      .catch((err) => { });
  }, [strings._language]);

  const getInternalAds = async () => {
    try {
      const response = await apiClient.get('v1/user/get-internal-ads')

      setInternalAds(response.data)
    } catch {
      setInternalAds([])
    }
  }

  useEffect(() => {
    let filteredData = vehicleData.filter((item) => {
      return search !== ""
        ? item.basicInformation.title
          .toLowerCase()
          .includes(search.toLowerCase())
        : item;
    });

    let filteredRealEstateData = data.filter((item) => {
      return search !== ""
        ? item.basicInformation.title
          .toLowerCase()
          .includes(search.toLowerCase())
        : item;
    });

    setVehicleData(filteredData);
    setData(filteredRealEstateData);
  }, [search]);

  useEffect(() => {
    const userDetails = Cookies.get("userDetails");

    if (userDetails) {
      const user = JSON?.parse(userDetails);
      setUserId(user._id);
    } else {
      setUserId("0");
    }
  }, []);

  const fetchPartners = async () => {
    try {
      const response = await apiClient.get('/v1/user/view-partners?location=homePage');
      setPartners(response.data.partners);
    } catch (err) {
      setPartners([])
    }
  };


  useEffect(() => {
    fetchPartners()
    getInternalAds()
  }, [])

  const getCities = async (region) => {
    setSelectedCity(region.title)
    setCitiesLoading(true)
    try {
      const response = await apiClient.get(`/v1/adds/region-ads?region=${region.value}&language=${strings._language}`);

      setSelectedCityData(response.data.ads)
      setCitiesLoading(false)

    } catch (error) {
      setCitiesLoading(false)
    }
  };


  const itemsToShow = 5

  const itemWidth = 300
  const [scrollPosition, setScrollPosition] = useState(0);

  const isVehicleEndReached = (vehicleData.length * 250)
  const isVehicleStartReached = scrollPosition === 0;

  const vehicleNextButtonClickHandler = () => {
    const nextPosition = Math.min(
      scrollPosition + itemWidth,
      ((vehicleData.length + 2) - itemsToShow) * itemWidth
    );

    if (isVehicleEndReached >= scrollPosition) {
      setScrollPosition(nextPosition);
    }
  };

  const vehiclePrevButtonClickHandler = () => {
    const prevPosition = Math.max(scrollPosition - itemWidth, 0);
    setScrollPosition(prevPosition);
  };


  const [realEstateScrollPosition, setRealEstateScrollPosition] = useState(0);

  const isRealEstateEndReached = (data.length * 250)
  const isRealEstateStartReached = realEstateScrollPosition === 0;

  const realEstateNextButtonClickHandler = () => {
    const nextPosition = Math.min(
      realEstateScrollPosition + itemWidth,
      ((data.length + 2) - itemsToShow) * itemWidth
    );

    if (isRealEstateEndReached >= realEstateScrollPosition) {
      setRealEstateScrollPosition(nextPosition);
    }
  };

  const realEstatePrevButtonClickHandler = () => {
    const prevPosition = Math.max(realEstateScrollPosition - itemWidth, 0);
    setRealEstateScrollPosition(prevPosition);
  };



  return (
    <div className=" space-y-10 ">

      {/* section 2 */}
      <div className="container">
        <div className="row">
          <Carousel
            showIndicators={false}
            showThumbs={false}
            interval={3000}
            autoPlay={true}
            infiniteLoop
          >

            {
              internalAds.filter((ad) => ad.imageUrl).map((item, index) => (
                <div className="md:h-[200px] h-[100px]">
                  <img src={item?.imageUrl} className="rounded-md h-full " />

                </div>
              ))
            }
          </Carousel>

        </div>
      </div>
      {/* section 3 */}

      {/* <div className="bg-success p-2 w-[100px] ml-auto mr-auto text-center text-white cursor-pointer"
      onClick={handlePayment}
      >
        Pay
      </div> */}


      <hr className=" opacity-10 " />

      {/* section 4 */}
      <div className=" mx-auto ">
        {isLoading ? (


          <div className="container ">
            <div>
              {" "}
              <Skeleton
                height={30}
                width={350}
                style={{
                  marginBottom: "24px",
                }}
              />
            </div>

            <div className="container">
              <div className="grid grid-cols-1  md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-3 justify-center">
                {[1, 2, 3, 4, 5].map((_, index) => (
                  <div
                    className=" shadow-xl rounded-xl cursor-pointer "
                    key={index}
                  >
                    <div className="relative h-40">
                      <Skeleton
                        height={160}
                        style={{
                          borderTopLeftRadius: "12px",
                          borderTopRightRadius: "12px",
                        }}
                      />
                    </div>
                    <div className="px-[10px] mt-2">
                      <div
                        className="flex items-center justify-between"
                      >
                        <Skeleton height={15} width={70} />
                        <Skeleton height={15} width={50} />
                      </div>
                      <div className="flex gap-2 ">
                        <Skeleton height={10} width={100} />
                      </div>
                      <div
                        className="flex mb-1  text-[10px] justify-between items-center text-gray-400"
                      >
                        <div className="flex gap-2 items-center">
                          <Skeleton height={10} width={20} />
                          <Skeleton height={10} width={20} />
                        </div>

                        <div className="flex gap-2 items-center">
                          <Skeleton height={10} width={20} />
                          <Skeleton height={10} width={20} />
                        </div>

                        <div className="flex gap-2 items-center">
                          <Skeleton height={10} width={20} />
                          <Skeleton height={10} width={20} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="container ">

            <div className="flex justify-between ">
              <h4 className="uppercase font-semibold text-[20px] mb-4">
                {strings.recommendedVehicles}
              </h4>
              <div className="flex">
                <ArrowBackIosNewIcon
                  className={`cursor-pointer  rounded-full text-xl p-1 bg-white `}
                  onClick={() => vehiclePrevButtonClickHandler()}
                />
                <ArrowForwardIosIcon
                  className={`cursor-pointer  rounded-full text-xl p-1 ml-1 bg-white `}
                  onClick={() => vehicleNextButtonClickHandler()}
                />
              </div>
            </div>

            <div className="container p-0 ">
              {vehicleData.length === 0 ? (
                <div className="flex justify-center">
                  <h3>{strings.norec}</h3>
                </div>
              ) : (
                <div
                  className='flex pb-2  gap-4 overflow-x-hidden '
                >
                  {vehicleData.map((item, index) => (
                    <div
                      key={index}
                      className=" w-[250px]  h-[260px] min-w-[250px]  flex flex-col justify-between shadow-md rounded-xl  cursor-pointer "
                      style={{
                        transition: 'transform 0.3s',
                        transform: `translateX(-${scrollPosition}px)`,
                      }}

                      onClick={() => handleCarClick(navigate, item._id, item.basicInformation.title, item.basicInformation.category.title, item.basicInformation.brand.title, item.basicInformation.model.title, item.location.city, item.location.district, item.basicInformation.KM)}


                    >
                      <div className="relative h-[170px] w-[100%]">
                        {!item?.fav?.includes(userId) ? (
                          <FavoriteBorderOutlinedIcon
                            className="absolute items-content-end w-12"
                            style={{
                              right: "8px",
                              color: "#F37521",
                              backgroundColor: "white",
                              borderRadius: 100,
                              padding: 3,
                              top: 8,
                            }}
                            onClick={(event) => {
                              if (user) {
                                event.stopPropagation()
                                addToFavorites(event, item._id);
                              }
                            }}
                          />
                        ) : (
                          <FavoriteOutlinedIcon
                            className="absolute items-content-end w-12 opacity-100"
                            style={{
                              right: "8px",
                              color: "red",
                              backgroundColor: "white",
                              borderRadius: 100,
                              padding: 3,
                              top: 8,
                            }}
                            onClick={(event) => {
                              if (user) {
                                event.stopPropagation()
                                addToFavorites(event, item?._id);
                              }
                            }}
                          />
                        )}
                        <img
                          className=" rounded-t-2xl"
                          src={item.coverPhoto || item.imageUrl[0]}
                          alt="random"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                            // borderTopRightRadius: 12,
                            // borderTopLeftRadius: 12,
                          }}
                        />
                      </div>
                      <div className="px-[10px]  mt-2">
                        <div
                          className="flex items-center justify-between"
                        >
                          <div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[70%]">
                            <h3 className="font-medium text-sm">
                              {item?.basicInformation?.title.length > 14
                                ? `${item.basicInformation.title.slice(0, 12)}...`
                                : item.basicInformation.title}
                            </h3>
                          </div>

                          <div className="flex items-center text-[#F37521] ml-2">
                            <h1 className="font-bold text-sm whitespace-nowrap">
                              {item?.basicInformation?.price?.currency}&nbsp;
                            </h1>
                            <h1 className="font-bold text-sm whitespace-nowrap">
                              {Number(item?.basicInformation?.price?.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </h1>
                          </div>
                        </div>

                        <div className="flex gap-2 -mb-2">
                          <h1 className="text-[9px] font-[350] text-gray-400 ">
                            {item?.basicInformation?.description?.slice(0, 25)}
                          </h1>
                        </div>
                        <div
                          className="flex -mb-1 text-[10px] justify-between items-center text-gray-400"

                        >
                          <div className="flex gap-2 items-center">
                            <img src={year} alt="year" className="h-3 w-3" />
                            <p className=" mt-[17px]">
                              {item?.basicInformation?.details[0]?.value}
                            </p>
                          </div>

                          <div className="flex gap-2 items-center">
                            <img
                              src={engineSize}
                              alt="year"
                              className="h-3 w-3"
                            />
                            <p className=" mt-[17px]">
                              {item?.basicInformation?.details
                                ?.filter((item) =>
                                  [
                                    "Engine Size",
                                    "Размер двигателя",
                                    "Dvigatel hajmi",
                                  ].includes(item.fieldName)
                                )
                                .map((filteredItem, index) => (
                                  <span key={index}>{filteredItem?.value}</span>
                                ))}
                            </p>
                          </div>

                          <div className="flex gap-2 items-center">
                            <img
                              src={calendar}
                              color="gray"
                              alt="year"
                              className="h-3 w-3 opacity-60"
                            />
                            <p className=" mt-[17px]">
                              {item?.basicInformation?.KM}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <hr className=" opacity-10 " />

      <div className=" mx-auto">
        {realEstatesLoading ? (

          <div className="container ">
            <div>
              {" "}
              <Skeleton
                height={30}
                width={350}
                style={{
                  marginBottom: "24px",
                }}
              />
            </div>

            <div className="">
              <div className="grid grid-cols-1  md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-3 justify-center">
                {[1, 2, 3, 4, 5].map((_, index) => (
                  <div
                    className="c shadow-xl rounded-xl  cursor-pointer "
                    key={index}
                  >
                    <div className="relative h-40">
                      <Skeleton
                        height={160}
                        style={{
                          borderTopLeftRadius: "12px",
                          borderTopRightRadius: "12px",
                        }}
                      />
                    </div>
                    <div className="px-[10px] mt-2">
                      <div
                        className="flex items-center justify-between"
                      >
                        <Skeleton height={15} width={70} />
                        <Skeleton height={15} width={50} />
                      </div>
                      <div className="flex gap-2 ">
                        <Skeleton height={10} width={100} />
                      </div>
                      <div
                        className="flex mb-1  text-[10px] justify-between items-center text-gray-400"
                      >
                        <div className="flex gap-2 items-center">
                          <Skeleton height={10} width={20} />
                          <Skeleton height={10} width={20} />
                        </div>

                        <div className="flex gap-2 items-center">
                          <Skeleton height={10} width={20} />
                          <Skeleton height={10} width={20} />
                        </div>

                        <div className="flex gap-2 items-center">
                          <Skeleton height={10} width={20} />
                          <Skeleton height={10} width={20} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="container ">
            <div className="flex justify-between ">
              <h4 className="uppercase font-semibold text-[20px] mb-4">
                {strings.recommendedRealEstates}
              </h4>
              <div className="flex">
                <ArrowBackIosNewIcon
                  className={`cursor-pointer  rounded-full text-xl p-1 bg-white `}
                  onClick={() => realEstatePrevButtonClickHandler()}
                />
                <ArrowForwardIosIcon
                  className={`cursor-pointer  rounded-full text-xl p-1 ml-1 bg-white `}
                  onClick={() => realEstateNextButtonClickHandler()}
                />
              </div>
            </div>

            {data.length === 0 ? (
              <div className="flex justify-center">
                <h3>{strings.norec}</h3>
              </div>
            ) : (
              <div className="container p-0">
                <div
                  className='flex gap-4 pb-2 bg-transparent overflow-y-auto md:overflow-hidden hours-container'
                >
                  {data.map((item, index) => (
                    <div
                      className="w-[250px] h-[260px] min-w-[250px]  flex flex-col shadow-md rounded-xl  cursor-pointer "
                      style={{
                        display: 'flex',
                        transition: 'transform 0.3s',
                        transform: `translateX(-${realEstateScrollPosition}px)`,
                      }}

                      onClick={() => handleRealEstateClick(navigate, item._id, item.basicInformation.title, item.basicInformation.category.title, item.basicInformation.brand.title, item.location.city, item.location.district)}

                    >
                      <div
                        className="relative  h-[170px] w-[100%]"
                      // style={{ height: "200px", overflow: "hidden" }}
                      >
                        {!item?.fav?.includes(userId) ? (
                          <FavoriteBorderOutlinedIcon
                            className="absolute items-content-end w-12"
                            style={{
                              right: "8px",
                              color: "#F37521",
                              backgroundColor: "white",
                              borderRadius: 100,
                              padding: 3,
                              top: 8,
                            }}
                            onClick={(event) => {
                              if (user) {
                                event.stopPropagation()
                                addToFavorites(event, item._id);
                              }
                            }}
                          />
                        ) : (
                          <FavoriteOutlinedIcon
                            className="absolute items-content-end w-12"
                            style={{
                              right: "8px",
                              color: "red",
                              backgroundColor: "white",
                              borderRadius: 100,
                              padding: 3,
                              top: 8,
                            }}
                            onClick={(event) => {
                              if (user) {
                                event.stopPropagation()
                                addToFavorites(event, item._id);
                              }
                            }}
                          />
                        )}
                        <img
                          className="rounded-t-2xl"
                          src={item.coverPhoto || item.imageUrl[0]}
                          alt="random"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                            // borderTopRightRadius: 12,
                            // borderTopLeftRadius: 12,
                          }}
                        />
                      </div>
                      <div
                        className="px-[10px] mt-2"
                        style={{ overflow: "hidden" }}
                      >
                        <div
                          className="flex items-center justify-between"
                        >
                          <div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[70%]">
                            <h3 className="font-medium text-sm">
                              {item?.basicInformation?.title.length > 14
                                ? `${item.basicInformation.title.slice(0, 12)}...`
                                : item.basicInformation.title}
                            </h3>
                          </div>

                          <div className="flex items-center text-[#F37521] ml-2">
                            <h1 className="font-bold text-sm whitespace-nowrap">
                              {item?.basicInformation?.price?.currency}&nbsp;
                            </h1>
                            <h1 className="font-bold text-sm whitespace-nowrap">
                              {Number(item?.basicInformation?.price?.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </h1>
                          </div>
                        </div>

                        <div className="flex gap-2 -mb-2">
                          <h1 className="text-[9px] font-[350] text-gray-500 ">
                            {item.basicInformation.description.slice(0, 25)}
                          </h1>
                        </div>
                        <div
                          className="flex -mb-1 text-[10px] justify-between items-center text-gray-400"

                        >
                          {item?.basicInformation?.details.some((item) =>
                            ["Bedrooms", "Number Of Rooms", "Количество комнат", "Xonalari"].includes(item?.fieldName)
                          ) && (
                              <div className="flex gap-2 items-center">
                                <img src={beds} alt="year" className="h-3 w-3" />
                                <p className="mt-[17px]">
                                  {item?.basicInformation?.details
                                    .filter((item) =>
                                      ["Bedrooms", "Number Of Rooms", "Количество комнат", "Xonalari"].includes(item?.fieldName)
                                    )
                                    .map((filteredItem, index) => (
                                      <span key={index}>
                                        {filteredItem?.value === "" ? 1 : filteredItem?.value}
                                      </span>
                                    ))}
                                </p>
                              </div>
                            )}

                          <div className="flex gap-2 items-center">
                            <img src={area} alt="year" className="h-3 w-3" />
                            <p className=" mt-[17px]">
                              {item?.basicInformation?.area?.net}m <sup>2</sup>
                            </p>
                          </div>

                          {item?.basicInformation?.details.some((item) =>
                            ["Bathrooms", "Hammomlar", "Ванные комнаты"].includes(item?.fieldName)
                          ) && (
                              <div className="flex gap-2 items-center">
                                <img
                                  src={wash}
                                  color="gray"
                                  alt="year"
                                  className="h-3 w-3 opacity-60"
                                />
                                <p className=" mt-[17px]">
                                  {item?.basicInformation?.details
                                    .filter((item) =>
                                      ["Bathrooms", "Hammomlar", "Ванные комнаты"].includes(item.fieldName)
                                    )
                                    .map((filteredItem, index) => (
                                      <span key={index}>{filteredItem.value}</span>
                                    ))}
                                </p>
                              </div>
                            )}


                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <hr />

      <div className="container">
        <h4 className="uppercase text-[20px] font-semibold">
          {strings.topCities}
        </h4>
        <div className="flex overflow-x-auto gap-4 mt-4 ">
          {regions[strings._language]?.map((region, index) => (
            <div
              key={index}
              className="relative flex-shrink-0 w-[300px] h-[300px] cursor-pointer rounded-lg overflow-hidden"
              onClick={() => getCities(region)}
            >
              <img
                className="w-full h-full object-cover"
                src={region.image}
                alt={region.title}
              />

              <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-transparent to-transparent"></div>

              <div className="absolute bottom-6 w-full text-center text-lg font-semibold text-white px-4">
                {region.title}
              </div>

              <div className="absolute bottom-1 w-full text-center text-sm text-white opacity-80 px-4">
              </div>
            </div>
          ))}
        </div>


      </div>
      {
        citiesLoading ? <div className="container flex items-center justify-center"> <ClipLoader />  </div> : (
          selectedCityData.length !== 0 ? (
            <div className="container">
              <h3>{selectedCityData.length} Ads</h3>
              <div className="grid grid-cols-1  md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-3 justify-center">
                {selectedCityData.map((item, index) => (
                  <div
                    className="c shadow-xl rounded-xl  cursor-pointer "

                    onClick={() => {
                      if (item.section === "638f25154cb7c29ec6ad4bfe") {
                        handleRealEstateClick(navigate, item._id, item.basicInformation.title, item.basicInformation.category.category[0].title, item.basicInformation.brand.sub_category[0].title, item.location.city, item.location.district)

                      } else {
                        handleCarClick(navigate, item._id, item.basicInformation.title, item.basicInformation.title, item.basicInformation.category.category[0].title, item.basicInformation.brand.sub_category[0].title, item.basicInformation.model.model[0].title, item.location.city, item.location.district, item.basicInformation.KM)

                      }
                    }}

                  >
                    <div className="relative h-40">
                      {!item?.fav.includes(userId) ? (
                        <FavoriteBorderOutlinedIcon
                          className="absolute items-content-end w-12"
                          style={{
                            right: "8px",
                            color: "#F37521",
                            backgroundColor: "white",
                            borderRadius: 100,
                            padding: 3,
                            top: 8,
                          }}
                          onClick={(event) => {
                            if (user) {
                              event.stopPropagation()
                              addToFavorites(event, item._id);
                            }
                          }}
                        />
                      ) : (
                        <FavoriteOutlinedIcon
                          className="absolute items-content-end w-12 opacity-100"
                          style={{
                            right: "8px",
                            color: "red",
                            backgroundColor: "white",
                            borderRadius: 100,
                            padding: 3,
                            top: 8,
                          }}
                          onClick={(event) => {
                            if (user) {
                              event.stopPropagation()
                              addToFavorites(event, item._id);
                            }
                          }}
                        />
                      )}
                      <img
                        className=" rounded-t-2xl"
                        src={item.coverPhoto || item.imageUrl[0]}
                        alt="random"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          // borderTopRightRadius: 12,
                          // borderTopLeftRadius: 12,
                        }}
                      />
                    </div>
                    <div className="px-[10px] mt-2">
                      <div
                        className="flex items-center justify-between"

                      >
                        <div>
                          <h3 className="font-[500] text-sm whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                            {item?.basicInformation?.title.length > 14
                              ? `${item.basicInformation.title.slice(0, 12)}...`
                              : item.basicInformation.title
                            }

                          </h3>
                        </div>

                        <div className="flex " style={{ color: "#F37521" }}>
                          <h1 className="font-bold text-sm ">
                            {item?.basicInformation?.price?.currency}&nbsp;
                          </h1>
                          <h1 className="font-bold text-sm ">
                            {Number(item?.basicInformation?.price?.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                          </h1>
                        </div>
                      </div>
                      <div className="flex gap-2 -mb-2">
                        <h1 className="text-[9px] font-[350] text-gray-400 ">
                          {item?.basicInformation?.description?.slice(0, 25)}
                        </h1>
                      </div>
                      <div
                        className="flex -mb-1 text-[10px] justify-between items-center text-gray-400"

                      >
                        <div className="flex gap-2 items-center">
                          <img src={year} alt="year" className="h-3 w-3" />
                          <p className=" mt-[17px]">
                            {item?.basicInformation?.details[0]?.value}
                          </p>
                        </div>

                        <div className="flex gap-2 items-center">
                          <img
                            src={engineSize}
                            alt="year"
                            className="h-3 w-3"
                          />
                          <p className=" mt-[17px]">
                            {item?.basicInformation?.details
                              .filter((item) =>
                                [
                                  "Engine Size",
                                  "Размер двигателя",
                                  "Dvigatel hajmi",
                                ].includes(item?.fieldName)
                              )
                              .map((filteredItem, index) => (
                                <span key={index}>{filteredItem?.value}</span>
                              ))}
                          </p>
                        </div>

                        <div className="flex gap-2 items-center">
                          <img
                            src={calendar}
                            color="gray"
                            alt="year"
                            className="h-3 w-3 opacity-60"
                          />
                          <p className=" mt-[17px]">
                            {item?.basicInformation?.KM}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            selectedCity !== "" && (
              <div className="container flex w-full justify-center font-bold">
              {selectedCity}: {strings.noAdsFound}
            </div>
            )
          )
        )
      }


      <div className="container ">
        <div className="shadow-md bg-white rounded-[10px] ">
          <div className="flex justify-content-center pt-[20px] underline ">

            {strings.whatDo}
          </div>

          <div className="row section">
            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11921} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.findPlace}
              </h6>
              <p
                style={{
                  fontSize: "10px",
                  opacity: "0.6",
                  textAlign: "center",
                }}
              >
                {strings.findPlaceText.slice(0, 80)}
              </p>
            </div>

            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11922} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.findVehicle}
              </h6>

              <p
                style={{
                  fontSize: "10px",
                  opacity: "0.6",
                  textAlign: "center",
                }}
              >
                {strings.findVehicleText.slice(0, 80)}
              </p>
            </div>

            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11923} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.buyRent}
              </h6>
              <p
                style={{
                  fontSize: "10px",
                  opacity: "0.6",
                  textAlign: "center",
                }}
              >
                {strings.buyRentText.slice(0, 80)}
              </p>
            </div>

            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11924} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.listAdd}
              </h6>
              <p
                style={{
                  fontSize: "10px",
                  opacity: "0.6",
                  textAlign: "center",
                }}
              >
                {strings.listAddText.slice(0, 80)}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*............ */}

      <div className="container">
        <div className="contain     bg-white shadow-md rounded-lg ">
          <div className="flex flex-col md:flex-row ">
            <div className="w-80 p-3 pt-5">
              <h6 className="w-64 fs-3 text-orange-400">{strings.getApp}</h6>
              <p style={{ fontSize: "14px" }}>{strings.getAppP}</p>
            </div>
            <div className="mt-3 ">
              <img className="w-52 h-52" src={mobile} alt="" />
            </div>

            <div className=" grid grid-cols-1 gap-2 px-3 py-4 md:grid-cols-1 lg:grid-cols-3 ms-auto">
              <div>
                <img
                  className="w-40 h-12 cursor-pointer"
                  src={appstore}
                  alt=""
                  onClick={openInAppStore}
                />
              </div>
              <div>
                <img
                  className="w-40 h-12 cursor-pointer "
                  src={googleplay}
                  alt=""
                  onClick={openOnGooglePlay}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* Partners ..............7....... */}
      {
        partners.length !== 0 && (
          <div className="container ">
            <h5 className="p-0 m-0 font-semibold text-[20px]">{strings.ourP}</h5>
            <div className="flex justify-center overflow-x-auto gap-4 mt-4 px-2">
              {
                partners?.map((item, index) => (
                  <div
                    className="flex-shrink-0 rounded flex items-center"
                    key={index}
                    style={{ width: "80px", height: "80px" }} // Controls size explicitly
                  >
                    <img
                      className="w-full h-full object-contain"
                      src={item.imageUrl}
                      alt={`Partner ${index}`}
                    />
                  </div>
                ))
              }
            </div>


          </div>
        )
      }

      <Footer strings={strings} />
    </div>
  );
};

export default Body;
