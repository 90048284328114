import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // For validation
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ClipLoader from 'react-spinners/ClipLoader';
import strings from '../utils/locals/languages';
import ResetPassword from '.././images/Resetpassword.svg';
import axios from 'axios';
import { baseURL1 } from '../utils/baseUrl';
import { toast } from 'react-toastify';

const UpdatePassword = ({ setSelectedForm, code }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [resetPasswordLoader, setResetPasswordLoader] = useState(false);


  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit: async (values) => {
      setResetPasswordLoader(true);
      try {
        const response = await axios.post(`${baseURL1}/v1/user/reset-password`, {
          code,
          password: values.password,
          confirmPassword: values.confirmPassword
        })

        if (response) {
          toast.success(response.data.msg)
          setSelectedForm("EmailLogin")
        } else {
          toast.error(response.data.msg)
        }
        setResetPasswordLoader(false);
      } catch (error) {
        setResetPasswordLoader(false);
      }
    },
  });

  return (
    <div className="container rounded-lg" style={{ background: '#F4F5F9' }}>
      <div className="p-4">
        <h5 className="text-center font-bold">{strings.resetpassword}</h5>
        <p className="text-center pt-2">{strings.pleaseEnterNewPassword}</p>
      </div>

      <div className="flex justify-center">
        <img className="w-32" src={ResetPassword} alt="" />
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col items-center mt-2">
          {/* Password input field */}
          <div className="flex w-80 rounded-full h-15 p-3 mb-4 shadow-md border-orange-400 border-2 outline-orange-600 pr-12">
            <input
              type={showPassword ? 'text' : 'password'}
              className="outline-none bg-transparent w-[85%]"
              name="password"
              value={formik.values.password}
              placeholder="Enter Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {showPassword ? (
              <LockIcon
                className="cursor-pointer"
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <LockOpenIcon
                className="cursor-pointer"
                onClick={() => setShowPassword(true)}
              />
            )}
          </div>
          {/* Display error message for password */}
          {formik.touched.password && formik.errors.password && (
            <div className="text-red-500 text-sm">{formik.errors.password}</div>
          )}

          {/* Confirm Password input field */}
          <div className="flex w-80 rounded-full h-15 p-3 mb-4 shadow-md border-orange-400 border-2 outline-orange-600 pr-12">
            <input
              type={showPassword ? 'text' : 'password'}
              className="outline-none bg-transparent w-[85%]"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              placeholder="Confirm Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {showPassword ? (
              <LockIcon
                className="cursor-pointer"
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <LockOpenIcon
                className="cursor-pointer"
                onClick={() => setShowPassword(true)}
              />
            )}
          </div>
          {/* Display error message for confirm password */}
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className="text-red-500 text-sm">{formik.errors.confirmPassword}</div>
          )}
        </div>

        <div className="flex justify-center mt-4">
          <button
            type="submit"
            className="bg-orange-500 w-80 rounded-full h-15 p-3 mb-4 text-white"
          >
            {resetPasswordLoader ? (
              <>
                <ClipLoader size={15} color="#fff" /> {strings.loading}
              </>
            ) : (
              strings.resetpassword
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdatePassword;
