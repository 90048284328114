import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import profile from "../../images/myProfile.svg";
import { setLanguage } from "../../app/languageSlice";
import { useDispatch } from "react-redux";
import strings from "../../utils/locals/languages";
import Cookies from "js-cookie";
import apiClient from "../../utils/apiClient";
import { toast } from "react-toastify";
import axios from "axios";
import { uploadDataUrl } from "../../utils/baseUrl";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

const MyProfile = () => {
  const hiddenFileInput = React.useRef(null);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [imageLoading, setImageLoading] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [profileData, setProfileData] = useState({});
  const [menu1Open, setMenu1Open] = useState(false);
  const button1Ref = React.useRef(null);
  const button2Ref = React.useRef(null);
  // event handlers for opening and closing each menu

  const handleFileClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleMenu1Open = (event) => {
    setMenu1Open(true);
  };
  const handleMenu1Close = () => {
    setMenu1Open(false);
  };
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageChange = (language) => {
    strings.setLanguage(language); // set the language for the LocalizedStrings instance

    Cookies.set("language", language);
    setTimeout(() => {
      window.location.reload();
    }, 500);
    dispatch(setLanguage(language)); // dispatch an action to update the language in the store
  };

  useEffect(() => {
    const selectedLanguage = Cookies.get("language");

    if (selectedLanguage !== undefined) {
      strings.setLanguage(selectedLanguage);
    }
  }, []);

  const uploadImage = (File) => {
    setImageLoading(true);
    const formData = new FormData();
    formData.append("files", File);

    axios
      .post(`${uploadDataUrl}/v1/adds/upload-multiple`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setImageLoading(false);

        setProfileData((prevProfileData) => ({
          ...prevProfileData,
          image: res.data[0],
        }));
        toast.success(`${strings.imageUpload}`);
      })
      .catch((err) => {
        toast.error(`${strings.imageUploadFailed}`);
      });
  };

  const updateProfile = () => {
    setIsLoading(true);
    apiClient
      .put(`/v1/user/profile`, profileData)
      .then((res) => {
        setIsLoading(false);
        const updatedUserDetails = JSON.stringify(res.data);
        Cookies.set("userDetails", updatedUserDetails, { expires: 7 });
        toast.success(`${strings.profileUpdateSuccess}`);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(`${strings.profileUpdateError}`);
      });
  };
  const logOutUser = () => {
    Cookies.remove("token");
    Cookies.remove("userDetails");
    window.location.reload();
  };
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");

    navigate("/");
  };

  useEffect(() => {
    const userDetails = Cookies.get("userDetails");
    const user = JSON.parse(userDetails);

    const {
      name,
      companyName,
      phone,
      email,
      country,
      city,
      logo,
      address,
      image,
      taxNumber,
      officePhone,
      gender,
      role,
      notificationOnOff
    } = user;
    const filteredUser = {
      name,
      companyName,
      phone,
      email,
      country,
      city,
      logo,
      address,
      image,
      taxNumber,
      officePhone,
      gender,
      role,
      notificationOnOff
    };

    setProfileData(filteredUser);
  }, []);

  //
  const [toggle, setToggle] = useState(false);

  const getNotifications = async () => {
    try {
      const response = await apiClient.get("/v1/user/notification")
      setNotificationCount(response.data.length);
      setNotificationData(response.data);
    } catch (err) {

    }

  }

  useEffect(() => {
    getNotifications()
  }, []);

  useEffect(() => {
    const userDetails = Cookies.get("userDetails");
    if (userDetails) {
      const user = JSON.parse(userDetails);
      const {
        name,
        companyName,
        phone,
        email,
        country,
        city,
        logo,
        address,
        image,
        taxNumber,
        officePhone,
        gender,
        notificationOnOff
      } = user;
      const filteredUser = {
        name,
        companyName,
        phone,
        email,
        country,
        city,
        logo,
        address,
        image,
        taxNumber,
        officePhone,
        gender,
        notificationOnOff
      };
      setProfileData(filteredUser);
    } else {
      setProfileData("Null");
    }
  }, []);

  return (
    <>
      <div className="container mx-auto">
        <h3 className="text-center font-bold text-lg uppercase ">
          {strings.my} {strings.profile}
        </h3>
        <div className="flex justify-center  py-4">
          <Stack direction="row" spacing={2}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <div className=" bg-[#F37521] p-1 rounded-full">
                  <CameraAltOutlinedIcon
                    onClick={handleFileClick}
                    className="cursor-pointer "
                    style={{
                      color: "white",
                      fontSize: "16px", // You can adjust the size as needed
                    }}
                  />
                </div>
              }
            >
              <Avatar
                alt="Travis Howard"
                src={profileData.image === "" ? profile : profileData.image}
                style={{
                  height: 80,
                  width: 80,
                }}
              />
            </Badge>
          </Stack>

          <input
            type="file"
            style={{ display: "none" }}
            ref={hiddenFileInput}
            onChange={(e) => {
              uploadImage(e.target.files[0]);
            }}
          />
        </div>

        <h6 className="font-semibold text-sm">
          {strings.personal} {strings.info} {profileData.username}
        </h6>

        <div className="grid grid-cols-1 lg:grid-cols-3 mt-4 md:grid-cols-3 md:gap-3 lg:gap-4 ">
          <input
            className="p-3 mt-4 rounded-full bg-white text-xs px-4 shadow-md"
            type="text"
            placeholder={strings.name}
            value={profileData.name}
            onChange={(event) => {
              const updatedName = event.target.value;
              setProfileData((prevProfileData) => ({
                ...prevProfileData,
                name: updatedName,
              }));
            }}
          />
          <input
            className="p-3 mt-4 rounded-full bg-white text-xs px-4 shadow-md"
            type="text"
            placeholder={strings.email}
            value={profileData.email}
            onChange={(event) => {
              const updatedEmail = event.target.value;
              setProfileData((prevProfileData) => ({
                ...prevProfileData,
                email: updatedEmail,
              }));
            }}
          />

          <input
            className="p-3 mt-4 rounded-full bg-white text-xs px-4 shadow-md"
            type="text"
            value={profileData.phone}
            placeholder={strings.phone}
            onChange={(event) => {
              const updatedPhone = event.target.value;
              setProfileData((prevProfileData) => ({
                ...prevProfileData,
                phone: updatedPhone,
              }));
            }}
          />
          <input
            className="p-3 mt-4 rounded-full bg-white text-xs px-4 shadow-md"
            type="text"
            placeholder={strings.taxNbr}
            value={profileData.taxNumber}
            onChange={(event) => {
              const updatedTaxNumber = event.target.value;
              setProfileData((prevProfileData) => ({
                ...prevProfileData,
                taxNumber: updatedTaxNumber,
              }));
            }}
          />
          <input
            className="p-3 mt-4 rounded-full bg-white text-xs px-4 shadow-md"
            type="text"
            placeholder={strings.country}
            value={profileData.country}
            onChange={(event) => {
              const updatedCountry = event.target.value;
              setProfileData((prevProfileData) => ({
                ...prevProfileData,
                country: updatedCountry,
              }));
            }}
          />
          <input
            className="p-3 mt-4 rounded-full bg-white text-xs px-4 shadow-md"
            type="text"
            placeholder={strings.city}
            value={profileData.city}
            onChange={(event) => {
              const updatedCity = event.target.value;
              setProfileData((prevProfileData) => ({
                ...prevProfileData,
                city: updatedCity,
              }));
            }}
          />
          <input
            className="p-3 mt-4 rounded-full bg-white text-xs px-4 shadow-md"
            type="text"
            placeholder={strings.address}
            value={profileData.address}
            onChange={(event) => {
              const updatedAddress = event.target.value;
              setProfileData((prevProfileData) => ({
                ...prevProfileData,
                address: updatedAddress,
              }));
            }}
          />
          <select
            className="p-3 mt-4 rounded-full bg-white text-xs px-4 shadow-md"
            value={profileData.gender}
            onChange={(event) => {
              const updatedGender = event.target.value;
              setProfileData((prevProfileData) => ({
                ...prevProfileData,
                gender: updatedGender,
              }));
            }}
          >
            <option value="">{strings.select}</option>
            <option>{strings.male}</option>
            <option>{strings.female}</option>
          </select>

          <div className="inline-flex items-center ml-2 ">
            <input type="checkbox" value="" className="accent-[#F37521] mt-4 -mr-2 cursor-pointer  "
              checked={profileData.notificationOnOff}
              onClick={() => {
                if (profileData.notificationOnOff) {
                  setProfileData((prevProfileData) => ({
                    ...prevProfileData,
                    notificationOnOff: false,
                  }));
                } else {
                  setProfileData((prevProfileData) => ({
                    ...prevProfileData,
                    notificationOnOff: true,
                  }));
                }

              }}
            />
            {/* <div className="md:mt-0 mt-4 relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div> */}
            <span className="ms-3 text-sm font-medium md:mt-0 mt-4 ">{strings.notif}</span>
          </div>

        </div>

        <div className="grid grid-cols-1 mb-4 lg:grid-cols-1 mt-4 md:grid-cols-1 md:gap-3 justify-items-center lg:gap-4 ">
          <button
            className="bg-orange-600 shadow-md w-80 p-3 rounded-full text-white mt-5"
            onClick={updateProfile}
          >
            {isLoading ? strings.loading : strings.update}
          </button>
        </div>
      </div>

      {/*footer*/}
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">
            {strings.notif}
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          {notificationData.map((data, index) => (
            <div
              key={index}
              className={`p-2 ${index % 2 === 0 ? "bg-gray-300" : ""}`}
            >
              <p style={{ fontWeight: "bold" }}>
                {index + 1}. {data.title}
              </p>
              <p>{data.message}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MyProfile;
