import React from 'react';
import strings from '../utils/locals/languages';

const SelectSignUpMethod = ({ setSelectedForm }) => {

  return (
    <>
      <div className="container rounded-lg" style={{ background: "#F4F5F9" }}>
        <h6 className="text-center mt-4">{strings.createAcc}</h6>
        <p className="text-center">{strings.pleaseSelectAccMethod}</p>

        <div className="flex justify-center">
          <button
            type="button"
            className="h-12 w-72 mt-5 bg-orange-600 text-white rounded-full"
            onClick={() => setSelectedForm("SignUpWithEmail")}
          >
            {strings.emailAddress}
          </button>
        </div>

        <div className="flex justify-center">
          <button
            type="button"
            className="h-12 w-72 mt-5 bg-orange-600 text-white rounded-full"
            onClick={() => setSelectedForm("PhoneSignUp")}
          >
            {strings.mobileNumber}
          </button>
        </div>

        <div className="flex justify-center mt-2">
          <span>
            {strings.alreadyHave}{" "}
            <button
              className="ms-3 text-orange-400 mb-4"
              onClick={() => setSelectedForm("EmailLogin")}
            >
              {strings.signIn}
            </button>
          </span>
        </div>

      </div>
    </>
  );
};

export default SelectSignUpMethod;
