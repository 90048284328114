import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ClipLoader from "react-spinners/ClipLoader";
import Verify from ".././images/Verfiy.png";
import strings from '../utils/locals/languages';
import axios from 'axios';
import { baseURL1 } from '../utils/baseUrl';
import { toast } from 'react-toastify';

const EmailSignUpVerfCode = ({ setSelectedForm }) => {
    const [userVerificationLoader, setUserVerificationLoader] = useState(false);

    const validationSchema = Yup.object().shape({
        emailVerificationCode: Yup.string()
            .required(strings.requiredField)
            .length(4, strings.invalidCode),
    });

    const handleUserVerification = async (values, { setSubmitting }) => {
        setUserVerificationLoader(true);
        try {
            const response = await axios.post(`${baseURL1}/v1/user/verify`, {
                code: values.emailVerificationCode
            })

            if (response) {
                toast.success(response.data.msg)
                setSelectedForm("EmailLogin")
                setUserVerificationLoader(false)
            } else {
                setUserVerificationLoader(false)
                toast.error(response.data.msg)
            }
        } catch {
            setUserVerificationLoader(false)
            toast.error(strings.invalidCode)
        }
        setTimeout(() => {
            setUserVerificationLoader(false);
            setSubmitting(false);
        }, 2000);
    };

    return (
        <div className="container rounded-lg" style={{ background: "#F4F5F9" }}>
            <div className="p-4">
                <h5 className="text-center font-bold">{strings.emailVerify}</h5>
                <p className="text-center pt-2">{strings.checkEmail}</p>
            </div>

            <div className="flex justify-center">
                <img className="w-40" src={Verify} alt="Verification" />
            </div>

            <Formik
                initialValues={{ emailVerificationCode: "" }}
                validationSchema={validationSchema}
                onSubmit={handleUserVerification}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="flex justify-center mt-3">
                            <Field
                                className="bg-white shadow-md rounded-full w-80 p-3 outline-orange-600"
                                type="text"
                                name="emailVerificationCode"
                                placeholder={strings.enterTheCode}
                            />
                        </div>
                        <ErrorMessage
                            name="emailVerificationCode"
                            component="div"
                            className="text-red-500 text-center mt-1"
                        />

                        <div className="flex justify-center mt-4">
                            <button
                                className="bg-orange-500 w-80 rounded-full h-15 p-3 mb-4 text-white"
                                type="submit"
                                disabled={isSubmitting || userVerificationLoader}
                            >
                                {userVerificationLoader ? (
                                    <>
                                        <ClipLoader size={15} color="#fff" /> {strings.loading}
                                    </>
                                ) : (
                                    strings.sendRequest
                                )}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EmailSignUpVerfCode;
