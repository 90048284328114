import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import Verfiy from ".././images/Verfiy.png";
import strings from "../utils/locals/languages";
import axios from "axios"; // Assuming axios is used for API calls
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { baseURL1 } from "../utils/baseUrl";

const PhoneLoginOtp = ({ phoneNbr }) => {
  const [userVerificationLoader, setUserVerificationLoader] = useState(false);

  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .length(4, strings.otpLengthError)
      .matches(/^\d+$/, strings.otpNumberError)
      .required(strings.requiredField),
  });

  const handleSubmit = async (otp, { setSubmitting }) => {
    setUserVerificationLoader(true);
    try {
      const response = await axios.post(`${baseURL1}/v1/user/verify-phone-login`, {
        phone: phoneNbr,
        code: Number(otp.otp),
      });

      if (response) {
        toast.success(response.data.message)
        Cookies.set("token", response.data.token, { expires: 7 });
        Cookies.set("userDetails", JSON.stringify(response.data.user), { expires: 7 });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error(strings.invalidCode)
    } finally {
      setSubmitting(false);
      setUserVerificationLoader(false);
    }
  };

  return (
    <div className="container rounded-lg" style={{ background: "#F4F5F9" }}>
      <h4 className="mt-5 text-center">{strings.enterTheCode}</h4>
      <p className="text-center">{strings.codeThatReceived}</p>

      <div className="flex justify-center">
        <img src={Verfiy} alt="Verify" style={{ width: "180px", height: "130px" }} />
      </div>

      <Formik
        initialValues={{ otp: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="flex justify-center mt-5">
              <Field
                name="otp"
                type="text"
                placeholder={strings.enterTheCode}
                className="bg-white shadow-md rounded-full w-80 p-3"
              />
            </div>
            <div className="text-red-500 text-sm text-center mt-1">
              <ErrorMessage name="otp" />
            </div>

            <div className="flex justify-center mt-4">
              <button
                className="h-16 w-80 mt-2 bg-orange-600 text-white rounded-full mb-5"
                type="submit"
                disabled={isSubmitting || userVerificationLoader}
              >
                {userVerificationLoader ? (
                  <>
                    <ClipLoader size={12} color="#fff" /> {strings.loading}
                  </>
                ) : (
                  strings.verify
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PhoneLoginOtp;
