import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup'; // Optional validation schema
import { ClipLoader } from 'react-spinners';
import phoneIcon from ".././images/phone.svg";
import strings from '../utils/locals/languages';
import {
    getCountries,
    getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";
import { toast } from 'react-toastify';
import axios from 'axios';
import { baseURL1 } from '../utils/baseUrl';

const CountrySelect = ({ value, onChange, labels, ...rest }) => (
    <select
        {...rest}
        value={value}
        onChange={(event) => onChange(event.target.value || undefined)}
    >
        <option value="">{labels["ZZ"]}</option>
        {getCountries().map((country) => (
            <option key={country} value={getCountryCallingCode(country)}>
                {labels[country]} +{getCountryCallingCode(country)}
            </option>
        ))}
    </select>
);

const PhoneSignUp = ({ setSelectedForm, setPhoneNbr }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [role, setRole] = useState('individual')
    const [country, setCountry] = useState('')
    const [phone, setPhone] = useState("");
    const [name, setName] = useState('')
    const [termsAccept, setTermsAccept] = useState(false)


    const handlePhoneCode = (e) => {
        setCountry(e);
        setPhone(e);
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        setPhone(value);
    };

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true)
            const response = await axios.post(`${baseURL1}/v1/user/phone-signup`, {
                name,
                role,
                termsAccept,
                phone: `+${phone}`
            })

            if (response) {
                toast.success(response.data.message)
                setSelectedForm("PhoneSignUpVerfCode")
                setIsLoading(false)
                setPhoneNbr(`+${phone}`)
            } else {
                setIsLoading(false)
                toast.error(response.data.msg)
            }
        } catch (err) {
            setIsLoading(false)
            toast.error(err.response.data.error)
        }
    };

    return (
        <div className="container mx-auto rounded-lg" style={{ background: "#F4F5F9" }}>
            <h4 className="fw-bold text-center mt-5">{strings.createAcc}</h4>
            <p>{strings.pleaseCreateTo}</p>

            <div className="max-w-3xl mx-auto px-8 sm:px-0">
                <div className="flex justify-center">
                    <div className="flex justify-between rounded-full bg-white w-max ">
                        <button
                            className={`p-3 rounded-full focus:outline-none ${role === 'individual' && 'bg-orange-600 text-white'} `}
                            type="button"
                            onClick={() => setRole('individual')}

                        >
                            {strings.ind}
                        </button>
                        <button
                            className={`p-3 rounded-full shadow-full focus:outline-none  ${role === 'corporate' && 'bg-orange-600 text-white'} `}
                            type="button"
                            onClick={() => setRole('corporate')}
                        >
                            {strings.corporate}
                        </button>
                    </div>
                </div>


                {/* Name Input */}
                <div className="flex justify-center mt-6">
                    <div className="relative">
                        <input
                            type='text'
                            className="bg-white shadow-md rounded-full w-80 p-3 outline-orange-600"
                            placeholder={strings.name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                </div>

                {/* Phone Input */}
                <div className="flex justify-center mt-4">
                    <div className="relative flex w-80 justify-center bg-white rounded-full mt-3 -ml-5 p-2 shadow-md">
                        <CountrySelect
                            className="w-16 p-1 outline-none "
                            labels={en}
                            value={country}
                            onChange={(e) => handlePhoneCode(e)}
                        />


                        <input
                            className="outline-none"
                            onChange={(e) => handlePhoneChange(e)}
                            value={phone}
                        />
                        <img className="mt-2 w-6" src={phoneIcon} alt="Phone Icon" />
                    </div>
                </div>

                {/* Terms Checkbox */}
                <div className="checkbox mt-3 flex w-full justify-center">
                    <div>
                        <input type="checkbox" name="termsAccept"
                            onClick={() => {
                                if (!termsAccept) {
                                    setTermsAccept(true)
                                } else {
                                    setTermsAccept(false)
                                }
                            }
                            }
                        />
                    </div>
                    <div className="text-sm ml-1 text-center">
                        {strings.readAndAgree}
                        <span className="text-orange-600"> {strings.termsOfUse}</span>
                        &nbsp; {strings.and}
                        <br /><span className="text-orange-600"> {strings.privacyPolicy}</span>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="flex justify-center mt-4">
                    <button
                        type="submit"
                        className="mt-3 bg-orange-600 text-white shadow-md rounded-full w-80 p-3"
                        disabled={isLoading}
                        onClick={handleSubmit}
                    >
                        {isLoading ? (
                            <>
                                <ClipLoader size={15} color="#fff" /> {strings.loading}
                            </>
                        ) : (
                            strings.signUp
                        )}
                    </button>
                </div>
            </div>

            <div className="flex justify-center mt-2">
                <span>
                    Already Have an Account?
                    <button className="ms-3 text-orange-400 mb-4"
                        onClick={() => setSelectedForm("EmailLogin")}
                    >
                        {strings.signIn}
                    </button>
                </span>
            </div>
        </div>
    );
};

export default PhoneSignUp;
