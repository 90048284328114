import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Group11921 from "../../images/Group 11921.svg";
import Group11922 from "../../images/Group 11922.svg";
import Group11923 from "../../images/Group 11923.svg";
import Group11924 from "../../images/Group 11924.svg";
import search from "../../images/whitesearch.svg";
import searchIcon from "../../images/searchIcon.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import area from "../../images/ar.svg";
import beds from "../../images/bedss.svg";
import wash from "../../images/wshs.svg";

import "./index.css";
import { baseURL1 } from "../../utils/baseUrl";
import apiClient from "../../utils/apiClient";
import strings from "../../utils/locals/languages";
import { useSelector } from "react-redux";
import Footer from "../../components/Footer";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { capitalizeFirstLetter } from "../../utils/capitalizeLetter";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { handleRealEstateClick } from "../../services/handlers";


const Body = ({ id }) => {
  const user = Cookies.get("userDetails");
  const [isLoading, setIsLoading] = useState(true);
  const [reLoad, setReLoad] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [city, setCity] = useState("");
  const [purpose, setPurpose] = useState("");
  const [subCat, setSubCat] = useState("");
  const language = useSelector((state) => state.language);
  const [userId, setUserId] = useState("");
  const [cities, setCities] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [partners, setPartners] = useState([])
  const [ads, setAds] = useState([])
  const [totalAds, setTotalAds] = useState(0)



  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/citiesDropdown`)
      .then((response) => {
        setCities(response.data.cities);
      })
      .catch((err) => { })
  }, [strings._language]);

  const addToFavorites = (event, adId) => {
    event.stopPropagation();

    apiClient
      .get(`/v1/user/fav-unfav/${adId}`)
      .then((res) => {
        apiClient
          .get(
            `/v1/adds/search/?section=638f25154cb7c29ec6ad4bfe&language=${strings._language}&city=${city}&purpose=${purpose}&category=${selectedId}&subcat=${subCat}
        `
          )
          .then((response) => {
            setData(response.data.data);
            setIsLoading(false);
          })
          .catch((err) => { });
      })
      .catch((err) => {
        toast.error(`${strings.failedToAdd}`);
      });
  };

  const searchFilter = () => {
    setIsLoading(true);
    apiClient
      .get(
        `/v1/adds/search/?section=638f25154cb7c29ec6ad4bfe&language=${strings._language}&city=${city}&purpose=${purpose}&category=${selectedId}&subcat=${subCat}
        `
      )
      .then((response) => {
        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => { });
  };

  const getAds = async () => {
    try {
      const response = await apiClient('/v1/user/get-banners/638f25154cb7c29ec6ad4bfe')
      setAds(response.data.banners)
    } catch {
      setAds([])
    }
  }

  useEffect(() => {
    searchFilter();
  }, [strings._language, reLoad, purpose, selectedId, subCat]);

  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/sections?language=${strings._language}`)
      .then((response) => {
        setBrands(response.data[1].categories);

        let total = 0;
        response.data[1].categories.forEach(element => {
          total += element.totalAds;
        });
        setTotalAds(total)
        setBrandsLoading(false);
      })
      .catch((err) => { });
  }, [strings._language]);



  const handleBrandChange = (event) => {
    setSelectedId(event.target.value);
  };

  useEffect(() => {
    let filteredRealEstateData = data.filter((item) => {
      return search !== ""
        ? item.basicInformation.title.toLowerCase().includes(city.toLowerCase())
        : item;
    });

    setData(filteredRealEstateData);
  }, [city]);

  useEffect(() => {
    const userDetails = Cookies.get("userDetails");

    if (userDetails) {
      const user = JSON?.parse(userDetails);
      setUserId(user._id);
    } else {
      setUserId("0");
    }
  }, []);

  const fetchPartners = async () => {
    try {
      const response = await apiClient.get('/v1/user/view-partners?location=realEstate');
      setPartners(response.data.partners);
    } catch (err) {
      console.error('Error fetching partners:', err);
    }
  };
  const itemsToShow = 5

  const itemWidth = 300
  const [realEstateScrollPosition, setRealEstateScrollPosition] = useState(0);

  const isRealEstateEndReached = (data.length * 250)
  const isRealEstateStartReached = realEstateScrollPosition === 0;

  const realEstateNextButtonClickHandler = () => {
    const nextPosition = Math.min(
      realEstateScrollPosition + itemWidth,
      ((data.length + 2) - itemsToShow) * itemWidth
    );

    if (isRealEstateEndReached >= realEstateScrollPosition) {
      setRealEstateScrollPosition(nextPosition);
    }
  };

  const realEstatePrevButtonClickHandler = () => {
    const prevPosition = Math.max(realEstateScrollPosition - itemWidth, 0);
    setRealEstateScrollPosition(prevPosition);
  };


  useEffect(() => {
    fetchPartners()
    getAds()
  }, [])


  return (
    <div className="space-y-8"

    >
      <div className="realestate-background-image">
        <div className="container">
          <div className="row  flex justify-center mb-3">
            <div className="col-12 col-md-12 col-lg-6">

              <h3
                className="text-center  text-white"
                style={{ fontSize: "40px" }}
              >
                {strings.rightPlace}
              </h3>

            </div>
          </div>

          <div className="row  flex justify-center mb-3">
            <div className="col-12 col-md-12 col-lg-6  items-center flex justify-center p-2 rounded-2xl">
              <div className="bg-white shadow-md items-center flex justify-center p-2 w-[85%] rounded-full">
                <input type="text" className="outline-none bg-white w-[90%]"
                  placeholder={capitalizeFirstLetter(strings.searchSomething)}
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
                <img src={searchIcon} className="h-[24px] w-[24px]" />
              </div>
            </div>
          </div>

        </div>
      </div>

      {/*CATEGORIES*/}
      <div className="container mx-auto">
        {brandsLoading ? (
          <h4 className="mb-3 uppercase font-semibold text-[20px]">
            <Skeleton
              width={300}
              height={30}
              style={{
                marginLeft: "-410px",
              }}
            />{" "}
          </h4>
        ) : (
          <h4 className="mb-3 uppercase font-semibold text-[20px]">
            {strings.categories}
          </h4>
        )}
        {brandsLoading ? (
          <div
            className="flex gap-[52px] flex-nowrap overflow-x-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-100"
            style={{ width: "100%" }}
          >
            {[1, 2, 3, 4].map((_, index) => (
              <div
                className="rounded-2xl shadow-md bg-white w-[200px]  flex-none mb-3 cursor-pointer"
                key={index}
              >
                <Skeleton
                  style={{
                    height: "200px",
                    borderTopLeftRadius: "16px",
                    borderTopRightRadius: "16px",
                  }}
                  className="max-h--sm h-60  shadow-sm"
                />
                <div className="text-center flex justify-center text-sm font-medium py-2">
                  <Skeleton height={10} width={80} />
                </div>
              </div>
            ))}
          </div>
        ) : (

          <div
            className="flex gap-[32px] flex-nowrap overflow-x-auto justify-center scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-100"
            style={{ width: "100%" }}
          >
            <div
              className="rounded-2xl shadow-md bg-white h-[200px]  w-[200px] flex-none mb-3 cursor-pointer"
              onClick={() => {
                navigate(`/search-realestate/all`);
              }}
            >
              <img
                src='https://egasi-storage.s3.ap-south-1.amazonaws.com/1730355973038_ltv68bnu_realestate-removebg-preview.png'
                alt="All Categories"
                className="h-[150px] w-[100%] rounded-2xl shadow-sm object-cover"
              />
              <h6 className="text-center text-sm font-extrabold py-1">
                {capitalizeFirstLetter(strings.all)}  <span className="text-orange-400"> ({totalAds})</span>
              </h6>
            </div>
            {brands.sort((a, b) => a.title.localeCompare(b.title)).map((item, index) => (
              <div
                className="rounded-2xl shadow-md bg-white h-[200px]  w-[200px] flex-none mb-3 cursor-pointer"
                key={index}
                onClick={() => {
                  navigate(`/search-realestate/${item._id}`);
                }}
              >
                <img
                  src={item.imageUrl}
                  alt="Category"
                  className="h-[150px] w-[100%] rounded-2xl shadow-sm"
                />
                <h6 className="text-center text-sm font-extrabold py-1">
                  {capitalizeFirstLetter(item.title)}  <span className="text-orange-400"> ({item.totalAds})</span>
                </h6>
              </div>
            ))}
          </div>
        )}
      </div>

      {isLoading ? (
        // <div className="container flex justify-center mt-3">
        //   <div>
        //     <ClipLoader
        //       loading={isLoading}
        //       size={50}
        //       aria-label="Loading Spinner"
        //       data-testid="loader"
        //     />
        //   </div>
        // </div>
        <div className="container mt-12">
          <Skeleton
            height={30}
            width={350}
            style={{
              marginBottom: "24px",
              // marginRight: "500px"
              marginLeft: "-385px",
            }}
          />

          <div className="">
            <div className="grid grid-cols-1  md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-center">
              {[1, 2, 3, 4].map((_, index) => (
                <div
                  className="c shadow-xl rounded-xl  cursor-pointer "
                  key={index}
                >
                  <div className="relative h-48" style={{}}>
                    <Skeleton
                      height={192}
                      style={{
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    />
                  </div>
                  <div className="px-[10px] mt-2" style={{}}>
                    <div
                      className="flex items-center justify-between"
                      style={{}}
                    >
                      <Skeleton height={15} width={70} />
                      <Skeleton height={15} width={50} />
                    </div>
                    <div className="flex gap-2 " style={{}}>
                      <Skeleton height={10} width={100} />
                    </div>
                    <div
                      className="flex mb-1  text-[10px] justify-between items-center text-gray-400"
                      style={{}}
                    >
                      <div className="flex gap-2 items-center">
                        <Skeleton height={10} width={20} />
                        <Skeleton height={10} width={20} />
                      </div>

                      <div className="flex gap-2 items-center">
                        <Skeleton height={10} width={20} />
                        <Skeleton height={10} width={20} />
                      </div>

                      <div className="flex gap-2 items-center">
                        <Skeleton height={10} width={20} />
                        <Skeleton height={10} width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="container mt-6">
          <div className="flex justify-between ">
            <h4 className="uppercase font-semibold text-[20px] mb-4">
              {strings.recommendedRealEstates}
            </h4>
            <div className="flex">
              <ArrowBackIosNewIcon
                className={`cursor-pointer  rounded-full text-xl p-1 bg-white `}
                onClick={() => realEstatePrevButtonClickHandler()}
              />
              <ArrowForwardIosIcon
                className={`cursor-pointer  rounded-full text-xl p-1 ml-1 bg-white `}
                onClick={() => realEstateNextButtonClickHandler()}
              />
            </div>
          </div>
          {data.length === 0 ? (
            <div className="flex p-0">
              <h3>{strings.norec}</h3>
            </div>
          ) : (
            <div className="">
              <div
                className='flex gap-4 pb-2 bg-transparent overflow-y-auto md:overflow-hidden hours-container'
              >
                {data.map((item, index) => (
                  <div
                    className="w-[250px] h-[260px] min-w-[250px]  flex flex-col shadow-md rounded-xl  cursor-pointer "
                    style={{
                      display: 'flex',
                      transition: 'transform 0.3s',
                      transform: `translateX(-${realEstateScrollPosition}px)`,
                    }}
                    onClick={() => {
                      handleRealEstateClick(navigate, item._id, item.basicInformation.title, item.basicInformation.category.title, item.basicInformation.brand.title, item.location.city, item.location.district)

                    }}
                  >
                    <div
                      className="relative  h-[170px] w-[100%]"
                    // style={{ height: "200px", overflow: "hidden" }}
                    >
                      {!item?.fav?.includes(userId) ? (
                        <FavoriteBorderOutlinedIcon
                          className="absolute items-content-end w-12"
                          style={{
                            right: "8px",
                            color: "#F37521",
                            backgroundColor: "white",
                            borderRadius: 100,
                            padding: 3,
                            top: 8,
                          }}
                          onClick={(event) => {
                            if (user) {
                              addToFavorites(event, item._id);
                            }
                          }}
                        />
                      ) : (
                        <FavoriteOutlinedIcon
                          className="absolute items-content-end w-12"
                          style={{
                            right: "8px",
                            color: "red",
                            backgroundColor: "white",
                            borderRadius: 100,
                            padding: 3,
                            top: 8,
                          }}
                          onClick={(event) => {
                            if (user) {
                              addToFavorites(event, item._id);
                            }
                          }}
                        />
                      )}
                      <img
                        className="rounded-t-2xl"
                        src={item?.coverPhoto}
                        alt="random"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          // borderTopRightRadius: 12,
                          // borderTopLeftRadius: 12,
                        }}
                      />
                    </div>
                    <div
                      className="px-[10px] mt-2"
                      style={{ overflow: "hidden" }}
                    >
                      <div
                        className="flex items-center justify-between"
                      >
                        <div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[70%]">
                          <h3 className="font-medium text-sm">
                            {item?.basicInformation?.title.length > 14
                              ? `${item.basicInformation.title.slice(0, 12)}...`
                              : item.basicInformation.title}
                          </h3>
                        </div>

                        <div className="flex items-center text-[#F37521] ml-2">
                          <h1 className="font-bold text-sm whitespace-nowrap">
                            {item?.basicInformation?.price?.currency}&nbsp;
                          </h1>
                          <h1 className="font-bold text-sm whitespace-nowrap">
                            {Number(item?.basicInformation?.price?.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                          </h1>
                        </div>
                      </div>

                      <div className="flex gap-2 -mb-2">
                        <h1 className="text-[9px] font-[350] text-gray-500 ">
                          {item.basicInformation.description.slice(0, 25)}
                        </h1>
                      </div>
                      <div
                        className="flex -mb-1 text-[10px] justify-between items-center text-gray-400"

                      >
                        {item?.basicInformation?.details.some((item) =>
                          ["Bedrooms", "Number Of Rooms", "Количество комнат", "Xonalari"].includes(item?.fieldName)
                        ) && (
                            <div className="flex gap-2 items-center">
                              <img src={beds} alt="year" className="h-3 w-3" />
                              <p className="mt-[17px]">
                                {item?.basicInformation?.details
                                  .filter((item) =>
                                    ["Bedrooms", "Number Of Rooms", "Количество комнат", "Xonalari"].includes(item?.fieldName)
                                  )
                                  .map((filteredItem, index) => (
                                    <span key={index}>
                                      {filteredItem?.value === "" ? 1 : filteredItem?.value}
                                    </span>
                                  ))}
                              </p>
                            </div>
                          )}

                        <div className="flex gap-2 items-center">
                          <img src={area} alt="year" className="h-3 w-3" />
                          <p className=" mt-[17px]">
                            {item?.basicInformation?.area?.net}m <sup>2</sup>
                          </p>
                        </div>

                        {item?.basicInformation?.details.some((item) =>
                          ["Bathrooms", "Hammomlar", "Ванные комнаты"].includes(item?.fieldName)
                        ) && (
                            <div className="flex gap-2 items-center">
                              <img
                                src={wash}
                                color="gray"
                                alt="year"
                                className="h-3 w-3 opacity-60"
                              />
                              <p className=" mt-[17px]">
                                {item?.basicInformation?.details
                                  .filter((item) =>
                                    ["Bathrooms", "Hammomlar", "Ванные комнаты"].includes(item.fieldName)
                                  )
                                  .map((filteredItem, index) => (
                                    <span key={index}>{filteredItem.value}</span>
                                  ))}
                              </p>
                            </div>
                          )}


                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="container">
        <div className="row">
          <Carousel
            showIndicators={false}
            showThumbs={false}
            interval={3000}
            autoPlay={true}
            infiniteLoop
          >

            {
              ads.filter((ad) => ad.imageUrl).map((item, index) => (
                <div className="md:h-[200px] h-[100px]">
                  <img src={item?.imageUrl} className="rounded-md h-[100%]" />

                </div>
              ))
            }
          </Carousel>
        </div>
      </div>

      {/*......6......*/}
      <div className="container">
        <div
          className="contain "
          style={{
            background: "white",
            marginTop: "40px",
            borderRadius: "10px",
          }}
        >
          <div className="flex justify-content-center pt-[20px] underline ">

            {strings.whatDo}
          </div>


          <div className="row section">
            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11921} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.findPlace}
              </h6>
            </div>

            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11922} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.findVehicle}
              </h6>
            </div>

            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11923} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.buyRent}
              </h6>
            </div>

            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11924} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.listAdd}
              </h6>
            </div>
          </div>
        </div>
      </div>

      {/* Partners ..............7....... */}
      {
        partners.length !== 0 && (
          <div className="container ">
            <h5 className="p-0 m-0 font-semibold text-[20px]">{strings.ourP}</h5>
            <div className="flex justify-center overflow-x-auto gap-4 mt-4 px-2">
              {
                partners?.map((item, index) => (
                  <div
                    className="flex-shrink-0 rounded flex items-center"
                    key={index}
                    style={{ width: "80px", height: "80px" }} 
                  >
                    <img
                      className="w-full h-full object-contain"
                      src={item.imageUrl}
                      alt={`Partner ${index}`}
                    />
                  </div>
                ))
              }
            </div>

          </div>
        )
      }

      {/* Footer */}
      <Footer strings={strings} />
    </div>
  );
};

export default Body;
