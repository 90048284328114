import React, { useEffect, useState } from "react";
import Body from "./Body";
// import Header from "./Header";
import Header from '../../components/Header'
import strings from "../../utils/locals/languages";
import { Helmet } from "react-helmet-async";

const Home = () => {

  const [search, setSearch] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <Helmet>
        <html lang={strings._language} />
        <title>{strings.homePageSeoTitle}</title>
        <meta name="description" content={strings.homePageSeoDesc} />
        <meta name="keywords" content={strings.homePageSeoKeyWords} />
        <meta name="author" content="Egasi Team" />
        <link rel="canonical" href="https://egasi.net/" />
      </Helmet>
      <Header search={search} setSearch={setSearch} />
      <Body search={search} />
    </div>
  );
};

export default Home;
