import tashkent from '../images/regions/Tashkent.png'
import andijan from '../images/regions/Andijan.png'
import bukhara from '../images/regions/Bukhara.png'
import Jizzakh from '../images/regions/Jizzakh.png'
import Kashkadarya from '../images/regions/Kashkadarya.png'
import Khorezm from '../images/regions/Khorezm.png'
import Namangan from '../images/regions/Namangan.png'
import Navoiy from '../images/regions/Navoiy.png'
import Samarkand from '../images/regions/Samarkand.png'
import Sirdarya from '../images/regions/Sirdarya.png'
import Surkhandarya from '../images/regions/Surkhandarya.png'
import Fergana from '../images/regions/Fergana.png'
import Karakalpakstan from '../images/regions/Karakalpakstan.png'

export const regions = {
    en: [
        { title: "Tashkent", value: "Tashkent", image: tashkent },
        { title: "Andijan", value: "Andijan", image: andijan },
        { title: "Bukhara", value: "Bukhara", image: bukhara },
        { title: "Jizzakh", value: "Jizzakh", image: Jizzakh },
        { title: "Kashkadarya", value: "Kashkadarya", image: Kashkadarya },
        { title: "Khorezm", value: "Khorezm", image: Khorezm },
        { title: "Namangan", value: "Namangan", image: Namangan },
        { title: "Navoiy", value: "Navoiy", image: Navoiy },
        { title: "Samarkand", value: "Samarkand", image: Samarkand },
        { title: "Sirdarya", value: "Sirdarya", image: Sirdarya },
        { title: "Surkhandarya", value: "Surkhandarya", image: Surkhandarya },
        { title: "Fergana", value: "Fergana", image: Fergana },
        { title: "Karakalpakstan", value: "Karakalpakstan", image: Karakalpakstan }
    ],
    uz: [
        { title: "Toshkent", value: "Tashkent", image: tashkent },
        { title: "Andijon", value: "Andijan", image: andijan },
        { title: "Buxoro", value: "Bukhara", image: bukhara },
        { title: "Jizzax", value: "Jizzakh", image: Jizzakh },
        { title: "Qashqadaryo", value: "Kashkadarya", image: Kashkadarya },
        { title: "Xorazm", value: "Khorezm", image: Khorezm },
        { title: "Namangan", value: "Namangan", image: Namangan },
        { title: "Navoiy", value: "Navoiy", image: Navoiy },
        { title: "Samarqand", value: "Samarkand", image: Samarkand },
        { title: "Sirdaryo", value: "Sirdarya", image: Sirdarya },
        { title: "Surxandaryo", value: "Surkhandarya", image: Surkhandarya },
        { title: "Farg'ona", value: "Fergana", image: Fergana },
        { title: "Qaraqalpog'iston", value: "Karakalpakstan", image: Karakalpakstan }
    ],
    ru: [
        { title: "Ташкент", value: "Tashkent", image: tashkent },
        { title: "Андижан", value: "Andijan", image: andijan },
        { title: "Бухара", value: "Bukhara", image: bukhara },
        { title: "Джиззак", value: "Jizzakh", image: Jizzakh },
        { title: "Кашкадарья", value: "Kashkadarya", image: Kashkadarya },
        { title: "Хорезм", value: "Khorezm", image: Khorezm },
        { title: "Наманган", value: "Namangan", image: Namangan },
        { title: "Навоий", value: "Navoiy", image: Navoiy },
        { title: "Самарканд", value: "Samarkand", image: Samarkand },
        { title: "Сырдарья", value: "Sirdarya", image: Sirdarya },
        { title: "Сурхандарья", value: "Surkhandarya", image: Surkhandarya },
        { title: "Фергана", value: "Fergana", image: Fergana },
        { title: "Каракалпакстан", value: "Karakalpakstan", image: Karakalpakstan }
    ]
};
