import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'
import "bootstrap/dist/css/bootstrap.min.css";
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import AdInformation from "../../images/AdInformation.svg";
import Review from "../../images/Review.svg";
import Package from "../../images/Package.svg";
import apiClient from "../../utils/apiClient";
import { baseURL1, uploadDataUrl, vehicleId } from "../../utils/baseUrl";
import { setLanguage } from "../../app/languageSlice";
import { useDispatch } from "react-redux";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import strings from "../../utils/locals/languages";
import Footer from "../../components/Footer";
import { FaTimes } from 'react-icons/fa';
import OpenStreetMap from "../../components/OpenStreetMap";
import { capitalizeFirstLetter } from "../../utils/capitalizeLetter";
import CurrencyInput from 'react-currency-input-field';
import './index.css'
import { regions } from "../../utils/regions";


const CreatedAdVehicle2 = ({ id }) => {
  const [isItalic, setIsItalic] = useState(false);
  const [isBold, setIsBold] = useState(false);

  const handleItalicClick = () => {
    setIsItalic(!isItalic);
  };

  const handleBoldClick = () => {
    setIsBold(!isBold);
  };
  const [notificationCount, setNotificationCount] = useState(0)
  const [notificationData, setNotificationData] = useState([])
  const [category, setCategory] = useState("")
  const [model, setModel] = useState("")
  const [brand, setBrand] = useState("")
  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState('')
  const [isLocationAvailable, setIsLocationAvailable] = useState(true)

  const imageUrl = []
  const [videoLoading, setVideoLoading] = useState(false)
  const button1Ref = React.useRef(null);
  const button2Ref = React.useRef(null);
  const [menu1Open, setMenu1Open] = useState(false);
  const [videoFile, setVideoFile] = useState("")
  const [coverPhotoLoading, setCoverPhotoLoading] = useState(false)
  const hiddenCoverFileInput = useRef(null);



  const handleMenu1Open = (event) => {
    setMenu1Open(true);
  };
  const handleMenu1Close = () => {
    setMenu1Open(false);
  };
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCoverPhotoClick = (event) => {
    hiddenCoverFileInput.current.click();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageChange = (language) => {
    strings.setLanguage(language); // set the language for the LocalizedStrings instance

    Cookies.set("language", language);
    setTimeout(() => {
      window.location.reload();
    }, 500);
    dispatch(setLanguage(language)); // dispatch an action to update the language in the store
  };


  useEffect(() => {
    const selectedLanguage = Cookies.get("language")

    if (selectedLanguage !== undefined) {
      strings.setLanguage(selectedLanguage)
    }

  }, [])

  const logOutUser = () => {
    Cookies.remove("token");
    Cookies.remove("userDetails");
    window.location.reload();
  };


  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [models1, setModels1] = useState([]);
  const [data, setData] = useState([]);
  const [myData, setMyData] = useState([]);
  const [videoCall, setVideoCall] = useState(true);
  const [NotVideoCall, setNotVideoCall] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showExternalModal, setExternalShowModal] = useState(false);
  const [showSafetyModal, setSafetyShowModal] = useState(false);
  const [getData, setGetData] = useState([]);
  const [getInternalFeatures, setGetInternalFeatures] = useState([]);
  const [getExternalFeatures, setGetExternalFeatures] = useState([]);
  const [getsafetyFeatures, setGetSafetyFeatures] = useState([]);
  const [internalFeatures, setInternalFeatures] = useState([]);
  const [externalFeatures, setExternalFeatures] = useState([]);
  const [safetyFeatures, setSafetyFeatures] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [newInternalFeature, setNewInternalFeature] = useState("");
  const [newExternalFeature, setNewExternalFeature] = useState("");
  const [newSafetyFeature, setNewSafetyFeature] = useState("");
  const [detailedDataLength, setDetailedDataLength] = useState(0)
  const [dropDownData, setDropDownData] = useState([])
  const [detailedFields, setDetailedFields] = useState([])

  const [isLoading, setIsLoading] = useState(false);
  const hiddenFileInput = useRef(null);
  const hiddenVideoFileInput = useRef(null);
  const [files, setFiles] = useState([]);
  const [video, setVideo] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  const [whatsapp, setWhatsapp] = useState(false);
  const [telegram, setTelegram] = useState(false);
  const [sms, setSms] = useState(false);
  const [call, setCall] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const bodyPartsFields = [
    { name: strings.frontBumper, label: strings.frontBumper },
    { name: strings.engineBonnet, label: strings.engineBonnet },
    { name: strings.ceiling, label: strings.ceiling },
    { name: strings.rightFrontFendor, label: strings.rightFrontFendor },
    { name: strings.rightFrontDoor, label: strings.rightFrontDoor },
    { name: strings.rightRearDoor, label: strings.rightRearDoor },
    { name: strings.leftFrontFendor, label: strings.leftFrontFendor },
    { name: strings.rightRearFendor, label: strings.rightRearFendor },
    { name: strings.leftFrontDoor, label: strings.leftFrontDoor },
    { name: strings.leftRearDoor, label: strings.leftRearDoor },
    { name: strings.leftRearFendor, label: strings.leftRearFendor },
    { name: strings.tailgate, label: strings.tailgate },
    { name: strings.rearBumper, label: strings.rearBumper }
  ];


  const handleOptionChange = (name, fieldValue) => {
    const selectedOption = {
      fieldName: name,
      value: fieldValue,
    };

    let selectedOptions = formik.values.bodyParts;

    const isSameRow = selectedOptions.some((item => item.fieldName === name))

    const index = selectedOptions.findIndex(option => option.fieldName === name);

    if (index !== -1 && isSameRow) {
      selectedOptions.splice(index, 1);
      selectedOptions.push(selectedOption);
    } else if (index !== -1) {
      selectedOptions.splice(index, 1);
    } else {
      selectedOptions.push(selectedOption);
    }

    formik.setFieldValue("bodyParts", selectedOptions);
  };

  const handleVideoUpload = (File) => {
    setVideoLoading(true)
    const formData = new FormData();
    formData.append("files", File);

    axios
      .post(
        `${uploadDataUrl}/v1/adds/upload-multiple`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setVideoFile(res.data[0]);
        setVideoLoading(false)
        formik.setFieldValue("videoUrl", res.data[0])
        formik.setFieldValue("videoThumbnail", res.data[0])
        toast.success(`${strings.videoUpload}`)
      })
      .catch((err) => {
        setVideoLoading(false)
        toast.error(`${strings.videoUploadFailed}`)
      });
  };

  const handleVideoClick = () => {
    hiddenVideoFileInput.current.click();
  };

  const handleImageUpload = (files) => {
    Array.from(files).forEach((file) => {
      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
          const { width, height } = img;

          if (width < 500 || height < 500) {
            toast.error("Please select an image with height and width greater than 500px");
            return;
          }

          // Proceed with the upload if dimensions are as expected
          const formData = new FormData();
          formData.append("files", file);
          setImageLoading(true);

          axios
            .post(`${uploadDataUrl}/v1/adds/upload-multiple`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              setImageLoading(false);

              setFiles((prevFiles) => [
                ...prevFiles,
                {
                  image: res.data[0],
                },
              ]);


              toast.success(`${strings.imageUpload}`);
            })
            .catch((err) => {
              setImageLoading(false);
              toast.error(`${strings.imageUploadFailed}`);
            });
        };

        img.onerror = () => {
          setImageLoading(false);
          toast.error("Invalid image file.");
        };

        img.src = e.target.result;
      };

      fileReader.onerror = () => {
        setImageLoading(false);
        toast.error("Failed to read the file.");
      };

      fileReader.readAsDataURL(file);
    });
  };

  const uploadCoverPhoto = (file) => {


    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const img = new Image();

      img.onload = () => {
        const { width, height } = img;

        if (width < 500 || height < 500) {
          toast.error("Please select an image with heigth and width of greater than 500px")
          return
        }

        setCoverPhotoLoading(true);
        const formData = new FormData();
        formData.append("files", file);

        axios
          .post(`${uploadDataUrl}/v1/adds/upload-multiple`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            setCoverPhotoLoading(false);
            formik.setFieldValue('coverPhoto', res.data[0])

            toast.success(`${strings.imageUpload}`);
          })
          .catch((err) => {
            toast.error(`${strings.imageUploadFailed}`);
          });

      };

      img.onerror = () => {
        setImageLoading(false);
        toast.error("Invalid image file.");
      };

      img.src = e.target.result;
    };

    fileReader.onerror = () => {
      setImageLoading(false);
      toast.error("Failed to read the file.");
    };

    fileReader.readAsDataURL(file);

  };

  const validationSchema = Yup.object().shape({

    purpose: Yup.string().required(`${strings.selectPurpose}`),
    basicInformation: Yup.object().shape({
      category: Yup.string().required(`${strings.selectCat}`),
      brand: Yup.string().required(`${strings.selectBrand}`),
      model: Yup.string().required(`${strings.selectModel}`),
      title: Yup.string()
        .min(5, `${strings.titleErrorMsgOne}`)
        .required(`${strings.titleErrorMsgTwo}`),
      description: Yup.string()
        .min(10, `${strings.descErrorMsgOne}`)
        .required(`${strings.descErrorMsgTwo}`),
      price: Yup.object().shape({
        currency: Yup.string().required(`${strings.currencyError}`),
        price: Yup.number()
          .min(0, `${strings.priceErrorOne}`)
          .required(`${strings.priceErrorTwo}`),
      }),
    }),
  });

  const formik = useFormik({
    initialValues: {
      section: vehicleId,
      purpose: "",
      region: "",
      basicInformation: {
        title: "",
        description: "",
        price: {
          currency: "",
          price: 0,
        },
        category: "",
        brand: "",
        model: "",
        details: [],
        contactInformation: {
          nameSurname: "",
          contactList: [],
        },
      },

      internalFeatures: [],
      externalFeaturesNotDisclose: false,
      externalFeatures: [],
      internalFeaturesNotDisclose: false,
      safetyFeaturesNotDisclose: false,
      safetyFeatures: [],

      location: {
        country: "",
        city: "",
        district: "",
        complex: "",
        neighborhood: "",
        openAddress: "",
        geoPoints: {
          coordinates: [0, 0],
        },
      },
      bodyParts: [],
      imageUrl: [],
      videoUrl: "",
      coverPhoto: ""
    },

    validationSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
  });


  const addNewInternalFeature = () => {
    if (newInternalFeature === '' || newInternalFeature === ' ') {
      toast.error(strings.fill);
      return;
    } else {
      const newItem = {
        fieldName: newInternalFeature,
      };
      const currentInternalfeatures = formik.values.internalFeatures;
      const currentGetInternalfeatures = getInternalFeatures;

      // Check if an item with the same fieldName already exists
      const isDuplicate = currentInternalfeatures.some(
        (item) => item.fieldName === newInternalFeature
      );

      if (isDuplicate) {
        toast.error(strings.noDuplicate);
        return;
      }

      const updatedInternalfeatures = [...currentInternalfeatures, newItem];
      const updatedGetInternalFeatures = [...currentGetInternalfeatures, newItem];
      formik.setFieldValue("internalFeatures", updatedInternalfeatures);
      setNewInternalFeature("");
      setGetInternalFeatures(updatedGetInternalFeatures);
      setShowModal(false);
    }
  };

  const addNewExternalFeature = () => {
    if (newExternalFeature.trim() === '') {
      toast.error(strings.fill);
      return;
    } else {
      const newItem = {
        fieldName: newExternalFeature,
      };

      const currentInternalfeatures = formik.values.externalFeatures;
      const currentExtFeatures = getExternalFeatures;

      // Check if an item with the same fieldName already exists
      const isExistingItem = currentInternalfeatures.some(
        (item) => item.fieldName === newExternalFeature
      );

      if (isExistingItem) {
        toast.error(strings.noDuplicate);
        return;
      }

      const updatedInternalfeatures = [...currentInternalfeatures, newItem];
      const updatedExtFeatures = [...currentExtFeatures, newItem];
      formik.setFieldValue("externalFeatures", updatedInternalfeatures);
      setNewExternalFeature("");
      setGetExternalFeatures(updatedExtFeatures);
      setExternalShowModal(false);
    }
  };

  const addNewSafetyFeature = () => {
    if (newSafetyFeature.trim() === '') {
      toast.error(strings.fill);
      return;
    } else {
      const newItem = {
        fieldName: newSafetyFeature,
      };
      const currentInternalfeatures = formik.values.safetyFeatures;
      const curentSafetyFeatures = getsafetyFeatures;

      // Check if an item with the same fieldName already exists
      const isDuplicate = currentInternalfeatures.some(
        (item) => item.fieldName === newSafetyFeature
      );
      if (isDuplicate) {
        toast.error(strings.noDuplicate);
        return;
      }

      const updatedInternalfeatures = [...currentInternalfeatures, newItem];
      const updatedSafetyFeatures = [...curentSafetyFeatures, newItem];
      formik.setFieldValue("safetyFeatures", updatedInternalfeatures);
      setNewSafetyFeature("")
      setGetSafetyFeatures(updatedSafetyFeatures);
      setSafetyShowModal(false);
    }
  };


  const handleFileUpload = (event) => {
    const files = event.target.files;
    setSelectedFiles(files);
  };

  const handleSave = (values) => {
    submitData(values);
  };

  const removeImage = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };


  const handleRemoveVideo = () => {
    setVideoFile(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await apiClient.get(
          `/v1/adds/section/feature?section=vehicle&language=${strings._language}`,
        );

        const filteredData = response.data.detail.filter((item) =>
          item.fieldName !== "Currency" && item.fieldName !== "Валюта" && item.fieldName !== "Valyuta" &&
          item.fieldName !== "Purpose" && item.fieldName !== "цель" && item.fieldName !== "Maqsad" &&
          item.fieldName !== "Series" && item.fieldName !== "Ряд" && item.fieldName !== "Seriya"
        );
        setGetData(filteredData)
        setDetailedFields(filteredData)
        setDropDownData(filteredData)
        setMyData(response.data.detail)
        setDetailedDataLength(response.data.detail.length - 3)
        setGetInternalFeatures(response.data.internalFeatures);
        setGetExternalFeatures(response.data.externalFeatures);
        setGetSafetyFeatures(response.data.saftyFeatures);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [strings._language]);

  useEffect(() => {

    if (formik.values.basicInformation.category === '63f72212e53fdd92a84520d0' || formik.values.basicInformation.category === '63f71edfe53fdd92a844f321') {
      const filteredFields = getData.filter((item) =>
        item._id !== "641c0c2e690ed315f0e1f7d6" && item._id !== "641c0c2e690ed315f0e1f716" && item._id !== "641c0c2e690ed315f0e1f896" &&
        item._id !== "641c0c2e690ed315f0e1f7d7" && item._id !== "641c0c2e690ed315f0e1f717" && item._id !== "641c0c2e690ed315f0e1f8a4" &&
        item._id !== "641c0c2e690ed315f0e1f7d8" && item._id !== "641c0c2e690ed315f0e1f718" && item._id !== "641c0c2e690ed315f0e1f898" &&
        item._id !== "641c0c2e690ed315f0e1f7e2" && item._id !== "641c0c2e690ed315f0e1f722" && item._id !== "641c0c2e690ed315f0e1f8a2"
      );

      setGetData(filteredFields)

    } else {
      setGetData(detailedFields)
    }


  }, [formik.values.basicInformation.category])

  useEffect(() => {
    if (formik.values.basicInformation.category === '63f71a6fe53fdd92a844cf22' || formik.values.basicInformation.category === '63f722eee53fdd92a84526ed' || formik.values.basicInformation.category === '63f723aae53fdd92a8452ec4') {
      const filteredData = getData.filter((item) => item._id !== '641c0c2e690ed315f0e1f7d6' && item._id !== '641c0c2e690ed315f0e1f7d7' && item._id !== '641c0c2e690ed315f0e1f7d8' && item._id !== '641c0c2e690ed315f0e1f716' && item._id !== '641c0c2e690ed315f0e1f717' && item._id !== '641c0c2e690ed315f0e1f718' && item._id !== '641c0c2e690ed315f0e1f896' && item._id !== '641c0c2e690ed315f0e1f8a4' && item._id !== '641c0c2e690ed315f0e1f898')
      setGetData(filteredData)
    }
  }, [selectedCategoryTitle])


  const submitData = (data) => {

    const missingField = getData.find((field) => {
      const matchedDetail = formik.values.basicInformation.details.find(
        (detail) => detail?.fieldName === field.fieldName
      );
      return !matchedDetail || !matchedDetail.value?.trim();
    });

    if (formik.values.region === "") {
      toast.error(`${strings.required}: ${strings.region}`)
    } else if (missingField) {
      toast.error(`${strings.required}: ${missingField.fieldName}`);
    } else if (formik.values.coverPhoto === "") {
      toast.error(strings.locationAcces)
    } else if (formik.values.location.city === "" || formik.values.location.country === "") {
      toast.error(strings.locationAcces)
    } else if (formik.values.imageUrl.length === 0) {
      toast.error(`${strings.imgErr}`)
    } else if (formik.values.basicInformation.category !== '63f71a6fe53fdd92a844cf22' && formik.values.basicInformation.category !== '63f722eee53fdd92a84526ed' && formik.values.basicInformation.category !== '63f723aae53fdd92a8452ec4' && formik.values.basicInformation.category !== "65a7d5c89f32be903d687bd4" && formik.values.basicInformation.category !== "63f71edfe53fdd92a844f321" && formik.values.basicInformation.category !== "6581423ba4410bdb6dd94367" && formik.values.basicInformation.category !== "63f72212e53fdd92a84520d0" && !formik.values.externalFeaturesNotDisclose && formik.values.externalFeatures.length < 1) {
      toast.error(`${strings.externalFeatureError}`)


    } else if (formik.values.basicInformation.category !== '63f71a6fe53fdd92a844cf22' && formik.values.basicInformation.category !== '63f722eee53fdd92a84526ed' && formik.values.basicInformation.category !== '63f723aae53fdd92a8452ec4' && formik.values.basicInformation.category !== "65a7d5c89f32be903d687bd4" && formik.values.basicInformation.category !== "63f71edfe53fdd92a844f321" && formik.values.basicInformation.category !== "6581423ba4410bdb6dd94367" && formik.values.basicInformation.category !== "63f72212e53fdd92a84520d0" && !formik.values.internalFeaturesNotDisclose && formik.values.internalFeatures.length < 1) {
      toast.error(`${strings.internalFeatureError}`)
    } else if (formik.values.basicInformation.category !== '63f71a6fe53fdd92a844cf22' && formik.values.basicInformation.category !== '63f722eee53fdd92a84526ed' && formik.values.basicInformation.category !== '63f723aae53fdd92a8452ec4' && formik.values.basicInformation.category !== "65a7d5c89f32be903d687bd4" && formik.values.basicInformation.category !== "63f71edfe53fdd92a844f321" && formik.values.basicInformation.category !== "6581423ba4410bdb6dd94367" && formik.values.basicInformation.category !== "63f72212e53fdd92a84520d0" && !formik.values.safetyFeaturesNotDisclose && formik.values.safetyFeatures.length < 1) {
      toast.error(`${strings.safetyFeatureError}`)
    } else if (formik.values.basicInformation.contactInformation.contactList.length === 0) {
      toast.error(`${strings.selectContact}`)
    } else {
      navigate("/vehicle-preview", {
        state: {
          formik: formik.values,
          isUpdate: false,
          basicInfo: {
            category: category,
            brand: brand,
            model: model
          }
        },
        isEdit: false
      })

    }

  };


  const handleInternalFeatures = (fieldName, imageUrl) => {
    if (fieldName) {
      const existingIndex = internalFeatures.findIndex(
        (item) => item.fieldName === fieldName
      );
      if (existingIndex !== -1) {
        const newFeatures = [...internalFeatures];
        newFeatures.splice(existingIndex, 1);
        setInternalFeatures(newFeatures);
      } else {
        const newItem = {
          fieldName: fieldName,
          imageUrl: imageUrl,
        };
        setInternalFeatures([...internalFeatures, newItem]);
      }
    } else {
    }
  };

  useEffect(() => {
    formik.setFieldValue("internalFeatures", internalFeatures)
  }, [internalFeatures])

  const handleExternalFeatures = (fieldName, imageUrl) => {
    if (fieldName) {
      const existingIndex = externalFeatures.findIndex(
        (item) => item.fieldName === fieldName
      );
      if (existingIndex !== -1) {
        const newFeatures = [...externalFeatures];
        newFeatures.splice(existingIndex, 1);
        setExternalFeatures(newFeatures);
      } else {
        const newItem = {
          fieldName: fieldName,
          imageUrl: imageUrl,
        };
        setExternalFeatures([...externalFeatures, newItem]);
      }
    } else {
    }
  };


  useEffect(() => {
    formik.setFieldValue("externalFeatures", externalFeatures)
  }, [externalFeatures])


  const handleSaftyFeatures = (fieldName, imageUrl) => {
    if (fieldName) {
      const existingIndex = safetyFeatures.findIndex(
        (item) => item.fieldName === fieldName
      );
      if (existingIndex !== -1) {
        const newFeatures = [...safetyFeatures];
        newFeatures.splice(existingIndex, 1);
        setSafetyFeatures(newFeatures);
      } else {
        const newItem = {
          fieldName: fieldName,
          imageUrl: imageUrl,
        };
        setSafetyFeatures([...safetyFeatures, newItem]);
      }
    } else {
    }
  };


  useEffect(() => {
    formik.setFieldValue("safetyFeatures", safetyFeatures)
  }, [safetyFeatures])

  useEffect(() => {
    formik.setFieldValue(
      "basicInformation.contactInformation.contactList",
      selectedOptions
    );
  }, [selectedOptions]);


  useEffect(() => {

    files.forEach((file) => {
      imageUrl.push(file.image);
    });
    formik.setFieldValue("imageUrl", imageUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);


  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/sections?language=${strings._language}`)
      .then((response) => {
        setBrands(response.data[0].categories);
      })
      .catch((err) => { });

    formik.setFieldValue('lango', strings._language)
  }, [strings._language]);

  useEffect(() => {
    axios
      .get(
        `${baseURL1}/v1/adds/brands-dropdown?catoryId=${formik.values.basicInformation.category}&language=${strings._language}`
      )
      .then((response) => {
        setModels(response.data);
      })
      .catch((err) => { });
  }, [formik.values.basicInformation.category, strings._language]);

  useEffect(() => {
    axios
      .get(
        `${baseURL1}/v1/adds/models-dropdown?brandId=${formik.values.basicInformation.brand}&language=${strings._language}`
      )
      .then((response) => {
        setModels1(response.data);
      })
      .catch((err) => { });
  }, [formik.values.basicInformation.brand, strings._language]);

  useEffect(() => {
    apiClient
      .get(
        `/v1/user/notification`
      )
      .then((response) => {
        setNotificationCount(response.data.length)
        setNotificationData(response.data)
      })
      .catch((err) => { });;
  }, [])


  return (
    <>
      <div>
        <h4 className="text-center">{strings.createAd}</h4>
      </div>
      <div className=" flex justify-center items-center mt-4 space-x-6">
        <div className="flex flex-col items-center">
          <img className="w-16" src={AdInformation} alt="" />
          <p
            className="text-center"
            style={{ color: "#F37521", fontSize: "12px", fontWeight: "bold" }}
          >
            {strings.adInfo}
          </p>
        </div>

        <div className="flex items-center">
          <div className="w-36" style={{ height: "1px", background: "#F37521" }}></div>
        </div>

        <div className="flex flex-col items-center">
          <img className="w-16" src={Review} alt="" />
          <p
            className="text-center py-2"
            style={{ color: "gray", fontSize: "14px", fontWeight: "bold" }}
          >
            {strings.reveiews}
          </p>
        </div>

        <div className="flex items-center">
          <div className="w-36" style={{ height: "1px", background: "gray" }}></div>
        </div>

        <div className="flex flex-col items-center">
          <img className="w-16" src={Package} alt="" />
          <p
            className="text-center py-2"
            style={{ color: "gray", fontSize: "14px", fontWeight: "bold" }}
          >
            {strings.pkg}
          </p>
        </div>
      </div>

      {/*section 3*/}
      <div className="container mx-auto   mt-4">
        <div className="bg-white flex justify-evenly pb-4  rounded-lg shadow-md flex-wrap  ">
          <div className="mt-5">
            <h6 className="font-bold">{strings.selectPurpose}</h6>
            <select
              className=" mt-3 w-72 h-14 rounded-full  border-1 shadow-md  bg-white  p-1 outline-[#F37521]"
              onChange={(e) => {
                formik.setFieldValue("purpose", e.target.value);
              }}
              value={formik.values.purpose}
            >
              <option>{strings.select}</option>

              <option value="sale">{strings.sale}</option>
              <option value="rent">{strings.rent}</option>
            </select>
            {formik.touched.purpose && formik.errors.purpose ? (
              <div className="text-red-500">{formik.errors.purpose}</div>
            ) : null}
          </div>
          <div className="mt-5">
            <h6 className="font-bold">{strings.selectCat}</h6>

            <select
              className="custom-select mt-3 w-72 h-14 rounded-full  border-1 shadow-md  bg-white  p-1 outline-[#F37521]"
              id="category"
              onChange={(e) => {
                const selectedBrand = brands.find(brand => brand._id === e.target.value);
                formik.setFieldValue("basicInformation.category", selectedBrand._id);

                setCategory(selectedBrand.title);
                if (selectedBrand.title === 'Motorcycles' || selectedBrand.title === 'Мотоциклы' || selectedBrand.title === 'Mototsikllar' || selectedBrand.title === 'ATV' || selectedBrand.title === 'UTV' || selectedBrand.title === 'квадроцикл' || selectedBrand.title === 'УТВ') {
                  setSelectedCategoryTitle(selectedBrand.title)
                } else {
                  setSelectedCategoryTitle('')
                }

              }}
              value={formik.values.basicInformation.category}
            >
              <option>{strings.select}</option>

              {brands.sort((a, b) => a.title.localeCompare(b.title)).map((brand) => (
                <option key={brand._id} value={brand._id}>
                  {brand.title}

                </option>
              ))}
            </select>

            {formik.touched.basicInformation?.category &&
              formik.errors.basicInformation?.category ? (
              <div className="text-red-500">
                {formik.errors.basicInformation.category}
              </div>
            ) : null}
          </div>

          <div className="mt-5">
            <h6 className="font-bold">{strings.selectBrand}</h6>
            <select
              className="custom-select mt-3 w-72 h-14 rounded-full  border-1 shadow-md  bg-white  p-1 outline-[#F37521]"
              id="model"
              onChange={(e) => {
                const selectedModel = models.find(model => model._id === e.target.value);
                formik.setFieldValue("basicInformation.brand", selectedModel._id);
                setBrand(selectedModel.title);
              }}
              value={formik.values.basicInformation.brand}
            >
              <option>{strings.select}</option>

              {models.sort((a, b) => a.title.localeCompare(b.title)).map((model) => (
                <option key={model._id} value={model._id}>
                  {model.title}
                </option>
              ))}
            </select>

            {formik.touched.basicInformation?.brand &&
              formik.errors.basicInformation?.brand ? (
              <div className="text-red-500">
                {formik.errors.basicInformation.brand}
              </div>
            ) : null}
          </div>
          <div className="mt-5">
            <h6 className="font-bold">{strings.selectModel}</h6>
            <select
              id="model"
              className="custom-select mt-3 w-72 h-14 rounded-full  border-1 shadow-md  bg-white  p-1 outline-[#F37521]"
              onChange={(e) => {
                const selectedModel = models1.find(model => model._id === e.target.value);
                formik.setFieldValue("basicInformation.model", selectedModel._id);
                setModel(selectedModel.title);
              }}
              value={formik.values.basicInformation.model}
            >
              <option>{strings.select}</option>

              {models1.sort((a, b) => a.title.localeCompare(b.title)).map((model) => (
                <option key={model._id} value={model._id}>
                  {model.title}
                </option>
              ))}
            </select>


            {formik.touched.basicInformation?.model &&
              formik.errors.basicInformation?.model ? (
              <div className="text-red-500">
                {formik.errors.basicInformation.model}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="container mx-auto  mt-4">
        <div className="bg-white p-4 rounded-lg shadow-md ">
          <div>
            <h6 className="pt-2 font-bold">{strings.basicInfo}</h6>

            <input
              className="w-full bg-white shadow-md  border-1 p-3 rounded-full outline-[#F37521]"
              type="text"
              placeholder={strings.title}
              onChange={(e) => {
                formik.setFieldValue("basicInformation.title", e.target.value);
              }}
              value={formik.values.basicInformation.title}
              max={24}
            />
            {formik.touched.basicInformation?.title &&
              formik.errors.basicInformation?.title ? (
              <div className="text-red-500">
                {formik.errors.basicInformation.title}
              </div>
            ) : null}
          </div>

          <div>
            <div style={{ position: "relative", width: "100%" }}>
              <textarea
                className="w-full bg-white outline-[#F37521] border-1 shadow-md p-3 rounded-full mt-6"
                type="text"
                placeholder={strings.desc}
                onChange={(e) => {
                  formik.setFieldValue(
                    "basicInformation.description",
                    e.target.value
                  );
                }}
                value={formik.values.basicInformation.description}
                style={{
                  fontStyle: isItalic ? "italic" : "normal",
                  fontWeight: isBold ? "bold" : "normal"
                }}
              />
              <div style={{ position: "absolute", bottom: 10, right: 20 }}>
                <button onClick={handleItalicClick} style={{ marginRight: "10px", fontWeight: 'bold' }}>
                  <FormatItalicIcon />
                </button>
                <button onClick={handleBoldClick} style={{ marginRight: "5px" }}>
                  <FormatBoldIcon />
                </button>
              </div>
            </div>
            {formik.touched.basicInformation?.description &&
              formik.errors.basicInformation?.description ? (
              <div className="text-red-500">
                {formik.errors.basicInformation.description}
              </div>
            ) : null}
          </div>

          <hr className="mt-5 w-full" />

          <div className="container mx-auto">
            {
              (formik.values.basicInformation.category !== '63f72212e53fdd92a84520d0' && formik.values.basicInformation.category !== '63f71edfe53fdd92a844f321') && (
                <>
                  <h6 className="font-bold">KM</h6>
                  <div className="">
                    <input
                      className="w-72 border-1  bg-white shadow-md p-3 rounded-full"
                      type="number"
                      placeholder={strings.typeHere}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          formik.setFieldValue(
                            "basicInformation.KM",
                            ''
                          );
                        } else {
                          formik.setFieldValue(
                            "basicInformation.KM",
                            e.target.value
                          );
                        }

                      }}
                      value={formik.values.basicInformation.KM}
                    />
                    {<div className="text-red-500">
                      {formik?.errors?.basicInformation?.KM}
                    </div>}
                  </div>
                </>
              )
            }

            <h6 className="pt-2 font-bold">{strings.price}</h6>

            <div className="flex justify-between flex-col md:flex-row">
              <div className="col-md-5 mt-3">
                <select
                  placeholder="dropDown"
                  name="dropDown"
                  className="custom-select w-72 h-14 rounded-full  border-1 shadow-md  bg-white  p-1 col-md-12"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "basicInformation.price.currency",
                      e.target.value
                    );
                  }}
                  value={formik.values.basicInformation.price.currency}
                >
                  <option>{strings.select}</option>
                  {myData?.find(obj => obj.fieldName === 'Currency' || obj.fieldName === "Валюта" || obj.fieldName === "Valyuta")?.dropDown.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}

                </select>

                {formik.touched.basicInformation?.price?.currency &&
                  formik.errors.basicInformation?.price?.currency ? (
                  <div className="text-red-500">
                    {formik.errors.basicInformation?.price?.currency}
                  </div>
                ) : null}
              </div>
              <div className="col-md-5 mt-3">
                <CurrencyInput
                  min={0}
                  decimalsLimit={2}
                  name='price'
                  id='price'
                  className="w-full bg-white  border-1 shadow-md p-3 rounded-full "
                  placeholder={strings.price}
                  onValueChange={(value, name, values) => formik.setFieldValue(
                    "basicInformation.price.price",
                    value
                  )}

                  value={formik.values.basicInformation.price.price}
                />

                {formik.touched.basicInformation?.price?.price &&
                  formik.errors.basicInformation?.price?.price ? (
                  <div className="text-red-500">
                    {formik.errors.basicInformation?.price?.price}
                  </div>
                ) : null}
              </div>
            </div>

            <h6 className="pt-2 font-bold">{strings.region}</h6>
            <div>
              <select
                value={formik.values.region}
                onChange={(e) => formik.setFieldValue("region", e.target.value)}
                className="custom-select w-72 h-14 rounded-full  border-1 shadow-md  bg-white  p-1 col-md-12"
              >
                <option value="">{strings.select} {strings.region}</option>
                {regions[strings._language]?.map((region, index) => (
                  <option key={index} value={region.value}>
                    {region.title}
                  </option>
                ))}
              </select>
            </div>


            {/* Dropdown section */}
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 py-5 justify-center">
              {
                getData.map((data, index) => (
                  <div key={index}>
                    <h6 className="mt-2 font-bold">{data.fieldName}</h6>

                    <select
                      placeholder="dropDown"
                      name="dropDown"
                      className="custom-select mt-3 w-72 h-14 rounded-full  border-1 shadow-md  bg-white  p-1"
                      onChange={(e) =>
                        formik.setFieldValue(`basicInformation.details[${index}]`, {
                          fieldName: data.fieldName,
                          value: e.target.value,
                          imageUrl: data.imageUrl
                        })
                      }
                    >
                      <option>{strings.select}</option>
                      {
                        data.dropDown.length === 0 ? (
                          <>
                            <option>{strings.yes}</option>
                            <option>{strings.no}</option>
                          </>
                        ) : (
                          [...new Set(data.dropDown)].map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))
                        )
                      }
                    </select>

                  </div>
                ))
              }
            </div>

            <h5 className="pt-3 font-bold">{strings.contactInfo}</h5>

            <div className="w-24 flex mt-4">
              <label className="flex items-center">
                <div>
                  <input
                    type="radio"
                    className="h-8 w-8 text-purple-500"
                    name="whatsapp"
                    value="whatsapp"
                    onClick={() => {
                      if (whatsapp) {
                        setWhatsapp(false);
                        setSelectedOptions((prevSelectedOptions) =>
                          prevSelectedOptions.filter(
                            (option) => option !== "Whatsapp"
                          )
                        );
                      } else {
                        setWhatsapp(true);
                        setSelectedOptions((prevSelectedOptions) => [
                          ...prevSelectedOptions,
                          "Whatsapp",
                        ]);
                      }
                    }}
                    checked={whatsapp}
                  />
                </div>
                <div className="ml-2">{strings.wtp}</div>
              </label>
            </div>
            <div className="w-24 flex mt-4">
              <label className="flex items-center">
                <div>
                  <input
                    type="radio"
                    className="h-8 w-8 text-purple-500"
                    name="telegram"
                    value="telegram"
                    onClick={() => {
                      if (telegram) {
                        setTelegram(false);
                        setSelectedOptions((prevSelectedOptions) =>
                          prevSelectedOptions.filter(
                            (option) => option !== "Telegram"
                          )
                        );
                      } else {
                        setTelegram(true);
                        setSelectedOptions((prevSelectedOptions) => [
                          ...prevSelectedOptions,
                          "Telegram",
                        ]);
                      }
                    }}
                    checked={telegram}
                  />
                </div>
                <div className="ml-2">{strings.teleg}</div>
              </label>
            </div>
            <div className="w-24 flex mt-4">
              <label className="flex items-center">
                <div>
                  <input
                    type="radio"
                    className="h-8 w-8 text-purple-500"
                    name="call"
                    value="call"
                    onClick={() => {
                      if (call) {
                        setCall(false);
                        setSelectedOptions((prevSelectedOptions) =>
                          prevSelectedOptions.filter(
                            (option) => option !== "Call"
                          )
                        );
                      } else {
                        setCall(true);
                        setSelectedOptions((prevSelectedOptions) => [
                          ...prevSelectedOptions,
                          "Call",
                        ]);
                      }
                    }}
                    checked={call}
                  />
                </div>
                <div className="ml-2">{strings.call}</div>
              </label>
            </div>
            <div className=" mt-4">
              <label className="flex items-center ">
                <div>
                  <input
                    type="radio"
                    className="h-8 w-8 text-purple-500"
                    name="sms"
                    value="sms"
                    onClick={() => {
                      if (sms) {
                        setSms(false);
                        setSelectedOptions((prevSelectedOptions) =>
                          prevSelectedOptions.filter(
                            (option) => option !== "Sms"
                          )
                        );
                      } else {
                        setSms(true);
                        setSelectedOptions((prevSelectedOptions) => [
                          ...prevSelectedOptions,
                          "Sms",
                        ]);
                      }
                    }}
                    checked={sms}
                  />
                </div>
                <div className="ml-2">{strings.sms}</div>
              </label>
            </div>

          </div>

          {/*........................................................*/}

          {
            selectedCategoryTitle === '' && (
              <>
                {
                  (formik.values.basicInformation.category !== '65a7d5c89f32be903d687bd4' && formik.values.basicInformation.category !== '6581423ba4410bdb6dd94367' &&
                    formik.values.basicInformation.category !== '63f71edfe53fdd92a844f321' && formik.values.basicInformation.category !== '63f72212e53fdd92a84520d0'
                  ) && (
                    <>
                      <hr className="mt-5" />
                      <div className="">
                        <div className=" flex mt-4">
                          <label className="flex">
                            <input
                              type="radio"
                              className="h-8 w-8 text-purple-500"
                              value="formik.values.internalFeaturesNotDisclose"
                              onClick={() => {
                                if (formik.values.internalFeaturesNotDisclose) {
                                  formik.setFieldValue(
                                    "internalFeaturesNotDisclose",
                                    false
                                  );
                                } else {
                                  formik.setFieldValue("internalFeaturesNotDisclose", true);
                                }
                              }}
                              checked={formik.values.internalFeaturesNotDisclose}
                            />
                            <div className="ml-2 text-gray-700 font-bold py-1">
                              {strings.disclose} {strings.internalFeatures}
                            </div>
                          </label>
                        </div>
                      </div>
                      <h5 className="pt-3 font-bold">{strings.internalFeatures}</h5>

                      <div className="">
                        <div className="grid   grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7  gap-3 mt-4">
                          {getInternalFeatures.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item, index) => {
                            const isActive =
                              internalFeatures.findIndex(
                                (feature) => feature.fieldName === item.fieldName
                              ) !== -1;
                            return (
                              <div
                                className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                                  }`}
                                key={item._id}
                                onClick={() =>
                                  handleInternalFeatures(item.fieldName, item.imageUrl)
                                }
                              >
                                <img className="w-12" src={item.imageUrl} alt="" />
                                <h1 className="text-center text-sm font-extrabold "
                                  style={{
                                    fontWeight: 'bold'
                                  }}>
                                  {item.fieldName}
                                </h1>
                              </div>
                            );
                          })}
                        </div>

                        <div className="text-red-400 flex justify-center mt-5">
                          <button onClick={() => setShowModal(true)}> +{strings.addManually}</button>
                        </div>
                        <Modal
                          size="md"
                          style={{ marginTop: "50px" }}
                          show={showModal}
                          onHide={() => setShowModal(false)}
                        >
                          <div className="p-3 flex flex-col items-center ">
                            <h6 className="pt-2"> +{strings.addManually}</h6>

                            <input
                              className="w-full bg-white  border-2 p-2 rounded-full "
                              type="text"
                              placeholder={strings.itemName}
                              onChange={(e) => setNewInternalFeature(e.target.value)}
                              value={newInternalFeature}
                            />

                            <button
                              className="w-40 p-2 rounded-full text-white mt-2 bg-orange-500"
                              onClick={addNewInternalFeature}
                            >
                              {strings.add}
                            </button>
                          </div>
                        </Modal>

                        <div className="">
                          <div className=" flex mt-4">
                            <label className="flex">
                              <input
                                type="radio"
                                className="h-8 w-8 text-purple-500"
                                value="formik.values.externalFeaturesNotDisclose"
                                onClick={() => {
                                  if (formik.values.externalFeaturesNotDisclose) {
                                    formik.setFieldValue(
                                      "externalFeaturesNotDisclose",
                                      false
                                    );
                                  } else {
                                    formik.setFieldValue(
                                      "externalFeaturesNotDisclose",
                                      true
                                    );
                                  }
                                }}
                                checked={formik.values.externalFeaturesNotDisclose}
                              />
                              <div className="ml-2 text-gray-700 font-bold py-1">
                                {strings.disclose} {strings.extFeatures}
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <h5 className="pt-2 font-bold">{strings.extFeatures}</h5>

                      <div className="grid   grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7  gap-3 mt-4">
                        {getExternalFeatures.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item, index) => {
                          const isActive =
                            externalFeatures.findIndex(
                              (feature) => feature.fieldName === item.fieldName
                            ) !== -1;
                          return (
                            <div
                              className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                                }`}
                              key={item._id}
                              onClick={() =>
                                handleExternalFeatures(item.fieldName, item.imageUrl)
                              }
                            >
                              <img className="w-12" src={item.imageUrl} alt="" />
                              <div className="p-1 text-center text-sm"
                                style={{
                                  fontWeight: 'bold'
                                }}>
                                {item.fieldName}
                              </div>
                            </div>
                          );
                        })}

                        <div className="text-red-400 flex justify-center mt-5">
                          <button onClick={() => setExternalShowModal(true)}>
                            {" "}
                            +{strings.addManually}
                          </button>
                        </div>
                        <Modal
                          size="md"
                          style={{ marginTop: "50px" }}
                          show={showExternalModal}
                          onHide={() => setExternalShowModal(false)}
                        >
                          <div className="p-3 flex flex-col items-center ">
                            <h6 className="pt-2"> +{strings.addManually}</h6>

                            <input
                              className="w-full bg-white  border-2 p-2 rounded-full "
                              type="text"
                              placeholder={strings.itemName}
                              onChange={(e) => setNewExternalFeature(e.target.value)}
                              value={newExternalFeature}
                            />

                            <button
                              className="w-40 p-2 rounded-full text-white mt-2 bg-orange-500"
                              onClick={addNewExternalFeature}
                            >
                              {strings.add}
                            </button>
                          </div>
                        </Modal>
                      </div>

                      <div className="mt-5">
                        <div className=" flex mt-4">
                          <label className="flex">
                            <input
                              type="radio"
                              className="h-8 w-8 text-purple-500"
                              value="formik.values.safetyFeaturesNotDisclose"
                              onClick={() => {
                                if (formik.values.safetyFeaturesNotDisclose) {
                                  formik.setFieldValue("safetyFeaturesNotDisclose", false);
                                } else {
                                  formik.setFieldValue("safetyFeaturesNotDisclose", true);
                                }
                              }}
                              checked={formik.values.safetyFeaturesNotDisclose}
                            />
                            <div className="ml-2 text-gray-700 text-sm font-bold py-1">
                              {strings.disclose} {strings.safetyFeatures}
                            </div>
                          </label>
                        </div>
                      </div>
                      <h5 className="pt-4 font-bold">{strings.safetyFeatures}</h5>
                      <div className="grid   grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 mt-4 gap-3">
                        {getsafetyFeatures.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item, index) => {
                          const isActive =
                            safetyFeatures.findIndex(
                              (feature) => feature.fieldName === item.fieldName
                            ) !== -1;
                          return (
                            <div
                              className={`bg-white shadow-md rounded-lg flex flex-col justify-center items-center p-2 cursor-pointer ${isActive ? "border-2 border-orange-400" : ""
                                }`}
                              key={item._id}
                              onClick={() =>
                                handleSaftyFeatures(item.fieldName, item.imageUrl)
                              }
                            >
                              <img className="w-12" src={item.imageUrl} alt="" />
                              <div className="p-1 text-center text-sm"
                                style={{
                                  fontWeight: 'bold'
                                }}>
                                {item.fieldName}
                              </div>
                            </div>
                          );
                        })}
                        <div className="text-red-400 flex justify-center mt-5">
                          <button onClick={() => setSafetyShowModal(true)}>
                            {" "}
                            +{strings.addManually}
                          </button>
                        </div>
                        <Modal
                          size="md"
                          style={{ marginTop: "50px" }}
                          show={showSafetyModal}
                          onHide={() => setSafetyShowModal(false)}
                        >
                          <div className="p-3 flex flex-col items-center ">
                            <h6 className="pt-2"> +{strings.addManually}</h6>

                            <input
                              className="w-full bg-white  border-2 p-2 rounded-full "
                              type="text"
                              placeholder={strings.itemName}
                              onChange={(e) => setNewSafetyFeature(e.target.value)}
                              value={newSafetyFeature}
                            />

                            <button
                              className="w-40 p-2 rounded-full text-white mt-2 bg-orange-500"
                              onClick={addNewSafetyFeature}
                            >
                              {strings.add}
                            </button>
                          </div>
                        </Modal>
                      </div>
                    </>
                  )
                }

                {/* body parts */}
                {
                  (formik.values.basicInformation.category !== '65a7d5c89f32be903d687bd4' && formik.values.basicInformation.category !== '6581423ba4410bdb6dd94367' &&
                    formik.values.basicInformation.category !== '63f71edfe53fdd92a844f321' && formik.values.basicInformation.category !== '63f72212e53fdd92a84520d0'
                  ) && (
                    <div className="mt-5">
                      <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 font-bold  py-4 whitespace-nowrap"
                                    >
                                      {strings.bodyParts}
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs   text-green-500 uppercase tracking-wider"
                                    >
                                      {strings.original}
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs  text-red-300 uppercase tracking-wider"
                                    >
                                      {strings.painted}
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs  text-orange-400 uppercase tracking-wider"
                                    >
                                      {strings.replaced}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  {
                                    bodyPartsFields.map((field, index) => (
                                      <tr key={index} >

                                        <td className="px-6 font-bold py-4 whitespace-nowrap">
                                          {index + 1} {capitalizeFirstLetter(field.label)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <div
                                            className=
                                            {` rounded-full w-[32px] h-[32px] border-1 border-slate-700
                                        ${formik.values.bodyParts.some(item => item.fieldName === field.name && item.value === "original") ? 'bg-green-600' : 'bg-white'}
                                        `}

                                            onClick={() => handleOptionChange(field.name, "original")}
                                          >
                                          </div>

                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <div
                                            className=
                                            {` rounded-full w-[32px] h-[32px] border-1 border-slate-700
                                        ${formik.values.bodyParts.some(item => item.fieldName === field.name && item.value === "painted") ? 'bg-red-300' : 'bg-white'}
                                        `}

                                            onClick={() => handleOptionChange(field.name, "painted")}
                                          >
                                          </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <div
                                            className=
                                            {` rounded-full w-[32px] h-[32px] border-1 border-slate-700
                                        ${formik.values.bodyParts.some(item => item.fieldName === field.name && item.value === "replaced") ? 'bg-orange-400' : 'bg-white'}
                                        `}

                                            onClick={() => handleOptionChange(field.name, "replaced")}
                                          >
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

                <hr className="w-full mt-4" />
              </>
            )
          }

          {/*Upload Images*/}
          <h3 className="text-sm mt-5">{strings.upload} {strings.image}</h3>
          <div className="col-12 bg-white shadow-lg rounded-lg mt-4">
            <div className="ml-auto mr-auto w-full justify-center p-3">
              <h6 className="text-center"> {/* Add the 'text-center' class */}
                {strings.upload} {strings.images}
              </h6>
              <form>
                <div className="flex w-100 justify-content-center"> {/* Add the 'justify-content-center' class */}
                  {Array.from(new Set(files.map(file => file.image))).map((image, index) => (
                    <div className="m-2" key={index}>
                      <div className="image-container">
                        <button className="remove-image" onClick={() => removeImage(index)}>
                          <FaTimes />
                        </button>
                        <img src={`${image}`} alt="" className="h-36 w-40" />
                        {/* <div className="remove-icon  cursor-pointer text-red-700 " onClick={() => removeImage(index)}>
                          &#10005;
                        </div> */}
                      </div>
                    </div>
                  ))}
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    multiple
                    ref={hiddenFileInput}
                    onChange={(e) => {
                      handleImageUpload(e.target.files);
                    }}
                  />
                </div>
                <div className="col-md-12 flex justify-center mt-2">
                  <div
                    onClick={() => hiddenFileInput.current.click()}
                    disabled={imageLoading}
                    className="bg-orange-500 rounded-md py-2 px-4 text-white cursor-pointer w-40 text-center"
                  >
                    {imageLoading ? strings.uploading : `${strings.add} ${strings.image}`}
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* Cover Photo */}
          <h3 className="text-sm mt-5">
            {strings.upload} {strings.coverPhoto}
          </h3>
          <div className="col-12 bg-white shadow-lg rounded-lg mt-4">
            <div className="ml-auto mr-auto w-full justify-center p-3">
              <h6 className="text-center">
                {" "}
                {/* Add the 'text-center' class */}
                {strings.upload} {strings.coverPhoto}
              </h6>
              <div className="flex w-100 justify-content-center">
                {" "}
                {
                  formik.values.coverPhoto !== '' && (
                    <div className="m-2" >
                      <div className="image-container">

                        <img src={`${formik?.values?.coverPhoto}`} alt="" className="h-36 w-40" />
                      </div>
                    </div>
                  )
                }
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={hiddenCoverFileInput}
                  onChange={(e) => {
                    uploadCoverPhoto(e.target.files[0]);
                  }}
                />
              </div>
              <div className="col-md-12 flex justify-center mt-2">
                <button
                  onClick={handleCoverPhotoClick}
                  className="bg-orange-500 rounded-md py-2 px-4 text-white cursor-pointer w-40 text-center"
                  disabled={coverPhotoLoading}
                >
                  {coverPhotoLoading
                    ? strings.uploading
                    : `${strings.add} ${strings.image}`}
                </button>
              </div>
            </div>
          </div>

          <div className="container mt-4 h-max p-2">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <h3 className="text-sm">{strings.upload} {strings.video}</h3>
                <div className="uploadimage">

                  <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => {
                      handleVideoUpload(e.target.files[0]);
                    }}
                    style={{ display: "none" }}
                    ref={hiddenVideoFileInput}
                  />

                  {videoFile && (
                    <div className="video-container">
                      <button className="remove-video" onClick={handleRemoveVideo}>
                        <FaTimes />
                      </button>
                      <video controls className="h-32 w-48">
                        <source src={videoFile} type={videoFile.type} />
                      </video>
                    </div>
                  )}

                  <div className="d-flex justify-content-center">
                    {
                      !videoFile && (
                        <button
                          className="bg-orange-500 rounded-md py-2 px-4 text-white cursor-pointer w-40 text-center"
                          onClick={handleVideoClick}
                          disabled={videoLoading}
                        >
                          {videoLoading ? strings.uploading : `${strings.add} ${strings.video}`}
                        </button>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="container mt-3">
            <div>{strings.location}</div>
            <div className="mt-2">
              <input type="text" placeholder={strings.city} className="w-full border-1  bg-white shadow-md p-3 rounded-full"
                onChange={(e) => formik.setFieldValue('location.city', e.target.value)}
                value={formik.values.location.city}
                readOnly={isLocationAvailable}
              />
            </div>
            <div className="mt-4">
              <input type="text" placeholder={strings.distt} className="w-full border-1  bg-white shadow-md p-3 rounded-full"
                onChange={(e) => formik.setFieldValue('location.district', e.target.value)}
                value={formik.values.location.district}
                readOnly={isLocationAvailable}
              />
            </div>
            <div className="mt-4">
              <input type="text" placeholder={strings.country} className="w-full border-1  bg-white shadow-md p-3 rounded-full"
                onChange={(e) => formik.setFieldValue('location.country', e.target.value)}
                value={formik.values.location.country}
                readOnly={isLocationAvailable}
              />
            </div>
          </div>


          <div
            className="container mt-3 h-[450px]"
          >

            <OpenStreetMap
              style={{
                height: '400px'
              }}
              formik={formik}
              setIsLocationAvailable={setIsLocationAvailable}
            />
          </div>


          <div className="flex justify-center mt-4 ">
            <button
              type="submit"
              className="w-80 p-3 rounded-full text-white mb-5 bg-orange-500"
              onClick={formik.handleSubmit}
            >
              {strings.next}
            </button>
          </div>
        </div>
      </div >
      {/*footer*/}

      < Footer strings={strings} />
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">{strings.notif}</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          {
            notificationData.map((data, index) => (
              <div key={index}
                className={`p-2 ${index % 2 === 0 ? "bg-gray-300" : ""
                  }`}
              >
                <p style={{ fontWeight: 'bold' }}>{index + 1}. {data.title}</p>
                <p>{data.message}</p>
              </div>
            ))
          }
        </div>

      </div>
    </>
  );
};

export default CreatedAdVehicle2;
