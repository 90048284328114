import { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import logoImage from "../images/logo.svg";
import NavbarImage1 from "../images/Group 11680.png";
import NavbarImage2 from "../images/Group 11819.png";
import profile from "../images/profile.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Login from "./Login";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { baseURL1, uploadDataUrl } from "../utils/baseUrl";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import strings from "../utils/locals/languages";
import { setLanguage } from "../app/languageSlice";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import apiClient from "../utils/apiClient";
import { getAuth } from "firebase/auth";
import Avatar from "@mui/material/Avatar";
import "./Header.css";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";

import { app } from "../firebase";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import EnglishFlag from '../images/english.png'
import RussianFlag from '../images/russian.png'
import uzbekFlag from '../images/uzbek.png'
import AdCreateModal from '../pages/home/AdCreateModal'
import { setNotifications } from "../app/notificationsSlice";
import DeleteUserModal from "./DeleteUser";
import { countUnreadNotifications } from "../utils/notificationsCount";
import AuthModal from "./AuthModal";


const Header = ({ search, setSearch }) => {

  const [notificationsLoading, setNotificationsLoading] = useState(true)
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showCreateAdModal, setShowCreateAdModal] = useState(false)
  const [country, setCountry] = useState();
  const [profileData, setProfileData] = useState({});
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [phoneOtpCode, setPhoneOtpCode] = useState("");
  const [phoneLoginLoader, setPhoneLoginLoader] = useState(false);
  const [signUpLoader, setSignUpLoader] = useState(false);
  const [userVerificationLoader, setUserVerificationLoader] = useState(false);
  const [passwordUpdatedSuccess, setPasswordUpdatedSuccess] = useState(false);
  const [phoneSignUpVerifyCodeScreen, setPhoneSignUpVerifyCodeScreen] =
    useState(false);
  const [emailVerificationCode, setEmailVerificationCode] = useState("");
  const [brands, setBrands] = useState([]);
  const [realEstateBrands, setRealEstateBrands] = useState([]);
  const [menu1Open, setMenu1Open] = useState(false);
  const button1Ref = useRef(null);
  const [phoneLoginOtpScreen, setPhoneLoginOtpScreen] = useState(false);
  const [timer, setTimer] = useState(30);
  const [resendEmailLoader, setResendEmailLoader] = useState(false)



  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer(prevTime => prevTime - 1);
      }, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [timer]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const handleMenu1Open = (event) => {
    setMenu1Open(true);
  };
  const handleMenu1Close = () => {
    setMenu1Open(false);
  };

  const handlePhoneCode = (e) => {
    setCountry(e);
    setPhone(e);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
  };

  const handleInput = (event, index) => {
    const input = inputRefs[index];
    const value = event.target.value;

    if (event.target.value.length >= 1) {
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    } else if (index > 0) {
      inputRefs[index - 1].current.focus();
    }

    // Update the otpValue state with the new input value
    const updatedOtpValue =
      phoneOtpCode.slice(0, index) + value + phoneOtpCode.slice(index + 1);
    setPhoneOtpCode(updatedOtpValue);
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain").trim();
    if (/^\d{1,4}$/.test(pasteData)) {
      for (let i = 0; i < pasteData.length && i < inputRefs.length; i++) {
        inputRefs[i].current.value = pasteData[i];
        if (i < inputRefs.length - 1) {
          inputRefs[i + 1].current.focus();
        }
      }
    }
  };

  const handlePhoneVerificationCode = (e) => {
    e.preventDefault();
    if (!phoneOtpCode) {
      toast.error(`${strings.verificationCodeRequired}`);
    } else {
      setMobileResetPassword(true);
      setEnterTheCode(false);
      setPasswordUpdatedSuccess(false);
      setPhoneSignUpVerifyCodeScreen(false);
      setResetPassword(false);
      setForgotEmailOtpScreen(false);
      setSignInVerify(false);
      setSignUpForm(false);
      setForgotData(false);
      setEmailSignUpOtpScreen(false);
      setEmailSignInForm(false);
      setSignInWithNumber(false);
      setSignInWithLogin(false);
      setRecoverWithEmail(false);
      setMobileNumber(false);
      setPhoneLoginOtpScreen(false);
      setSignUpWithPhone(false);
    }
  };

  const hiddenFileInput = useRef(null);

  const handleFileClick = (file) => {
    hiddenFileInput.current.click();
  };

  const handleLogoUpload = (file) => {
    const formData = new FormData();
    formData.append("files", file);

    axios
      .post(`${uploadDataUrl}/v1/adds/upload-multiple`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLogo(res.data[0]);
        toast.success(`${strings.imageUpload}`);
      })
      .catch((err) => {
        toast.error(`${strings.imageUploadFailed}`);
      });
  };
  const dispatch = useDispatch();
  const [isSectionsLoading, setIsSectionsLoading] = useState(true);
  const token = Cookies.get("token");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [forgotEmailEntry, setForgotEmailEntry] = useState("");
  const [forgotEmailLoader, setForgotEmailLoader] = useState(false);
  const [emailResetCode, setEmailResetCode] = useState("");
  const [emailResetLoader, setEmailResetLoader] = useState(false);
  const [resetPasswordLoader, setResetPasswordLoader] = useState(false);
  const [requestedPhoneNumber, setRequestedPhoneNumber] = useState("");
  const [emailSignUpOtpScreen, setEmailSignUpOtpScreen] = useState(false);

  const sendPhoneCodeRequest = (event) => {
    if (!requestedPhoneNumber) {
      toast.error(`${strings.phoneIsRequired}`);
    } else {
      setEnterTheCode(true);
      setPasswordUpdatedSuccess(false);
      setPhoneSignUpVerifyCodeScreen(false);
      setResetPassword(false);
      setForgotEmailOtpScreen(false);
      setSignInVerify(false);
      setSignUpForm(false);
      setForgotData(false);
      setEmailSignUpOtpScreen(false);
      setEmailSignInForm(false);
      setSignInWithNumber(false);
      setSignInWithLogin(false);
      setRecoverWithEmail(false);
      setMobileNumber(false);
      setMobileResetPassword(false);
      setPhoneLoginOtpScreen(false);
      setSignUpWithPhone(false);
    }
  };

  const readNotification = async () => {
    try {
      const response = await apiClient.put(`/v1/user/read-notifications`);
      setNotificationsLoading(false)

    } catch {
      setNotificationsLoading(false)
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logOutUser = () => {
    Cookies.remove("token");
    Cookies.remove("userDetails");
    toast.success("Logout Successfully");

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  const [show, setShow] = useState(false);

  const handleLanguageChange = (language) => {
    strings.setLanguage(language);

    Cookies.set("language", language);
    setTimeout(() => {
      window.location.reload();
    }, 500);
    dispatch(setLanguage(language));
  };

  useEffect(() => {
    const selectedLanguage = Cookies.get("language");

    if (selectedLanguage !== undefined) {
      strings.setLanguage(selectedLanguage);
    }
  }, []);
  const handleClose = () => {
    setShow(false);
    setEmailSignInForm(true);

    setSignUpForm(false);
    setForgotData(false);
    setEmailSignUpOtpScreen(false);

    setSignInWithNumber(false);
    setSignInVerify(false);
    setRecoverWithEmail(false);
    setForgotEmailOtpScreen(false);
    setResetPassword(false);
    setPasswordUpdatedSuccess(false);
    setPhoneSignUpVerifyCodeScreen(false);
    setMobileNumber(false);
    setEnterTheCode(false);
    setMobileResetPassword(false);
    setSignUpEmailAddress(false);
    setPhoneLoginOtpScreen(false);
    setSignUpWithPhone(false);
    setVerificationSuccessScreen(false);
  };

  const handleShow = () => setShow(true);
  const [isLocked, setIsLocked] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [signUpForm, setSignUpForm] = useState(false);
  const [emailSignInForm, setEmailSignInForm] = useState(true);
  const [signinwithnumber, setSignInWithNumber] = useState(false);
  const [signinwithlogin, setSignInWithLogin] = useState(false);
  const [signinverify, setSignInVerify] = useState(false);
  const [recoverWithEmail, setRecoverWithEmail] = useState(false);
  const [forgotEmailOtpScreen, setForgotEmailOtpScreen] = useState(false);
  const [resetpassword, setResetPassword] = useState(false);
  const [mobilenumber, setMobileNumber] = useState(false);
  const [enterthecode, setEnterTheCode] = useState(false);
  const [mobileresetpassword, setMobileResetPassword] = useState(false);
  const [forgotData, setForgotData] = useState(false);
  const [signupemailaddress, setSignUpEmailAddress] = useState(false);
  const [verificationSuccessScreen, setVerificationSuccessScreen] =
    useState(false);
  const [signUpWithPhone, setSignUpWithPhone] = useState(false);

  const location = useLocation();

  // five image
  const [data, setData] = useState([]);

  useEffect(() => {
    // Simulate a delay of 1000 milliseconds (1 second)
    const delay = 1000;

    // Start loading state
    setIsSectionsLoading(true);

    // Fetch data after the delay
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL1}/v1/adds/sections?language=${strings._language}`);

        // Simulate a delay before setting the data
        setTimeout(() => {
          setData(response.data);
          setIsSectionsLoading(false);
        }, delay);
      } catch (error) {
        setIsSectionsLoading(false); // Set loading to false in case of an error
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strings._language]);


  const [logo, setLogo] = useState("");
  function handleImage(e) {
    setLogo(e.target.files[logo]);
  }

  const navigate = useNavigate();

  const [openTab, setOpenTab] = useState(1);

  const [name, setName] = useState("");
  const [role, setRole] = useState("individual");

  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [officePhone, setOfficePhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [address, setAddress] = useState("");
  const [termsAccept, setTermsAccept] = useState("");

  const handlesubmit = (e) => {
    e.preventDefault();

    if (!termsAccept) {
      toast.error("Please accept terms & conditions");
    } else {
      setSignUpLoader(true);
      axios
        .post(`${baseURL1}/v1/user/email-signup`, {
          name,
          role,
          companyName,
          email,
          taxNumber,
          phone,
          officePhone,
          logo,
          password,
          confirmPassword,
          address,
          termsAccept: "true",
        })
        .then((response) => {
          toast.success(`${strings.signUpSuccess}`);
          setSignUpLoader(false);
          setPhoneLoginOtpScreen(false);
          setForgotEmailOtpScreen(false);
          setSignUpWithPhone(false);
          setSignUpEmailAddress(false);
          setForgotData(false);
          setEmailSignUpOtpScreen(true);
          setEmailSignInForm(false);
          setSignUpForm(false);
          setSignInWithNumber(false);
          setSignInWithLogin(false);
          setSignInVerify(false);
          setRecoverWithEmail(false);
          setResetPassword(false);
          setPasswordUpdatedSuccess(false);
          setPhoneSignUpVerifyCodeScreen(false);
          setMobileNumber(false);
          setMobileResetPassword(false);
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          setSignUpLoader(false);
        });
    }
  };

  const handleUserVerification = (e) => {
    e.preventDefault();

    setUserVerificationLoader(true);
    axios
      .post(`${baseURL1}/v1/user/verify`, {
        code: emailVerificationCode,
      })
      .then((response) => {
        toast.success(`${strings.userVerified}`);
        setUserVerificationLoader(true);
        setPhoneLoginOtpScreen(false);
        setForgotEmailOtpScreen(false);
        setSignUpWithPhone(false);
        setSignUpEmailAddress(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(true);
        setSignUpForm(false);
        setSignInWithNumber(false);
        setSignInWithLogin(false);
        setSignInVerify(false);
        setRecoverWithEmail(false);
        setResetPassword(false);
        setPasswordUpdatedSuccess(false);
        setPhoneSignUpVerifyCodeScreen(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
        setUserVerificationLoader(false);
      });
  };



  const auth = getAuth(app);
  auth.languageCode = "en";

  const onPhoneLogin = async () => {
    setPhoneLoginLoader(true)
    try {
      const response = await apiClient.post('/v1/user/phone-login', {
        phone: `+${phone}`,
        isUzbek: true,

      })

      if (response) {

        setPhoneLoginLoader(false);
        setPhoneLoginOtpScreen(true);
        setSignUpWithPhone(false);
        setSignUpEmailAddress(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(false);
        setSignUpForm(false);
        setSignInWithNumber(false);
        setSignInWithLogin(false);
        setSignInVerify(false);
        setRecoverWithEmail(false);
        setResetPassword(false);
        setPasswordUpdatedSuccess(false);
        setPhoneSignUpVerifyCodeScreen(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
        toast.success(`${strings.otpSent}`);
      }
    } catch (err) {
      setPhoneLoginLoader(false)
      console.log(err)
      toast.error(`${err.response.data.error}`)
    }
  }

  const onSignup = async () => {
    setLoading(true)
    try {
      const response = await apiClient.post('/v1/user/phone-signup', {
        phone: `+${phone}`,
        name,
        isUzbek: true,
        role

      })

      if (response) {
        setLoading(false);
        setPhoneLoginOtpScreen(false);
        setPhoneSignUpVerifyCodeScreen(true);
        setSignUpWithPhone(false);
        setSignUpEmailAddress(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(false);
        setSignUpForm(false);
        setSignInWithNumber(false);
        setSignInWithLogin(false);
        setSignInVerify(false);
        setRecoverWithEmail(false);
        setResetPassword(false);
        setPasswordUpdatedSuccess(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
        toast.success(`${strings.otpSent}`);
      }
    } catch {
      setLoading(false)
    }
  }

  const verifySignUp = async () => {
    setUserVerificationLoader(true);
    try {
      const response = await apiClient.post('/v1/user/verify-phone-user', {
        code: otp,
        phone: `+${phone}`,
        isUzbek: true,

      })

      if (response) {
        setLoading(false);
        setUserVerificationLoader(false);
        setVerificationSuccessScreen(false);
        setPhoneLoginOtpScreen(false);
        setSignUpEmailAddress(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(false);
        setSignUpForm(false);
        setSignInWithNumber(true);
        setSignInWithLogin(false);
        setSignInVerify(false);
        setRecoverWithEmail(false);
        setResetPassword(false);
        setPasswordUpdatedSuccess(false);
        setPhoneSignUpVerifyCodeScreen(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
        setSignUpWithPhone(false);
      }
    } catch {

    }
  }

  const verifyLogin = async () => {
    setUserVerificationLoader(true);

    try {
      const response = await apiClient.post('/v1/user/verify-phone-login', {
        code: otp,
        phone: `+${phone}`,
        isUzbek: true,

      })

      if (response) {
        setUserVerificationLoader(false);
        setVerificationSuccessScreen(false);
        setPhoneLoginOtpScreen(false);
        setSignUpEmailAddress(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(false);
        setSignUpForm(false);
        setSignInWithNumber(false);
        setSignInWithLogin(false);
        setSignInVerify(false);
        setRecoverWithEmail(false);
        setResetPassword(false);
        setPasswordUpdatedSuccess(false);
        setPhoneSignUpVerifyCodeScreen(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
        setSignUpWithPhone(false);
        Cookies.set("token", response.data.token, { expires: 7 });
        Cookies.set("userDetails", JSON.stringify(response.data.user), {
          expires: 7,
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      toast.error(error.response.data.error)
      setUserVerificationLoader(false);
    }
  }

  function handleForgotApi(event) {
    event.preventDefault();
    if (!forgotEmailEntry) {
      toast.error(`${strings.emailIsRequired}`);
      return;
    } else {
      setForgotEmailLoader(true);
      axios
        .get(`${baseURL1}/v1/user/forget-password?email=${forgotEmailEntry}`)
        .then((response) => {
          setForgotEmailLoader(false);
          setForgotEmailOtpScreen(true);
          setSignInVerify(false);
          setSignUpForm(false);
          setForgotData(false);
          setEmailSignUpOtpScreen(false);
          setEmailSignInForm(false);
          setSignInWithNumber(false);
          setSignInWithLogin(false);
          setRecoverWithEmail(false);
          setResetPassword(false);
          setPasswordUpdatedSuccess(false);
          setPhoneSignUpVerifyCodeScreen(false);
          setMobileNumber(false);
          setMobileResetPassword(false);
          setPhoneLoginOtpScreen(false);
          setSignUpWithPhone(false);
        })
        .catch((error) => {
          toast.error(error.response.data.error)
          setForgotEmailLoader(false);
        });
    }
  }

  function handleEmailReset(event) {
    event.preventDefault();
    setEmailResetLoader(true);
    axios
      .get(`${baseURL1}/v1/user/verify?code=${emailResetCode}`)
      .then((response) => {
        setEmailResetLoader(false);
        setPassword('')
        setConfirmPassword('')
        setResetPassword(true);
        setForgotEmailOtpScreen(false);
        setSignInVerify(false);
        setSignUpForm(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(false);
        setSignInWithNumber(false);
        setSignInWithLogin(false);
        setRecoverWithEmail(false);
        setPasswordUpdatedSuccess(false);
        setPhoneSignUpVerifyCodeScreen(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
        setPhoneLoginOtpScreen(false);
        setSignUpWithPhone(false);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error}`);
        setEmailResetLoader(false);
      });
  }

  //reset password api
  function handleSubmitResetPassword() {
    setResetPasswordLoader(true);
    axios
      .post(`${baseURL1}/v1/user/reset-password`, {
        code: emailResetCode,
        password: password,
        confirmPassword: confirmPassword,
      })
      .then((response) => {
        setResetPasswordLoader(false);
        setPasswordUpdatedSuccess(true);
        setResetPassword(false);
        setForgotEmailOtpScreen(false);
        setSignInVerify(false);
        setSignUpForm(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(false);
        setSignInWithNumber(false);
        setSignInWithLogin(false);
        setRecoverWithEmail(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
        setPhoneLoginOtpScreen(false);
        setSignUpWithPhone(false);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error}`);
        setResetPasswordLoader(false);
      });
  }

  const resendEmail = async () => {
    try {
      setResendEmailLoader(true)


      const response = await axios.get(`${baseURL1}/v1/user/forget-password?email=${forgotEmailEntry}`)
      toast.success("Email Resent!")
      setResendEmailLoader(false)
      setTimer(30)
    } catch {
      toast.error('Failed')
      setResendEmailLoader(false)
    }
  }




  const notifications = useSelector(state => state.notifications.notifications);


  const fetchNotifications = async () => {
    try {
      const response = await apiClient.get("/v1/user/notification")
      const notifications = response.data;

      const userDetails = Cookies.get("userDetails")

      const user = JSON.parse(userDetails);

      const notificationsCount = countUnreadNotifications(notifications, user._id)

      setUnreadNotificationsCount(notificationsCount)

      dispatch(setNotifications(notifications));
    } catch (error) {
      dispatch(setNotifications([]));
    }
  };

  useEffect(() => {
    fetchNotifications()
  }, []);

  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/sections?language=${strings._language}`)
      .then((response) => {
        setBrands(response.data[0].categories);
      })
      .catch((err) => { });;
  }, [strings._language]);

  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/sections?language=${strings._language}`)
      .then((response) => {
        setRealEstateBrands(response.data[1].categories);
      })
      .catch((err) => { });;
  }, [strings._language]);

  useEffect(() => {
    const userDetails = Cookies.get("userDetails");
    if (userDetails) {
      const user = JSON.parse(userDetails);
      const {
        name,
        companyName,
        phone,
        email,
        country,
        city,
        logo,
        address,
        image,
        taxNumber,
        officePhone,
        gender,
      } = user;
      const filteredUser = {
        name,
        companyName,
        phone,
        email,
        country,
        city,
        logo,
        address,
        image,
        taxNumber,
        officePhone,
        gender,
      };
      setProfileData(filteredUser);
    } else {
      setProfileData("Null");
    }
  }, []);

  return (
    <div>
      <div id="recaptcha-container"></div>
      {/*Navbar Start*/}
      <Navbar collapseOnSelect expand="lg">
        <Container className="lg:h-[100px]">
          <Navbar.Brand className="col-8  flex justify-start">
            <Link to="/">
              <img src={logoImage} alt="" className="h-[180px] w-[180px]" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav " className="col-4 ">


            <Nav className="ms-auto md:p-0 p-2  flex h-max items-center w-[100%] justify-end">

              <Link to="/">
                <button className="text-orange-400 ml-1">{strings.home}</button>
              </Link>

              {token === undefined ? (
                <Nav.Link eventKey={2}>
                  <div
                    className="border-1 p-[6px] px-[12px] rounded-full border-[#F37521] text-white bg-[#F37521] cursor-pointer whitespace-nowrap inline-bloc"
                    onClick={handleShow}
                  >
                    {strings.login}
                  </div>
                </Nav.Link>
              ) : (
                <Nav.Link>
                  {profileData !== "Null" ? (
                    <Avatar
                      alt="Travis Howard"
                      src={
                        profileData.image === "" ? profile : profileData.image
                      }
                      style={{
                        height: 60,
                        width: 60,
                      }}
                      onClick={handleMenu1Open}
                      ref={button1Ref}
                    />
                  ) : (
                    <Avatar
                      alt="Travis Howard"
                      src=""
                      style={{
                        height: 50,
                        width: 50,
                        marginTop: 7,
                      }}
                      onClick={handleMenu1Open}
                      ref={button1Ref}
                    />
                  )}
                </Nav.Link>
              )}

              {
                token !== undefined && (
                  <div
                    className="w-max"
                  >
                    <div
                      onClick={() => setShowCreateAdModal(true)}
                      className="w-max border-2 rounded-full bg-transparent text-[#f37521] border-[#f37521] p-2 cursor-pointer "
                    >
                      {strings.createAd}
                    </div>
                  </div>
                )
              }
              {
                showCreateAdModal && (
                  <AdCreateModal showCreateAdModal={showCreateAdModal} setShowCreateAdModal={setShowCreateAdModal} />
                )
              }
              <Menu
                anchorEl={button1Ref.current}
                open={Boolean(menu1Open)}
                onClose={handleMenu1Close}
              >
                <MenuItem
                  onClick={() => {
                    if (token === null || token === undefined) {
                      toast.warn(`${strings.loginWarn}`);
                    } else {
                      navigate("/profile");
                    }

                    handleMenu1Close();
                  }}
                >
                  {strings.myProfile}
                </MenuItem>
                <MenuItem onClick={() => {
                  navigate('/ads-management')
                  handleMenu1Close()
                }}>{strings.AdManagement}</MenuItem>
                <MenuItem onClick={handleMenu1Close}>
                  {strings.balance}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (token === null || token === undefined) {
                      toast.warn(`${strings.loginWarn}`);
                    } else {
                      navigate("/favorites");
                    }

                    handleMenu1Close();
                  }}
                >
                  {strings.fav}
                </MenuItem>
                <MenuItem onClick={handleMenu1Close}>
                  {strings.messages}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/packages')
                    handleMenu1Close()
                  }}
                >
                  {strings.packages}
                </MenuItem>
                <MenuItem
                  onClick={handleMenu1Close}
                  className="flex justify-between"
                >
                  <div>{strings.lang}</div>
                  <div className="text-orange-400 ml-2">
                    {strings._language}
                  </div>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (token === null || token === undefined) {
                      toast.warn(`${strings.loginWarn}`);
                    } else {
                      navigate("/privacy-policy");
                    }

                    handleMenu1Close();
                  }}
                >
                  {strings.Priv}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (token === null || token === undefined) {
                      toast.warn(`${strings.loginWarn}`);
                    } else {
                      navigate("/faq");
                    }

                    handleMenu1Close();
                  }}
                >
                  {strings.faqs}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (token === null || token === undefined) {
                      toast.warn(`${strings.loginWarn}`);
                    } else {
                      navigate("/reportproblem");
                    }
                    handleMenu1Close();
                  }}
                >
                  {strings.reportProb}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (token === null || token === undefined) {
                      toast.warn(`${strings.loginWarn}`);
                    } else {
                      navigate("/aboutus");
                    }

                    handleMenu1Close();
                  }}
                >
                  {strings.about}
                </MenuItem>
                <MenuItem

                  onClick={() => {
                    handleMenu1Close();
                    setShowDeleteConfirmation(true)
                  }}
                >
                  {strings.deleteAccount}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (token === undefined) {
                      handleShow();
                    } else {
                      logOutUser();
                    }
                    handleMenu1Close();
                  }}
                >
                  {token === undefined ? (
                    <>{strings.login}</>
                  ) : (
                    <>{strings.logout}</>
                  )}
                </MenuItem>
              </Menu>

              <Nav.Link
                className="relative flex items-center bg-red-600"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"

                onClick={() => readNotification()}
              >
                <span class="sr-only">{strings.notif}</span>
                {
                  unreadNotificationsCount.length > 0 && (
                    <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -right-1 dark:border-gray-900">
                      {unreadNotificationsCount}
                    </div>
                  )
                }
                <img className="w-[50px] cursor-pointer mt-2 h-[50px]" src={NavbarImage1} alt="" />
              </Nav.Link>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    // selectLanguage("en")
                    handleLanguageChange("en");
                    handleMenuClose();
                  }}
                  style={{
                    backgroundColor:
                      Cookies.get("language") === "en" ? "#F37521" : "",
                    color: Cookies.get("language") === "en" ? "#fff" : "",
                  }}
                >
                  <img src={EnglishFlag} alt="Eng Flag" className="h-[20px] mr-2" />
                  English
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    // selectLanguage("ru")
                    handleLanguageChange("ru");
                    handleMenuClose();
                  }}
                  style={{
                    backgroundColor:
                      Cookies.get("language") === "ru" ? "#F37521" : "",
                    color: Cookies.get("language") === "ru" ? "#fff" : "",
                  }}
                >
                  <img src={RussianFlag} alt="Russian Flag" className="h-[20px] mr-2" />
                  Русский
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    // selectLanguage("uz")
                    handleLanguageChange("uz");
                    handleMenuClose();
                  }}
                  style={{
                    backgroundColor:
                      Cookies.get("language") === "uz" ? "#F37521" : "",
                    color: Cookies.get("language") === "uz" ? "#fff" : "",
                  }}
                >
                  <img src={uzbekFlag} alt="Uz Flag" className="h-[20px] mr-2" />
                  O'zbek
                </MenuItem>
              </Menu>

              <img
                className="w-[50px] mt-2 cursor-pointer h-[50px]"
                src={NavbarImage2}
                alt=""
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              />
            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/*Navbar End*/}

      <div className="container mx-auto mb-3">
        {isSectionsLoading ? (


          <div
            className="grid grid-cols-1 md:flex md:justify-between md:items-center "

          >
            {[1, 2, 3, 4].map((index) => (
              <div key={index}
                className="cursor-pointer md:h-[180px] lg:h-[180px] md:mb-0 mb-2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Skeleton
                  style={{
                    height: '180px',
                    width: '250px',
                    md: { height: '180px' },
                    lg: { height: '180px' },
                    padding: '10px',
                    borderRadius: '10px'
                  }}

                />
                <Skeleton
                  // height={40}
                  width={120}
                  style={{ marginTop: "0.5rem" }}
                />
              </div>
            ))}
          </div>

        ) : (
          <div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4"

          >
            {data
              .filter(
                (item) =>
                  item.title === "Services" ||
                  item.title === "транспортное средство" ||
                  item.title === "transport vositasi" ||
                  item.title === "недвижимость" ||
                  item.title === "Ko'chmas mulk" ||
                  item.title === "Jobs" ||
                  item.title === "Работа" ||
                  item.title === "Ishlar" ||
                  item.title === "Услуги" ||
                  item.title === "Xizmatlar" ||
                  item.title === "Real Estate" ||
                  item.title === "Vehicle" ||
                  item.title === "Spare parts" ||
                  item.title === "Запчасти" ||
                  item.title === "Ehtiyot qismlar"
              )
              .map((item, index) => (
                <div
                  key={item._id}
                  className="cursor-pointer px-4 rounded-2xl bg-white shadow-md  md:mb-0 mb-2"
                  style={{
                    // height: 300,
                    // width: 200,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    const validTitles = [
                      "Vehicle",
                      "транспортное средство",
                      "transport vositasi",
                      "Real Estate",
                      "Ko'chmas mulk",
                      "недвижимость",
                    ];

                    if (validTitles.includes(item.title)) {
                      navigate(
                        `${(item.title === "Vehicle" ||
                          item.title === "транспортное средство" ||
                          item.title === "transport vositasi")
                          ? "/vehicle"
                          : (item.title === "Real Estate" ||
                            item.title === "Ko'chmas mulk" ||
                            item.title === "недвижимость")
                            ? "/realstate"
                            : ""
                        }`,
                        {
                          state: {
                            id: item._id,
                          },
                        }
                      );
                    } else {
                      toast.info(`${strings.comingSoon}`);
                    }
                  }}
                >
                  <img
                    className=" object-cover  md:h-[180px] lg:h-[180px] w-[250px]  bg-white"
                    src={item.imageUrl}
                    alt=""
                  />
                  <h1 className="font-semibold text-sm md:text-lg lg:text-md pe-2  mt-3  text-gray-700">
                    {item.title
                      .split(" ")
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ")}
                  </h1>
                </div>
              ))}
          </div>
        )}
      </div>

      {/* section  5 image  */}

      {
        show && <AuthModal show={show} setShow={setShow} />
      }
      {/*Modal Section end*/}



      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">
            {strings.notif}
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        {
          notificationsLoading ? <ClipLoader size={12} /> : (
            <div class="offcanvas-body">
              {notifications?.map((data, index) => (
                <div
                  key={index}
                  className={`p-2 ${index % 2 === 0 ? "bg-gray-300" : ""}`}
                >
                  <p style={{ fontWeight: "bold" }}>
                    {index + 1}. {data.title}
                  </p>
                  <p>{data.message}</p>
                </div>
              ))}
            </div>
          )
        }

      </div>
      {
        showDeleteConfirmation && <DeleteUserModal showDeleteConfirmation={showDeleteConfirmation} setShowDeleteConfirmation={setShowDeleteConfirmation} />
      }
    </div>
  );
};

export default Header;
