import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { ClipLoader } from 'react-spinners'
import strings from '../utils/locals/languages'
import { toast } from 'react-toastify'
import axios from 'axios'
import { baseURL1 } from '../utils/baseUrl'

const EmailSignUp = ({ setSelectedForm }) => {

  const [role, setRole] = useState('individual')
  const [isLoading, setIsLoading] = useState(false)

  const initialValues = {
    name: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsAccept: false,
    companyName: '',
    taxNumber: '',
    officePhone: '',
    address: '',
    role: role
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone number is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
    termsAccept: Yup.boolean().oneOf([true], 'You must accept the terms and conditions')
  })

  const handleSubmit = async (values) => {
    setIsLoading(true)
    try {
      const response = await axios.post(`${baseURL1}/v1/user/email-signup`, values)

      if (response) {
        toast.success(response.data.msg)
        setSelectedForm("EmailSignUpVerfCode")
        setIsLoading(false)
      } else {
        setIsLoading(false)
        toast.error(response.data.msg)
      }
    } catch{
      setIsLoading(false)
      toast.error(strings.somethingWentWrong)
    }
  }

  return (
    <div className="container mx-auto rounded-lg " style={{ background: "#F4F5F9" }}>
      <h4 className="fw-bold text-center mt-3">{strings.createAcc}</h4>
      <p>{strings.pleaseCreateTo}</p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue }) => (
          <Form onSubmit={handleSubmit} className="max-w-3xl mx-auto px-8 sm:px-0">
            <div className="sm:w-7/12 sm:mx-auto">
              <div className="flex justify-center">
                <div className="flex justify-between rounded-full bg-white w-max ">
                  <button
                    className={`p-3 rounded-full focus:outline-none ${role === 'individual' && 'bg-orange-600 text-white'} `}
                    type="button"
                    onClick={() => setRole('individual')}

                  >
                    {strings.ind}
                  </button>
                  <button
                    className={`p-3 rounded-full shadow-full focus:outline-none  ${role === 'corporate' && 'bg-orange-600 text-white'} `}
                    type="button"
                    onClick={() => setRole('corporate')}
                  >
                    {strings.corporate}
                  </button>
                </div>
              </div>

              <div className="mt-6">
                <div>
                  <div className="flex justify-center">
                    <div className="relative">
                      <Field
                        className="bg-white shadow-md rounded-full w-80 p-3 outline-orange-600"
                        type="text"
                        name="name"
                        value={values.name}
                        placeholder="Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name && <div className="text-red-500 text-xs">{errors.name}</div>}
                    </div>
                  </div>
                </div>

                <div className="flex justify-center mt-4">
                  <div className="relative">
                    <Field
                      className="bg-white shadow-md rounded-full w-80 p-3 outline-orange-600"
                      type="text"
                      name="phone"
                      value={values.phone}
                      placeholder="Phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.phone && touched.phone && <div className="text-red-500 text-xs">{errors.phone}</div>}
                  </div>
                </div>

                <div className="flex justify-center mt-4">
                  <div className="relative">
                    <Field
                      className="bg-white shadow-md rounded-full w-80 p-3 outline-orange-600"
                      type="email"
                      name="email"
                      value={values.email}
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email && <div className="text-red-500 text-xs">{errors.email}</div>}
                  </div>
                </div>

                <div className="flex justify-center mt-4">
                  <div className="relative">
                    <Field
                      className="bg-white shadow-md rounded-full w-80 p-3 outline-orange-600"
                      type="password"
                      name="password"
                      value={values.password}
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.password && touched.password && <div className="text-red-500 text-xs">{errors.password}</div>}
                  </div>
                </div>

                <div className="flex justify-center mt-4">
                  <div className="relative">
                    <Field
                      className="bg-white shadow-md rounded-full w-80 p-3 outline-orange-600"
                      type="password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      placeholder="Confirm Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div className="text-red-500 text-xs">{errors.confirmPassword}</div>
                    )}
                  </div>
                </div>

                <div className="checkbox mt-3 flex w-max -ml-10">
                  <div>
                    <Field type="checkbox" name="termsAccept" />
                  </div>
                  <div className="text-sm ml-1 text-center">
                    I have read and agree to the{' '}
                    <span className="text-orange-600">Terms of Use</span> &amp;{' '}
                    <span className="text-orange-600">Privacy Policy</span>
                  </div>
                </div>

                <div className="flex justify-center mt-4">
                  <button
                    type="submit"
                    className="mt-3 mb-3 bg-orange-600 text-white shadow-md rounded-full w-80 p-3"
                  >
                  {
                    isLoading ? <ClipLoader size={12} color='#fff' /> : strings.signUp
                  }  
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default EmailSignUp
