import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ClipLoader from 'react-spinners/ClipLoader';
import Lock from '.././images/Lock.svg';
import strings from '../utils/locals/languages';
import axios from 'axios';
import { baseURL1 } from '../utils/baseUrl';
import { toast } from 'react-toastify';

const EmailPassReset = ({setSelectedForm, setEmail}) => {
    const initialValues = { email: '' };

    const validationSchema = Yup.object({
        email: Yup.string().email(strings.invalidEmail).required(strings.requiredField)
    });


    const handleForgotApi = async (values, { setSubmitting }) => {
        try {
            setSubmitting(true)
            const response = await axios.get(`${baseURL1}/v1/user/forget-password?email=${values.email}`)

            if (response) {
                toast.success(response.data.msg)
                setSelectedForm("ForgotOtpEmailVerification")
                setEmail(values.email)
            } else {
                toast.error(response.data.msg)
            }
        } catch {
            setSubmitting(false);
            toast.error(strings.somethingWentWrong)
        }

    };

    return (
        <div className="container mx-auto rounded-lg" style={{ background: '#F4F5F9' }}>
            <div className="p-4">
                <h5 className="text-center font-bold">{strings.forgotPassword}</h5>
                <p className="text-center pt-2">{strings.pleaseEnterEmail}</p>
            </div>
            <div className="flex justify-center">
                <img className="w-16" src={Lock} alt="" />
            </div>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleForgotApi}>
                {({ isSubmitting }) => (
                    <Form className="flex flex-col items-center p-3">
                        <Field
                            className="p-3 h-15 w-80 bg-white rounded-full outline-orange-600"
                            type="email"
                            name="email"
                            placeholder={strings.email}
                        />
                        <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
                        <button
                            className="bg-orange-500 w-80 mt-3 rounded-full h-15 p-3 mb-4 text-white"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <>
                                    <ClipLoader size={15} color="#fff" /> {strings.loading}
                                </>
                            ) : (
                                strings.sendRequestEmail
                            )}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EmailPassReset;
