import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import en from "react-phone-number-input/locale/en";
import phoneIcon from ".././images/phone.svg";
import strings from "../utils/locals/languages";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import axios from "axios";
import { toast } from "react-toastify";
import { baseURL1 } from "../utils/baseUrl";


const CountrySelect = ({ value, onChange, labels, ...rest }) => (
  <select
    {...rest}
    value={value}
    onChange={(event) => onChange(event.target.value || undefined)}
  >
    <option value="">{labels["ZZ"]}</option>
    {getCountries().map((country) => (
      <option key={country} value={getCountryCallingCode(country)}>
        {labels[country]} +{getCountryCallingCode(country)}
      </option>
    ))}
  </select>
);

const PhoneSignIn = ({ setSelectedForm, setPhoneNbr }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [country, setCountry] = useState('')
  const [phone, setPhone] = useState('')

  // phone: `+${phone}`,

  const handlePhoneCode = (e) => {
    setCountry(e);
    setPhone(e);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true)
      const response = await axios.post(`${baseURL1}/v1/user/phone-login`, {
        phone: `+${phone}`,
        isUzbek: true
      })

      if (response) {
        toast.success(response.data.msg)
        setSelectedForm("PhoneLoginOtp")
        setIsLoading(false)
        setPhoneNbr(`+${phone}`)
      } else {
        setIsLoading(false)
        toast.error(response.data.msg)
      }
    } catch (err) {
      console.log('first', err)
      setIsLoading(false)
      toast.error(strings.failedToCreateUser)
    }
  };

  return (
    <div
      className="container rounded-lg"
      style={{ background: "#F4F5F9" }}
    >
      <h4 className="fw-bold  mt-4 text-center">{strings.login}</h4>
      <p className="mt-4">{strings.pleaseSignIn}</p>
      <div className="flex justify-center mt-5">
        <div className="flex w-80 justify-center bg-white rounded-full">
          <img className=" py-2 mt-2 w-6" src={phoneIcon} alt="" />
          <CountrySelect
            className="w-16 p-1 outline-none "
            labels={en}
            value={country}
            onChange={(e) => handlePhoneCode(e)}
          />


          <input
            className="outline-none"
            onChange={(e) => handlePhoneChange(e)}
            value={phone}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <button
          id="login-in-button"
          className="p-3 h-15 w-80 mt-4 bg-orange-600 rounded-full text-white"
          onClick={handleSubmit}
        >
          {isLoading ? (
            <>
              <ClipLoader size={15} color="#fff" /> {strings.loading}
            </>
          ) : (
            strings.login
          )}
        </button>
      </div>

      <div className="flex justify-center mt-4">
        <button
          className=" h-10 w-52 mt-2 bg-orange-600 text-white rounded-full mb-2"
          onClick={() => setSelectedForm("EmailLogin")}
        >
          {strings.signinWithEmail}
        </button>
      </div>

      <div className="flex justify-center mt-4">
        <span>
          {strings.dontHave}{" "}
          <button
            className="ps-2 mb-3 text-orange-300"
            onClick={() => setSelectedForm("SelectSignUpMethod")}

          >
            {strings.signUp}
          </button>
        </span>
      </div>
    </div>
  );
};

export default PhoneSignIn;
